/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { useState } from 'react'
//COMPONENTS
import { Layout, Title, Error, Loading } from '../components'
//MATERIAL UI
import { Grid, ButtonGroup, Button, TextField, AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

//GQL
import { PRODUCTION_REPORTS_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//TYPES
import { Report } from '../../../lib/sharedTypes'
//ROUTER
import { useParams } from 'react-router'
import moment from 'moment'
import { MdExpandMore } from 'react-icons/md'
import { useAppSelector } from '../hooks/reducerHooks'

const Reports = () => {
  const user = useAppSelector((state) => state.user.user)
  const isEditor = user?.roles.some((r) => r.category === 'EDITOR')
  const { period } = useParams<{ period: string }>()
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [reportPeriod, setReportPeriod] = useState(period ?? 'DAILY')
  const [expandedReports, setExpandedReports] = useState<number[]>([0])
  const {
    loading,
    error,
    data: { productionReports = [] } = {},
  } = useQuery<{
    productionReports: Report[]
  }>(PRODUCTION_REPORTS_QUERY, {
    variables: {
      period: reportPeriod,
      startDate: startDate,
      endDate: endDate,
    },
  })

  const daily_goals: Record<string, number> = {
    'jessica.del-tedesco@walter-learning.com': 21,
    'nina.le-tumelin@walter-learning.com': 21,
  }

  const formatPeriod = (pr: Report) => {
    switch (pr.period) {
      case 'DAILY':
        return `${new Intl.DateTimeFormat('fr-FR', {
          dateStyle: 'full',
        }).format(new Date(pr.startDate as string))}`
      case 'WEEKLY':
        return `Semaine du ${new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(
          new Date(pr.startDate as string),
        )} `
      case 'MONTHLY':
        return `${new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long' }).format(
          new Date(pr.startDate as string),
        )} `
      case 'YEARLY':
        return `Année ${new Intl.DateTimeFormat('fr-FR', { year: 'numeric' }).format(
          new Date(pr.startDate as string),
        )} `
      default:
        return 'Période inconnue'
    }
  }

  if (error) {
    return (
      <Layout title="Rapports">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title="Rapports">
      <Grid item container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title="Rapports" />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <ButtonGroup color="primary" aria-label="">
              <Button
                color={reportPeriod === 'DAILY' ? 'secondary' : 'primary'}
                onClick={() => {
                  setStartDate(moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'))
                  setEndDate(moment(new Date()).format('YYYY-MM-DD'))
                  setReportPeriod('DAILY')
                }}
              >
                Quotidien
              </Button>
              <Button
                color={reportPeriod === 'WEEKLY' ? 'secondary' : 'primary'}
                onClick={() => {
                  setStartDate(moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'))
                  setEndDate(moment(new Date()).format('YYYY-MM-DD'))
                  setReportPeriod('WEEKLY')
                }}
              >
                Hebdomadaire
              </Button>
              <Button
                color={reportPeriod === 'MONTHLY' ? 'secondary' : 'primary'}
                onClick={() => {
                  setStartDate(moment(new Date()).subtract(12, 'months').format('YYYY-MM-DD'))
                  setEndDate(moment(new Date()).format('YYYY-MM-DD'))
                  setReportPeriod('MONTHLY')
                }}
              >
                Mensuel
              </Button>
              <Button
                color={reportPeriod === 'YEARLY' ? 'secondary' : 'primary'}
                onClick={() => {
                  setStartDate(moment(new Date()).subtract(3, 'y').format('YYYY-MM-DD'))
                  setEndDate(moment(new Date()).format('YYYY-MM-DD'))
                  setReportPeriod('YEARLY')
                }}
              >
                Annuel
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  variant="outlined"
                  id="start"
                  label="Début"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={(event) => {
                    event.preventDefault()
                    setStartDate(event.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  id="end"
                  label="Fin"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  onChange={(event) => {
                    event.preventDefault()
                    setEndDate(event.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: '100%', marginTop: '10px' }}>
          {loading ? (
            <Loading />
          ) : isEditor ? (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={'250px'}>Période</TableCell>
                    <TableCell align="right">Vidéos mises en ligne</TableCell>
                    <TableCell align="right">Vidéos mises en ligne (min)</TableCell>
                    <TableCell align="right">Jours travaillés</TableCell>
                    <TableCell align="right">Vidéos uploadées par jour (min)</TableCell>
                    <TableCell align="right">Atteinte de l'objectif (%)</TableCell>
                    <TableCell align="right">Vidéos validées directement (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productionReports.map((pr) => {
                    console.log('pr', pr)
                    const report = pr.reports.find((r) => r.user.id === user?.id)
                    if (!report) return <></>
                    const editor_goal = daily_goals[report.user.email] ?? 18
                    return (
                      <TableRow key={report.user.id}>
                        <TableCell width={'250px'} style={{ display: 'flex', flexDirection: 'row' }}>
                          {formatPeriod(pr)}
                        </TableCell>
                        <TableCell align="right">{report.num_of_uploads}</TableCell>
                        <TableCell align="right">
                          {`${new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(
                            Math.floor(report.duration_of_uploads / 60),
                          )} min`}
                        </TableCell>
                        <TableCell align="right">{report.work_days_count}</TableCell>
                        <TableCell align="right">
                          {isNaN(report.duration_of_uploads / report.work_days_count)
                            ? '-'
                            : `${new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(
                                Math.floor(report.duration_of_uploads / report.work_days_count / 60),
                              )} min`}
                        </TableCell>
                        <TableCell align="right">
                          {isNaN(report.duration_of_uploads / report.work_days_count)
                            ? '-'
                            : `${new Intl.NumberFormat('fr-FR', { style: 'percent' }).format(
                                report.duration_of_uploads / report.work_days_count / 60 / editor_goal,
                              )}`}
                        </TableCell>
                        <TableCell align="right">
                          {report.first_attempt_validation_video_count
                            ? new Intl.NumberFormat('fr-FR', { style: 'percent' }).format(
                                report.first_attempt_validation_video_count / report.num_of_uploads,
                              )
                            : '-'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            productionReports.map((pr, index) => {
              return (
                <Accordion
                  expanded={expandedReports.includes(index)}
                  onChange={() => {
                    if (expandedReports.includes(index)) {
                      setExpandedReports((prevState) => {
                        return prevState.filter((p) => p !== index)
                      })
                    } else {
                      setExpandedReports([...expandedReports, index])
                    }
                  }}
                  style={{ marginBottom: '15px' }}
                >
                  <AccordionSummary expandIcon={<MdExpandMore />}>
                    <h4 style={{ margin: '0px' }}>{formatPeriod(pr)}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell width={'150px'}>Monteur</TableCell>
                            <TableCell align="right">Vidéos mises en ligne</TableCell>
                            <TableCell align="right">Vidéos mises en ligne (min)</TableCell>
                            <TableCell align="right">Jours travaillés</TableCell>
                            <TableCell align="right">Vidéos uploadées par jour (min)</TableCell>
                            <TableCell align="right">Atteinte de l'objectif (%)</TableCell>
                            <TableCell align="right">Vidéos validées directement (%)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pr.reports.map((report) => {
                            const editor_goal = daily_goals[report.user.email] ?? 18
                            return (
                              <TableRow key={report.user.id}>
                                <TableCell width={'150px'} style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ marginRight: '5px' }}>{report.user.first_name}</div>
                                  <div>{report.user.last_name}</div>
                                </TableCell>
                                <TableCell align="right">{report.num_of_uploads}</TableCell>
                                <TableCell align="right">
                                  {`${new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(
                                    Math.floor(report.duration_of_uploads / 60),
                                  )} min`}
                                </TableCell>
                                <TableCell align="right">{report.work_days_count}</TableCell>
                                <TableCell align="right">
                                  {isNaN(report.duration_of_uploads / report.work_days_count)
                                    ? '-'
                                    : `${new Intl.NumberFormat('fr-FR', { style: 'decimal' }).format(
                                        Math.floor(report.duration_of_uploads / report.work_days_count / 60),
                                      )} min`}
                                </TableCell>
                                <TableCell align="right">
                                  {isNaN(report.duration_of_uploads / report.work_days_count)
                                    ? '-'
                                    : `${new Intl.NumberFormat('fr-FR', { style: 'percent' }).format(
                                        report.duration_of_uploads / report.work_days_count / 60 / editor_goal,
                                      )}`}
                                </TableCell>
                                <TableCell align="right">
                                  {report.first_attempt_validation_video_count
                                    ? new Intl.NumberFormat('fr-FR', { style: 'percent' }).format(
                                        report.first_attempt_validation_video_count / report.num_of_uploads,
                                      )
                                    : '-'}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              )
            })
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}
export default Reports
