import { gql } from '@apollo/client'

//SEARCH
export const GLOBAL_SEARCH_QUERY = gql`
  query GLOBAL_SEARCH_QUERY($searchQuery: String!) {
    global(searchQuery: $searchQuery) {
      courses {
        id
        name
        code_name
        updated_at
      }
      projects {
        id
        name
        code_name
        updated_at
      }
      products {
        id
        title
        code_name
        updated_at
      }
    }
  }
`

//HEALTH
export const HEALTH_QUERY = gql`
  query HEALTH_QUERY {
    ping
  }
`
//AUTH
export const LOGIN_QUERY = gql`
  query LOGIN_QUERY($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

//DASHBOARD
export const DASHBOARD_QUERY = gql`
  query DASHBOARD_QUERY($take: Int, $order: OrderType) {
    me {
      id
      username
      email
      ad_username
      first_name
      last_name
      roles {
        id
        category
      }
      job {
        id
        category
      }
    }
    products(take: $take, order: $order) {
      id
      code_name
      color
      s3_banner
      s3_logo
    }
  }
`

export const DASHBOARD_PROJECTS_QUERY = gql`
  query DASHBOARD_PROJECTS_QUERY(
    $where: ProjectWhereInput
    $directorsWhere: UserWhereInput
    $directorsTake: Int
    $order: OrderType
  ) {
    projects(where: $where, order: $order) {
      id
      name
      code_name
      duration_target
      duration_production
      video_sets_count
      members(where: $directorsWhere, take: $directorsTake) {
        id
        first_name
        last_name
      }
      course {
        id
        s3_banner
        s3_logo
        color
        premiere_session
      }
    }
  }
`

export const DASHBOARD_COURSES_QUERY = gql`
  query DASHBOARD_COURSES_QUERY($where: CourseWhereInput, $order: OrderType) {
    courses(where: $where, order: $order) {
      id
      code_name
      name
      s3_banner
      s3_logo
      color
      premiere_session
      published_on
      managers {
        id
        first_name
        last_name
      }
    }
  }
`

// SHOOTINGS QUERY
export const SHOOTINGS_QUERY = gql`
  query SHOOTINGS_QUERY($take: Int, $where: ShootingWhereInput, $order: OrderType) {
    shootings(take: $take, where: $where, order: $order) {
      id
      starts_at
      ends_at
      address
      crew {
        id
        first_name
        last_name
      }
      project {
        id
        code_name
        name
        course {
          id
          s3_banner
        }
      }
    }
  }
`

export const USERS_QUERY = gql`
  query USERS_QUERY($order: OrderType, $skip: Int, $take: Int) {
    users(order: $order, skip: $skip, take: $take) {
      id
      username
      email
      last_name
      first_name
      current
      roles {
        id
        category
      }
      job {
        id
        category
      }
    }
    usersCount
  }
`

export const USERS_SEARCH_QUERY = gql`
  query USERS_SEARCH_QUERY($searchQuery: String, $order: OrderType, $take: Int) {
    usersSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      username
      email
      last_name
      first_name
      current
      roles {
        id
        category
      }
      job {
        id
        category
      }
    }
  }
`

//PROJECT
export const PROJECT_ID_QUERY = gql`
  query PROJECT_ID_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
    }
  }
`

export const PROJECTS_QUERY = gql`
  query PROJECTS_QUERY($where: UserWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    projects(order: $order, skip: $skip, take: $take) {
      id
      code_name
      name
      duration_target
      duration_production
      created_at
      course {
        id
        code_name
      }
      members(where: $where) {
        id
        first_name
        last_name
        username
      }
    }
    projectsCount
  }
`

export const PROJECT_INFO_QUERY = gql`
  query PROJECT_INFO_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
      code_name
      storage_name
      name
      duration_production
      duration_target
      video_sets {
        id
        duration
      }
      members {
        id
        username
        job {
          id
          category
        }
      }
      course {
        id
        code_name
        managers {
          id
        }
      }
    }
  }
`

export const PROJECT_QUERY = gql`
  query PROJECT_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
      code_name
      course {
        id
        code_name
        s3_banner
        s3_logo
        product {
          id
          code_name
        }
      }
      video_sets {
        id
        duration
        lesson_videos {
          id
          review_state
          file_duration
        }
      }
    }
  }
`

export const PROJECT_SETS_QUERY = gql`
  query PROJECT_SETS_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
      duration_target
      course {
        id
        product {
          id
          code_name
          teaser_vimeo_id
          teaser_vimeo_url
          teaser_vimeo_iframe
          teaser_vimeo_lastsync
          teaser_walter_media_url
          teaser_review_state
          teaser_uploader {
            id
            first_name
            last_name
          }
        }
        managers {
          id
        }
      }
      video_sets {
        id
        name
        index
        duration
        editor {
          id
          first_name
          last_name
          username
        }
        lesson_videos {
          id
          number
          vimeo_id
          review_state
          file_duration
        }
      }
    }
  }
`

export const VIDEO_SET_QUERY = gql`
  query VIDEO_SET_QUERY($where: ProjectWhereInput, $video_set_where: VideoSetWhereInput, $order: OrderType) {
    project(where: $where) {
      id
      course {
        id
        s3_banner
        code_name
        managers {
          id
        }
      }
      video_sets(where: $video_set_where) {
        id
        name
        index
        duration
        editor {
          id
          first_name
          last_name
        }
        lesson_videos(order: $order) {
          id
          number
          review_state
          file_duration
          vimeo_url
          lesson {
            id
            name
            note
          }
        }
      }
    }
  }
`

export const PROJECT_VIDEOS_QUERY = gql`
  query PROJECT_VIDEOS_QUERY($where: ProjectWhereInput, $order: OrderType) {
    project(where: $where) {
      id
      project_videos(order: $order) {
        id
        number
        file_path
        file_duration
        review_state
        vimeo_url
        video_set {
          id
          index
        }
        lesson {
          id
          name
          note
        }
      }
    }
  }
`

export const PROJECT_LESSON_VIDEO_QUERY = gql`
  query PROJECT_LESSON_VIDEO_QUERY($where: LessonVideoWhereInput) {
    lessonVideo(where: $where) {
      id
      number
      walter_media_url
      vimeo_thumbnail
      vimeo_lastsync
      vimeo_url
      review_state
      file_path
      file_duration
      review_state
      thumbnail_timecode
      version
      video_diagrams {
        id
        time_code
        comment
        name
        s3_file
        state
      }
      reviews {
        id
        time_code
        state
        content
        pos_y
        pos_x
        created_at
        updated_at
        reviewer {
          id
          first_name
          last_name
        }
        comments {
          id
          content
          created_at
          author {
            id
            first_name
            last_name
          }
        }
      }
      video_set {
        id
        name
        index
        editor {
          id
        }
        project {
          id
          code_name
          course {
            id
            code_name
            managers {
              id
            }
          }
          members {
            id
            username
            job {
              id
              category
            }
          }
        }
      }
      lesson {
        id
        name
        note
      }
    }
  }
`

export const PROJECT_RUSHES_QUERY = gql`
  query PROJECT_RUSHES_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
      course {
        id
        managers {
          id
        }
      }
      medias {
        id
        path
        proxy_file_status
        created_at
        updated_at
      }
    }
  }
`

export const PROJECT_DOCUMENTS_QUERY = gql`
  query PROJECT_DOCUMENTS_QUERY($where: ProjectWhereInput, $order: OrderType) {
    project(where: $where) {
      id
      course {
        managers {
          id
        }
      }

      documents(order: $order) {
        id
        name
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const PROJECT_SHOOTINGS_QUERY = gql`
  query PROJECT_SHOOTINGS_QUERY($where: ProjectWhereInput) {
    project(where: $where) {
      id
      code_name
      name
      duration_production
      duration_target
      course {
        id
        code_name
        managers {
          id
        }
      }
      members {
        id
        last_name
        first_name
        created_at
        updated_at
        job {
          id
          category
        }
      }
      shootings {
        id
        address
        duration_target
        starts_at
        ends_at
        created_at
        updated_at
        crew {
          id
          first_name
          last_name
          username
          created_at
          updated_at
          job {
            id
            category
          }
        }
        teachers {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const PROJECTS_SEARCH_QUERY = gql`
  query PROJECTS_SEARCH_QUERY($where: UserWhereInput, $searchQuery: String, $order: OrderType, $take: Int) {
    projectsSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      name
      code_name
      duration_target
      duration_production
      created_at
      members(where: $where) {
        first_name
        last_name
        username
        id
      }
    }
  }
`

//PRODUCT
export const PRODUCTS_QUERY = gql`
  query PRODUCTS_QUERY($where: ProductWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    products(where: $where, order: $order, skip: $skip, take: $take) {
      id
      code_name
      title
      subtitle
      s3_card
      created_at
      preamble
    }
    productsCount
  }
`

export const PRODUCTS_SEARCH_QUERY = gql`
  query PRODUCTS_SEARCH_QUERY($searchQuery: String, $where: ProductWhereInput, $order: OrderType, $take: Int) {
    productsSearch(where: $where, searchQuery: $searchQuery, order: $order, take: $take) {
      id
      code_name
      title
      subtitle
      s3_card
      created_at
    }
  }
`

export const PRODUCT_QUERY = gql`
  query PRODUCT_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      lms_published_on
      is_apprenticeship
      teaser_vimeo_id
      teaser_vimeo_url
      teaser_vimeo_iframe
      teaser_vimeo_lastsync
      teaser_walter_media_url
      teaser_review_state
      teaser_uploader {
        id
      }
      updated_at
      version
      video_reviews {
        id
        time_code
        state
        content
        pos_y
        pos_x
        created_at
        updated_at
        reviewer {
          id
          first_name
          last_name
        }
        comments {
          id
          content
          created_at
          author {
            id
            first_name
            last_name
          }
        }
      }
      publications {
        id
        published_at
        user {
          id
          first_name
          last_name
        }
      }
      courses {
        id
        code_name
        projects {
          id
          code_name
        }
      }
    }
  }
`

export const PRODUCT_PROGRAM_QUERY = gql`
  query PRODUCT_PROGRAM_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      s3_card
      s3_logo
      color
      title
      subtitle
      preamble
      duration
      action_number
      cpf_action_string
      cpf_session_duration
      epp_available
      skills_title
      skills_introduction
      prerequisite
      public
      is_apprenticeship
      program_introduction
      updated_at
      certification {
        id
        name
        code
        href
        text
        message
        certifier {
          id
          name
          full_name
          description
          website
        }
      }
      parts {
        id
        position
        name
        sub_parts {
          id
          position
          name
        }
      }
      teachers {
        id
        first_name
        last_name
        title
        introduction
        s3_picture
      }
      skills {
        id
        name
      }
      product_catalog_positions {
        catalog {
          id
          name
          parent {
            id
            name
          }
        }
      }
      catalog_funding_organisms {
        catalog {
          id
          name
          parent {
            id
            name
          }
        }
        funding_organism {
          id
          name
        }
      }
    }
  }
`

export const PRODUCT_REVIEWS_QUERY = gql`
  query PRODUCT_REVIEWS_QUERY($where: ProductWhereInput, $reviewCountWhere: ProductReviewWhereInput) {
    product(where: $where) {
      id
      title
      reviews {
        id
        first_name
        last_name
        feedback
        rating
        written_at
        created_at
        updated_at
      }
    }
    productReviewsCount(where: $reviewCountWhere)
  }
`

export const PRODUCT_APPRENTICESHIP_QUERY = gql`
  query PRODUCT_APPRENTICESHIP_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      code_name
      disciplines {
        id
        name
        code_name
        s3_banner
        s3_card
        s3_logo
        color
        duration
        position
        thematics {
          id
          name
          position
          duration
          global_id
          courses {
            id
            code_name
            name
          }
        }
      }
    }
  }
`

export const PRODUCT_INFO_QUERY = gql`
  query PRODUCT_INFO_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      status
      action_number
      cpf_action_string
      cpf_session_duration
      available_for_self_registration
      push_score
      canonical_url
      format
      is_crm_displayed
      has_cv
      release_date
      title
      subtitle
      code_name
      short_name
      demo_formation_code
      duration
      duration_dpc
      duration_fifpl
      preamble
      program_introduction
      public
      prerequisite
      seo_url
      seo_title
      breadcrumb_title
      seo_meta_description
      skills_introduction
      marked_as_new
      epp_available
      skills_title
      updated_at
      aliases
      key_strengths_walter
      key_strengths_formation
      job_opportunities
      formation_steps
      key_informations
      job_informations
      salary_start
      key_figures
      public_keywords
      lms_published_on
      post_publications {
        id
        type
        new_product_code
        fc_duration
        has_cv
        forum_slug
        publication_version
      }
      teaching_methods {
        id
        name
      }
      send_to_walter_sf
      recommendations {
        id
        code_name
      }
      certification {
        id
        code
        href
        text
        message
        name
        certifier {
          id
          full_name
        }
      }
      teachers {
        id
        first_name
        last_name
        resume_highlights
      }
      skills {
        id
        name
        description
        position
      }
    }
  }
`

export const PRODUCT_MANAGERS_QUERY = gql`
  query PRODUCT_MANAGERS_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      courses {
        id
        duration
        managers {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const PRODUCT_PARTS_QUERY = gql`
  query PRODUCT_PARTS_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      program_introduction
      is_apprenticeship
      parts {
        id
        name
        position
        sub_parts {
          id
          name
          position
        }
      }
    }
  }
`

export const PRODUCT_CAT_FO_QUERY = gql`
  query PRODUCT_CAT_FO_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      action_number
      cpf_action_string
      cpf_session_duration
      code_name
      product_catalog_positions {
        id
        position
        catalog {
          id
          name
        }
      }
      catalog_funding_organisms {
        id
        code_name
        duration
        listing_price
        minimum_selling_price
        release_date
        is_alias
        catalog {
          id
          name
          professions {
            id
            name
            code
          }
        }
        funding_organism {
          id
          name
        }
        profession {
          id
          name
          code
        }
        sale_processes {
          id
          name
        }
      }
    }
  }
`

export const PRODUCT_CATALOGS_FO_QUERY = gql`
  query PRODUCT_CATALOGS_FO_QUERY {
    catalogs {
      id
      code
      name
      parent {
        id
        name
      }
      professions {
        id
        name
        code
      }
    }
    fundingOrganisms {
      id
      code
      name
    }
  }
`

export const PRODUCT_CODE_NAME_QUERY = gql`
  query PRODUCT_CODE_NAME_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      code_name
    }
  }
`

export const PRODUCT_FAQ_QUERY = gql`
  query PRODUCT_FAQ_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      seo_faq {
        id
        question
        position
        answer
        updated_at
        created_at
      }
    }
  }
`

export const PRODUCT_VISUALS_QUERY = gql`
  query PRODUCT_VISUALS_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      s3_banner
      s3_card
      s3_logo
      s3_pdf
      color
      teaser_vimeo_url
      teaser_vimeo_lastsync
      teaser_walter_media_url
      teaser_review_state
    }
  }
`

export const PRODUCT_SALES_QUERY = gql`
  query PRODUCT_SALES_QUERY($where: ProductWhereInput) {
    product(where: $where) {
      id
      docusign_template_id
      minimum_selling_price
      is_active
      is_crm_displayed
      is_offerable
      catchphrase
      send_to_walter_sf
      listing_price
      dpc_compensation
      cogs_estimated
      cogs_theoretical
      selling_point
      sale_processes {
        id
        name
        description
      }
      waltersf_product_code
    }
  }
`

export const PRODUCT_TEASER_UPLOAD_TOKEN = gql`
  query productTeaserUploadToken($code_name: String) {
    productTeaserUploadToken(code_name: $code_name)
  }
`

//COURSE
export const COURSES_QUERY = gql`
  query COURSES_QUERY($where: CourseWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    courses(where: $where, order: $order, skip: $skip, take: $take) {
      id
      code_name
      name
      s3_card
      created_at
      sub_courses_count
    }
    coursesCount(where: $where)
  }
`

export const COURSES_PLAN_QUERY = gql`
  query COURSES_PLAN_QUERY($order: OrderType, $skip: Int, $take: Int) {
    courses(order: $order, skip: $skip, take: $take) {
      id
      code_name
      sub_courses {
        id
        code_name
      }
    }
  }
`

export const COURSE_MANAGERS_QUERY = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      id
      username
      job {
        id
        category
      }
    }
  }
`

export const COURSES_SEARCH_QUERY = gql`
  query COURSES_SEARCH_QUERY(
    $where: CourseWhereInput
    $searchQuery: String
    $order: OrderType
    $take: Int
    $skip: Int
  ) {
    coursesSearch(where: $where, searchQuery: $searchQuery, order: $order, take: $take, skip: $skip) {
      id
      code_name
      name
      s3_card
      created_at
      sub_courses_count
    }
    coursesCount(where: $where, searchQuery: $searchQuery)
  }
`

export const COURSE_ID_QUERY = gql`
  query COURSE_ID_QUERY($where: CourseWhereInput) {
    course(where: $where) {
      id
      code_name
      name
      managers {
        id
      }
    }
  }
`

export const COURSE_SYNTHESIS_QUERY = gql`
  query COURSE_SYNTHESIS_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      name
      parts(order: $order) {
        id
        name
        position
        sub_parts(order: $order) {
          id
          name
          position
          sub_parts(order: $order) {
            id
            name
            position
            lesson {
              id
              name
              summary
            }
          }
        }
      }
    }
  }
`

export const COURSE_CODE_NAME_QUERY = gql`
  query COURSE_CODE_NAME_QUERY($where: CourseWhereInput) {
    course(where: $where) {
      id
      code_name
    }
  }
`

export const COURSE_QUERY = gql`
  query COURSE_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      name
      premiere_session
      code_name
      duration
      s3_logo
      s3_card
      s3_banner
      lessons_count
      video_lessons_count
      document_lessons_count
      epp_lessons_count
      quiz_lessons_count
      skills_count
      lessons_questions_count
      summaries_count
      published_on
      created_at
      updated_at
      managers {
        id
      }
      projects {
        id
        code_name
      }
      publications {
        id
        user {
          id
          first_name
          last_name
        }
        published_at
      }
      product {
        id
        code_name
        title
        subtitle
        preamble
        product_catalog_positions {
          catalog {
            id
            name
          }
        }
        s3_logo
        s3_card
        s3_banner
        teachers {
          id
          s3_picture
          first_name
          last_name
          introduction
        }
      }
      sub_courses(order: $order) {
        id
        name
        code_name
        s3_logo
        s3_card
        position
        skills_count
        lessons_count
        video_lessons_count
        document_lessons_count
        epp_lessons_count
        quiz_lessons_count
        lessons_questions_count
        summaries_count
      }
    }
  }
`

export const COURSE_INFO_QUERY = gql`
  query COURSE_INFO_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      name
      code_name
      premiere_session
      position
      duration
      s3_banner
      s3_card
      s3_logo
      parent {
        id
      }
      managers {
        id
        username
      }
      sub_courses(order: $order) {
        id
        code_name
      }
      requirements {
        id
        completion_rate
        requires {
          id
          code_name
        }
      }
      writer_assignments {
        id
        start
        end
        active
        writer {
          id
          first_name
          last_name
        }
      }
      virtual_classrooms {
        id
        name
        description
        duration
      }
    }
  }
`

export const COURSE_LESSONS_VIDEO = gql`
  query COURSE_LESSONS_VIDEO($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      code_name
      video_lessons(order: $order) {
        id
        walter_media_url
        lesson {
          id
          name
          transcription
        }
      }
    }
  }
`

export const COURSE_VIDEO_QUERY = gql`
  query COURSE_VIDEO_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        summary
        summary_required
        summary_state
        validated
        resources {
          id
          pinned
          name
          comment
          s3_file
        }
        video {
          id
          number
          file_path
          file_duration
          review_state
          vimeo_url
          vimeo_thumbnail
          created_at
          updated_at
        }
      }
      video_lessons_count
    }
  }
`

export const COURSE_LESSONS_VIDEO_SEARCH = gql`
  query COURSE_LESSONS_VIDEO_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        summary
        summary_required
        summary_state
        validated
        transcription
        resources {
          id
          name
          pinned
          comment
          s3_file
        }
        video {
          id
          number
          file_path
          file_duration
          review_state
          vimeo_url
          vimeo_thumbnail
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_LESSONS_VIDEO_ADVANCED_SEARCH = gql`
  query COURSE_LESSONS_VIDEO_ADVANCED_SEARCH(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $searchObject: LessonWhereInputAdvanced
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_advanced_search(where: $lessonWhere, searchObject: $searchObject) {
        id
        name
        note
        summary
        summary_required
        summary_state
        transcription
        resources {
          id
          name
          pinned
          comment
          s3_file
        }
        video {
          id
          number
          file_path
          file_duration
          review_state
          vimeo_url
          vimeo_thumbnail
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_LESSON_ALL_QUERY = gql`
  query COURSE_LESSON_ALL_QUERY($where: CourseWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(order: $order, skip: $skip, take: $take) {
        id
        name
        validated
        published_on
        note
        created_at
        updated_at
        video {
          id
        }
        text {
          id
          type
        }
        epp {
          id
        }
        dactylo {
          id
        }
        document {
          id
        }
        quiz {
          id
        }
      }
      lessons_count
    }
  }
`

export const COURSE_LESSONS_ALL_SEARCH = gql`
  query COURSE_LESSONS_ALL_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        validated
        published_on
        note
        created_at
        updated_at
        video {
          id
        }
        dactylo {
          id
        }
        text {
          id
          type
        }
        epp {
          id
        }
        document {
          id
        }
        quiz {
          id
        }
      }
    }
  }
`

export const COURSE_TASK_QUERY = gql`
  query COURSE_TASK_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        task {
          id
          name
          description
          validation_threshold
          required
          grading_type
          type
          available_after_session_end
          need_correction_file
          created_at
          updated_at
          task_items {
            id
            position
            required
            max_size
            require_walter_validation
            is_corrector_item
            is_available_after_correction
            created_at
            updated_at
          }
        }
      }
      text_lessons_count
    }
  }
`

export const COURSE_TEXT_QUERY = gql`
  query COURSE_TEXT_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        text {
          id
          type
          s3_file
          downloadable
          created_at
          updated_at
        }
      }
      text_lessons_count
    }
  }
`

export const COURSE_LESSONS_TASK_SEARCH = gql`
  query COURSE_LESSONS_TASK_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        task {
          id
          type
          downloadable
          created_at
          updated_at
          task_items {
            id
            name
            position
            required
            max_size
            require_walter_validation
            is_corrector_item
            is_available_after_correction
            created_at
            updated_at
          }
        }
      }
    }
  }
`

export const COURSE_LESSONS_TEXT_SEARCH = gql`
  query COURSE_LESSONS_TEXT_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        text {
          id
          type
          s3_file
          downloadable
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_EPP_QUERY = gql`
  query COURSE_EPP_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        epp {
          id
          created_at
          updated_at
        }
      }
      epp_lessons_count
    }
  }
`

export const COURSE_LESSONS_EPP_SEARCH = gql`
  query COURSE_LESSONS_EPP_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        epp {
          id
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_QUIZ_QUERY = gql`
  query COURSE_QUIZ_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        quiz {
          id
          category
          created_at
          updated_at
          quiz_questions {
            id
          }
        }
      }
      quiz_lessons_count
    }
  }
`

export const COURSE_LESSONS_QUIZ_SEARCH = gql`
  query COURSE_LESSONS_QUIZ_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        quiz {
          id
          category
          created_at
          updated_at
          quiz_questions {
            id
          }
        }
      }
    }
  }
`

export const COURSE_HTML_QUERY = gql`
  query COURSE_HTML_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        html {
          id
          content
          type
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_LESSONS_HTML_SEARCH = gql`
  query COURSE_LESSONS_HTML_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        html {
          id
          type
          content
          created_at
          updated_at
          quiz_questions {
            id
          }
        }
      }
    }
  }
`

export const COURSE_DOCUMENT_QUERY = gql`
  query COURSE_DOCUMENT_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        document {
          id
          s3_file
          created_at
          updated_at
        }
      }
      document_lessons_count
    }
  }
`

export const COURSE_LESSONS_DOCUMENT_SEARCH = gql`
  query COURSE_LESSONS_DOCUMENT_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        document {
          id
          s3_file
          created_at
          updated_at
        }
      }
    }
  }
`

// LESSON DACTYLO
export const COURSE_DACTYLO_QUERY = gql`
  query COURSE_DACTYLO_QUERY(
    $where: CourseWhereInput
    $lessonWhere: LessonWhereInput
    $order: OrderType
    $skip: Int
    $take: Int
  ) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons(where: $lessonWhere, order: $order, skip: $skip, take: $take) {
        id
        name
        note
        validated
        dactylo {
          id
          transcript
          pausable
          lesson_video {
            id
            lesson {
              id
              name
            }
            created_at
            updated_at
          }
          created_at
          updated_at
        }
      }
    }
  }
`

export const COURSE_LESSONS_DACTYLO_SEARCH = gql`
  query COURSE_LESSONS_DACTYLO_SEARCH($where: CourseWhereInput, $lessonWhere: LessonWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      managers {
        id
      }
      lessons_search(where: $lessonWhere, searchQuery: $searchQuery) {
        id
        name
        note
        validated
        dactylo {
          id
          transcript
          pausable
          lesson_video {
            id
            lesson {
              id
              name
            }
          }
          created_at
          updated_at
        }
      }
    }
  }
`

// $order: OrderType
export const COURSE_QUIZ_QUESTIONS_QUERY = gql`
  query COURSE_QUIZ_QUESTIONS_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      code_name
      name
      parts(order: $order) {
        id
        name
        position
        sub_parts(order: $order) {
          id
          name
          position
          sub_parts(order: $order) {
            id
            name
            position
            lesson {
              id
              name
              lesson_questions {
                id
                introduction
                help_text
                created_at
                updated_at
                # lesson {
                #   id
                #   course {
                #     id
                #     code_name
                #   }
                # }
              }
            }
          }
        }
      }
    }
  }
`

export const COURSE_LESSONS_PLAN_QUERY = gql`
  query COURSE_LESSONS_PLAN_QUERY($where: CourseWhereInput) {
    courses(where: $where) {
      id
      lessons {
        id
        name
        video {
          id
          number
          file_duration
          video_set {
            id
            index
          }
        }
        course {
          id
          code_name
        }
        dactylo {
          id
        }
        document {
          id
        }
        epp {
          id
        }
        quiz {
          id
        }
        text {
          id
          type
        }
        task {
          id
        }
      }
    }
  }
`

export const COURSE_PLAN_QUERY = gql`
  query COURSE_PLAN_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      code_name
      s3_card
      name
      product {
        id
        breadcrumb_title
        action_number
      }
      introduction {
        id
        name
        summary
      }
      managers {
        id
      }
      evaluation {
        id
        name
        summary
        quiz {
          id
          quiz_questions {
            id
            lesson_question {
              id
              skills {
                id
                name
              }
              mc_question {
                id
                statement
              }
              gapfill_question {
                id
                text
              }
              ranking_question {
                id
                statement
              }
            }
          }
        }
      }
      parts(order: $order) {
        id
        name
        position
        duration
        sub_parts(order: $order) {
          id
          name
          position
          parent {
            id
          }
          sub_parts(order: $order) {
            id
            name
            position

            lesson {
              id
              global_id
              name
              summary
              summary_state
              summary_required
              version
              course {
                id
                code_name
              }
              video {
                id
                file_duration
                video_set {
                  id
                  index
                }
              }
              dactylo {
                id
              }
              document {
                id
              }
              epp {
                id
              }
              quiz {
                id
              }
              text {
                id
                type
              }
              task {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const COURSE_CHECK_STEP_4_QUERY = gql`
  query COURSE_CHECK_STEP_4_QUERY($where: CourseWhereInput) {
    course(where: $where) {
      id
      code_name
      skills_count
      skills {
        id
        name
        lesson_questions {
          id
        }
      }
      quiz_lessons {
        id
        lesson {
          id
          name
        }
        quiz_questions {
          id
        }
      }
    }
  }
`

export const COURSE_COURSE_SKILLS_QUERY = gql`
  query COURSE_COURSE_SKILLS_QUERY($where: CourseWhereInput, $order: OrderType, $take: Int, $skip: Int) {
    course(where: $where) {
      id
      code_name
      skills_count
      managers {
        id
      }
      skills(order: $order, take: $take, skip: $skip) {
        id
        name
        created_at
        updated_at
        lesson_questions {
          id
          introduction
          lesson {
            name
            id
          }
          mc_question {
            id
            statement
          }
          gapfill_question {
            id
            text
          }
          ranking_question {
            id
            statement
          }
        }
      }
    }
  }
`

export const COURSE_COURSE_SKILLS_SEARCH_QUERY = gql`
  query COURSE_COURSE_SKILLS_SEARCH_QUERY($where: CourseWhereInput, $searchQuery: String) {
    course(where: $where) {
      id
      code_name
      skills_search(searchQuery: $searchQuery) {
        id
        name
        created_at
        updated_at
        lesson_questions {
          id
          introduction
          lesson {
            id
            name
          }
          mc_question {
            id
            statement
          }
          gapfill_question {
            id
            text
          }
          ranking_question {
            id
            statement
          }
        }
      }
    }
  }
`

export const COURSE_SKILLS_SEARCH_QUERY = gql`
  query COURSE_SKILLS_SEARCH_QUERY(
    $where: CourseWhereInput
    $subCoursesWhere: CourseWhereInput
    $searchQuery: String
    $order: OrderType
    $take: Int
  ) {
    course(where: $where) {
      id
      code_name
      skills_count
      skills_search(searchQuery: $searchQuery, order: $order, take: $take) {
        id
        name
        course {
          code_name
        }
      }
      sub_courses(where: $subCoursesWhere) {
        id
        code_name
        skills_count
        skills_search(searchQuery: $searchQuery, order: $order, take: $take) {
          id
          name
          course {
            id
            code_name
          }
        }
      }
    }
  }
`

export const COURSE_QUESTIONS_QUERY = gql`
  query COURSE_QUESTIONS_QUERY(
    $where: CourseWhereInput
    $order: OrderType
    $take: Int
    $skip: Int
    $orderItems: OrderType
  ) {
    course(where: $where) {
      id
      code_name
      lessons_questions_count
      parent {
        id
        code_name
      }
      managers {
        id
      }
      lessons_questions(order: $order, take: $take, skip: $skip) {
        id
        introduction
        help_text
        correction_text
        question_video {
          id
          walter_media_url
          vimeo_thumbnail
          lesson {
            id
            name
          }
        }
        correction_video {
          id
          lesson {
            id
            name
            course {
              id
              code_name
            }
          }
        }
        created_at
        updated_at
        s3_image
        lesson {
          id
          name
          course {
            id
            code_name
          }
          video {
            id
          }
          text {
            id
            type
          }
          document {
            id
          }
          epp {
            id
          }
        }
        skills {
          id
          name
        }
        mc_question {
          id
          statement
          mc_items(order: $orderItems) {
            id
            s3_image
            statement
            correct
            position
            item_video {
              id
              vimeo_thumbnail
              walter_media_url
            }
          }
        }
        gapfill_question {
          id
          text
          gapfill_items {
            id
            position
            element_id
            value
            strict
          }
        }
        ranking_question {
          id
          statement
          ranking_items {
            id
            statement
            correct_position
            displayed_position
          }
        }
      }
    }
  }
`

export const COURSE_QUESTIONS_SEARCH_QUERY = gql`
  query COURSE_QUESTIONS_SEARCH_QUERY($where: CourseWhereInput, $searchQuery: String, $orderItems: OrderType) {
    course(where: $where) {
      id
      code_name
      lessons_questions_search(searchQuery: $searchQuery) {
        id
        introduction
        help_text
        correction_text
        question_video {
          id
          walter_media_url
          vimeo_thumbnail
          lesson {
            id
            name
          }
        }
        correction_video {
          id
          lesson {
            id
            name
            course {
              id
              code_name
            }
          }
        }
        s3_image
        created_at
        updated_at
        skills {
          id
          name
        }
        mc_question {
          id
          statement
          mc_items(order: $orderItems) {
            id
            item_video {
              id
              walter_media_url
              vimeo_thumbnail
            }
            statement
            correct
          }
        }
        gapfill_question {
          id
          text
          gapfill_items {
            id
            position
            value
            element_id
          }
        }
        ranking_question {
          id
          statement
          ranking_items {
            id
            statement
            correct_position
            displayed_position
          }
        }
        lesson {
          id
          name
          course {
            id
            code_name
          }
          video {
            id
          }
          html {
            id
          }
          text {
            id
            type
          }
          document {
            id
          }
          epp {
            id
          }
        }
      }
    }
  }
`

export const COURSE_RESOURCES_QUERY = gql`
  query COURSE_RESOURCES_QUERY($where: CourseWhereInput, $order: OrderType, $take: Int, $skip: Int) {
    course(where: $where) {
      id
      code_name
      resources_count
      managers {
        id
      }
      resources(take: $take, skip: $skip, order: $order) {
        id
        name
        pinned
        comment
        s3_file
        created_at
        updated_at
        lessons {
          id
          name
        }
      }
    }
  }
`

export const COURSE_RESOURCES_SEARCH_QUERY = gql`
  query COURSE_RESOURCES_SEARCH_QUERY($where: CourseWhereInput, $order: OrderType, $searchQuery: String) {
    course(where: $where) {
      id
      code_name
      resources_search(searchQuery: $searchQuery, order: $order) {
        id
        name
        comment
        s3_file
        created_at
        updated_at
        lessons {
          id
          name
        }
      }
    }
  }
`

export const SCHEMAS_QUERY = gql`
  query SCHEMAS_QUERY($where: LessonVideoDiagramWhereInput, $order: OrderType, $take: Int, $skip: Int) {
    lessonVideoDiagrams(where: $where, order: $order, take: $take, skip: $skip) {
      id
      name
      s3_file
      time_code
      comment
      state
      created_at
      updated_at
      lesson_video {
        id
        number
        lesson {
          id
          parts {
            id
            name
            parent {
              id
              name
              position
              parent {
                id
                name
                position
                version
              }
            }
          }
        }
        video_set {
          id
          index
          project {
            id
            code_name
          }
        }
        lesson {
          id
          name
        }
      }
    }
  }
`

// CATALOGS
export const CATALOGS_QUERY = gql`
  query CATALOGS_QUERY {
    catalogs {
      id
      name
      title
      catalog_pdf_s3link
    }
  }
`

export const CATALOGS_PAGE_QUERY = gql`
  query CATALOGS_PAGE_QUERY($order: OrderType, $skip: Int, $take: Int) {
    catalogs(order: $order, skip: $skip, take: $take) {
      id
      name
      created_at
      updated_at
      parent {
        id
        name
      }
    }
    catalogsCount
  }
`

export const CATALOGS_SEARCH_QUERY = gql`
  query CATALOGS_SEARCH_QUERY($searchQuery: String, $order: OrderType) {
    catalogsSearch(searchQuery: $searchQuery, order: $order) {
      id
      name
      created_at
      updated_at
      parent {
        id
        name
      }
    }
  }
`

export const CATALOG_QUERY = gql`
  query CATALOG_QUERY($where: CatalogWhereInput) {
    catalog(where: $where) {
      id
      name
      global_id
      content
      created_at
      updated_at
    }
  }
`

export const CATALOG_GENERAL_INFO_QUERY = gql`
  query CATALOG_GENERAL_INFO_QUERY($where: CatalogWhereInput) {
    catalog(where: $where) {
      id
      name
      status
      send_to_walter_sf
      global_id
      content
      title
      description
      canonical_url
      seo_url
      seo_title
      seo_meta_description
      breadcrumb
      position
      funding_subtitle
      funding_text
      catalog_pdf_cta
      course_top_title
      reviews_subtitle
      courses_list_title
      courses_list_subtitle
      andpc_compensation_example_amount
      andpc_compensation_example_description
      online_course_title
      opportunity_title
      opportunity_text
      opportunity_list
      parent {
        id
        name
      }
      product_top_1 {
        id
        code_name
      }
      product_top_2 {
        id
        code_name
      }
      product_top_3 {
        id
        code_name
      }
      products {
        id
        code_name
      }
      product_catalog_positions {
        id
        position
        product {
          id
          code_name
        }
      }
      created_at
      updated_at
    }
  }
`

export const CATALOG_VISUAL_MANAGEMENT_QUERY = gql`
  query CATALOG_VISUAL_MANAGEMENT_QUERY($where: CatalogWhereInput) {
    catalog(where: $where) {
      id
      screenshots_s3link
      catalog_pdf_s3link
      color
    }
  }
`

export const CATALOG_FAQ_QUERY = gql`
  query CATALOG_FAQ_QUERY($where: CatalogWhereInput) {
    catalog(where: $where) {
      id
      qa_title
      faq {
        id
        question
        answer
        updated_at
        created_at
      }
    }
  }
`

// SALE PROCESS
export const SALE_PROCESSES_QUERY = gql`
  query SALE_PROCESSES_QUERY {
    saleProcesses {
      id
      name
    }
  }
`
// TEACHERS
export const TEACHERS_QUERY = gql`
  query TEACHERS_QUERY {
    teachers {
      id
      first_name
      last_name
    }
  }
`

export const TEACHER_QUERY = gql`
  query TEACHER_QUERY($where: TeacherWhereInput) {
    teacher(where: $where) {
      id
      email
      first_name
      last_name
      title
      s3_picture
      phone_number
      mobile_number
      introduction
      resume_highlights
      push_score
      created_at
      updated_at
      products {
        id
        title
        code_name
        created_at
        updated_at
      }
    }
  }
`

export const TEACHERS_PAGE_QUERY = gql`
  query TEACHERS_PAGE_QUERY($order: OrderType, $skip: Int, $take: Int) {
    teachers(order: $order, skip: $skip, take: $take) {
      id
      title
      email
      first_name
      last_name
      s3_picture
      created_at
      updated_at
    }
    teachersCount
  }
`

export const TEACHERS_SEARCH_QUERY = gql`
  query TEACHERS_SEARCH_QUERY($searchQuery: String, $order: OrderType, $take: Int) {
    teachersSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      email
      first_name
      last_name
      s3_picture
      phone_number
      mobile_number
      introduction
      created_at
      updated_at
    }
  }
`

// CERTIFIERS
export const CERTIFIERS_QUERY = gql`
  query CERTIFIERS_QUERY($order: OrderType) {
    certifiers(order: $order) {
      id
      full_name
    }
  }
`

// MANAGERS
export const MANAGERS_QUERY = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      id
      username
      first_name
      last_name
      job {
        id
        category
      }
      leaves {
        id
        date
      }
    }
  }
`

// SUGGESTION
export const SUGGESTIONS_QUERY = gql`
  query Suggestions($where: SuggestionWhereInput, $order: OrderType, $take: Int, $skip: Int) {
    suggestionsCount(where: $where)
    suggestions(where: $where, order: $order, take: $take, skip: $skip) {
      id
      content
      category
      created_at
      updated_at
      user {
        id
        first_name
        last_name
        username
      }
    }
  }
`

// CALENDAR
export const PROJECTS_TABLE_QUERY = gql`
  query PROJECTS_TABLE_QUERY($where: UserWhereInput, $order: OrderType) {
    projects(order: $order) {
      id
      code_name
      duration_production
      duration_target
      created_at
      updated_at
      course {
        id
        premiere_session
        duration
        color
      }
      members(where: $where) {
        id
        first_name
        last_name
      }
      shootings {
        id
        starts_at
        ends_at
        address
        crew {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const CALENDAR_PROJECTS_QUERY = gql`
  query CALENDAR_PROJECTS_QUERY {
    projects {
      id
      code_name
      duration_production
      created_at
      updated_at
      course {
        id
        premiere_session
        duration
        color
      }
    }
  }
`

export const CALENDAR_PRODUCTS_QUERY = gql`
  query CALENDAR_PRODUCTS_QUERY {
    products {
      id
      release_date
      code_name
      catalog_funding_organisms {
        id
        release_date
        funding_organism {
          id
          name
        }
      }
    }
  }
`

export const CALENDAR_SHOOTINGS_QUERY = gql`
  query CALENDAR_SHOOTINGS_QUERY($where: ShootingWhereInput) {
    shootings(where: $where) {
      id
      starts_at
      ends_at
      address
      project {
        id
        code_name
        course {
          id
          color
        }
      }
      crew {
        id
        first_name
        last_name
      }
    }
  }
`

export const CALENDAR_USERS_QUERY = gql`
  query CALENDAR_USERS_QUERY($where: UserWhereInput) {
    users(where: $where) {
      id
      first_name
      last_name
      username
      job {
        id
        category
      }
      roles {
        id
        category
      }
    }
  }
`

export const CALENDAR_USER_ASSIGNMENT_QUERY = gql`
  query CALENDAR_USER_ASSIGNMENT_QUERY($where: AssignmentWhereInput) {
    assignments(where: $where) {
      id
      week
      date
      type
      project {
        id
        code_name
        course {
          color
        }
      }
    }
  }
`

export const CALENDAR_USER_LEAVES_QUERY = gql`
  query CALENDAR_USER_LEAVES_QUERY($where: LeaveWhereInput) {
    leaves(where: $where) {
      id
      date
    }
  }
`

export const CALENDAR_USER_SHOOTINGS_QUERY = gql`
  query CALENDAR_USER_SHOOTINGS_QUERY($where: ShootingWhereInput) {
    shootings(where: $where) {
      id
      starts_at
      ends_at
      address
      crew {
        id
        first_name
        last_name
      }
      project {
        id
        code_name
        course {
          id
          color
        }
      }
    }
  }
`

export const NOTIFICATIONS_QUERY = gql`
  query NOTIFICATIONS_QUERY($where: NotificationWhereInput, $order: OrderType, $take: Int) {
    notificationsCount(where: $where)
    notifications(where: $where, order: $order, take: $take) {
      id
      category
      content
      target_id
      sent_at
      read_at
      checked_at
      sender {
        id
        first_name
        last_name
      }
      target {
        id
        first_name
        last_name
      }
    }
  }
`

//EDITORS
export const EDITORS_QUERY = gql`
  query EDITORS_QUERY($where: UserWhereInput) {
    users(where: $where) {
      id
      username
    }
  }
`

//VIDEO REVIEW
export const VIDEO_REVIEW_QUERY = gql`
  query VIDEO_REVIEW_QUERY($where: VideoReviewWhereInput) {
    videoReview(where: $where) {
      id
      time_code
      lesson_video {
        id
        number
        video_set {
          id
          project {
            id
            code_name
          }
        }
      }
    }
  }
`

//VIDEO REVIEW COMMENT
export const VIDEO_REVIEW_COMMENT_QUERY = gql`
  query VIDEO_REVIEW_COMMENT_QUERY($where: VideoReviewCommentWhereInput) {
    videoReviewComment(where: $where) {
      id
      video_review {
        id
        time_code
        lesson_video {
          id
          number
          video_set {
            id
            project {
              id
              code_name
            }
          }
        }
      }
    }
  }
`

//LESSON
export const LESSON_VIDEO_QUERY = gql`
  query LESSON_VIDEO_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      transcription
      published_on
      created_at
      updated_at
      course {
        id
        code_name
        parent {
          id
          code_name
        }
        managers {
          id
        }
      }
      video {
        id
        number
        review_state
        file_path
        file_size
        file_duration
        thumbnail_timecode
        vimeo_url
        vimeo_status
        vimeo_iframe
        vimeo_lastsync
        walter_media_url
        thumbnail_timecode
        version
        video_diagrams {
          id
          name
          s3_file
          time_code
          comment
          state
          created_by {
            id
            username
          }
        }
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

// LESSON DACTYLO

export const LESSON_DACTYLO_QUERY = gql`
  query LESSON_DACTYLO_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      transcription
      published_on
      created_at
      updated_at
      course {
        id
        code_name
        parent {
          id
          code_name
        }
        managers {
          id
        }
      }
      dactylo {
        id
        transcript
        pausable
        lesson_video {
          id
          walter_media_url
          lesson {
            id
            name
            course {
              id
              code_name
            }
          }
        }
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const LESSON_VIDEO_NOTIFICATION_QUERY = gql`
  query LESSON_VIDEO_NOTIFICATION_QUERY($where: LessonVideoWhereInput) {
    lessonVideo(where: $where) {
      id
      number
      video_set {
        id
        index
        project {
          id
          code_name
        }
      }
    }
  }
`

export const LESSON_SUMMARY_NOTIFICATION_QUERY = gql`
  query LESSON_SUMMARY_NOTIFICATION_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      course {
        id
        code_name
      }
    }
  }
`

export const LESSON_VIDEO_DIAGRAM_NOTIFICATION_QUERY = gql`
  query LESSON_VIDEO_DIAGRAM_NOTIFICATION_QUERY($where: LessonVideoDiagramWhereInput) {
    lessonVideoDiagram(where: $where) {
      id
      time_code
      lesson_video {
        id
        number
        video_set {
          id
          index
          project {
            id
            code_name
          }
        }
      }
    }
  }
`

export const LESSON_DOCUMENT_QUERY = gql`
  query LESSON_DOCUMENT_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      document {
        id
        type
        downloadable
        s3_file
      }
      resources {
        id
        name
        pinned
        comment
        s3_file
        created_at
        updated_at
      }
    }
  }
`
export const LESSON_HTML_QUERY = gql`
  query LESSON_HTML_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      html {
        id
        content
        type
        created_at
        updated_at
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const LESSON_QUIZ_QUERY = gql`
  query LESSON_QUIZ_QUERY($where: LessonWhereInput, $qq_order: OrderType) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      quiz {
        id
        s3_file
        category
        display
        allow_retry
        created_at
        updated_at
        quiz_questions(order: $qq_order) {
          id
          position
          points
          lesson_question {
            id
            s3_image
            introduction
            help_text
            question_video {
              id
              walter_media_url
              vimeo_thumbnail
            }
            skills {
              id
              name
            }
            lesson {
              id
              name
              course {
                id
                code_name
              }
              video {
                id
              }
              text {
                id
                type
              }
              document {
                id
              }
              epp {
                id
              }
              quiz {
                id
              }
            }
            mc_question {
              id
              statement
              mc_items(order: $qq_order) {
                id
                statement
                s3_image
                correct
                item_video {
                  id
                  vimeo_thumbnail
                  walter_media_url
                }
              }
            }
            gapfill_question {
              id
              text
              gapfill_items {
                id
                position
                value
                element_id
              }
            }
            ranking_question {
              id
              statement
              ranking_items {
                id
                statement
                correct_position
                displayed_position
              }
            }
          }
        }
      }
      resources {
        id
        name
        pinned
        comment
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const LESSON_TASK_QUERY = gql`
  query LESSON_TASK_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      global_id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      task {
        id
        text
        correction_s3_file
        name
        description
        validation_threshold
        required
        grading_type
        type
        available_after_session_end
        need_correction_file
        created_at
        updated_at
        task_items {
          id
          name
          position
          required
          max_size
          require_walter_validation
          is_corrector_item
          is_available_after_correction
          created_at
          updated_at
        }
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const LESSON_TEXT_QUERY = gql`
  query LESSON_TEXT_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      global_id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      text {
        id
        content
        s3_file
        type
        downloadable
        updated_at
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

export const LESSON_EPP_QUERY = gql`
  query LESSON_EPP_QUERY($where: LessonWhereInput) {
    lesson(where: $where) {
      id
      name
      note
      validated
      summary
      summary_review
      summary_required
      summary_state
      published_on
      created_at
      updated_at
      course {
        id
        managers {
          id
        }
      }
      epp {
        id
        s3_grid_pdf
        epp_video_iframe
        grid_video_iframe
        inclusion_criteria
        exclusion_criteria
        episodes {
          id
          title
          position
          checkpoints {
            statement
            id
            advice
            position
          }
        }
      }
      resources {
        id
        name
        comment
        pinned
        s3_file
        created_at
        updated_at
      }
    }
  }
`

//COURSE_SKILLS
export const COURSE_SKILLS_QUERY = gql`
  query COURSE_SKILLS_QUERY($where: CourseSkillWhereInput, $order: OrderType) {
    courseSkills(where: $where, order: $order) {
      name
      id
      course {
        id
        code_name
      }
    }
  }
`

export const ALL_COURSE_SKILLS_QUERY = gql`
  query ALL_COURSE_SKILLS_QUERY($where: CourseSkillWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    courseSkills(where: $where, order: $order, take: $take, skip: $skip) {
      id
      name
      created_at
      updated_at
      course {
        id
        code_name
      }
    }
    courseSkillsCount
  }
`

export const ALL_COURSE_SKILLS_SEARCH_QUERY = gql`
  query ALL_COURSE_SKILLS_SEARCH_QUERY($order: OrderType, $searchQuery: String, $take: Int) {
    courseSkillsSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      name
      created_at
      updated_at
      course {
        id
        code_name
      }
    }
  }
`

export const SAME_COURSE_SKILLS_QUERY = gql`
  query SAME_COURSE_SKILLS_QUERY($where: CourseSkillWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    courseSkills(where: $where, order: $order, take: $take, skip: $skip) {
      id
      name
      created_at
      updated_at
      course {
        id
        code_name
      }
    }
  }
`

//COURSE LESSONS
export const COURSE_LESSONS_QUERY = gql`
  query COURSE_LESSONS_QUERY($where: LessonWhereInput, $courseWhere: CourseWhereInput, $order: OrderType) {
    lessons(where: $where, order: $order) {
      id
      name
      course {
        id
        name
        code_name
      }
      quiz {
        id
      }
      document {
        id
      }
      epp {
        id
      }
      text {
        id
        type
      }
      video {
        id
        vimeo_iframe
        vimeo_thumbnail
        vimeo_url
        lesson {
          id
          name
        }
      }
    }
    course(where: $courseWhere) {
      id
      code_name
      parts {
        id
        sub_parts {
          id
          sub_parts {
            id
            lesson {
              id
              name
              quiz {
                id
              }
              document {
                id
              }
              epp {
                id
              }
              text {
                id
                type
              }
              video {
                id
                vimeo_iframe
                vimeo_thumbnail
                vimeo_url
              }
              course {
                id
                code_name
                name
              }
            }
          }
        }
      }
    }
  }
`

export const COURSE_LESSON_QUESTIONS_QUERY = gql`
  query COURSE_LESSON_QUESTIONS_QUERY($where: CourseWhereInput, $order: OrderType) {
    course(where: $where) {
      id
      code_name
      lessons_questions(order: $order) {
        id
        mc_question {
          id
          statement
        }
        gapfill_question {
          id
          text
        }
        ranking_question {
          id
          statement
        }
      }
    }
  }
`

//LESSON VIDEO
export const LESSON_VIDEO_UPLOAD_TOKEN = gql`
  query lessonVideoUploadToken($id: ID) {
    lessonVideoUploadToken(id: $id)
  }
`

// LESSON TEXT CONVERTER TMP
export const TMP_LESSON_TEXT = gql`
  query TMP_LESSON_TEXT($where: LessonWhereInput) {
    lessons(where: $where) {
      id
      name
      text {
        content
        id
      }
    }
  }
`

//WRITERS
export const WRITERS_QUERY = gql`
  query WRITERS_QUERY {
    writers {
      id
      first_name
      last_name
      email
    }
  }
`

// TEACHING METHODS
export const TEACHING_METHODS_QUERY = gql`
  query TEACHING_METHODS_QUERY($order: OrderType) {
    teachingMethods(order: $order) {
      id
      name
      position
      is_default
    }
  }
`

//PRODUCTION REPORTS
export const PRODUCTION_REPORTS_QUERY = gql`
  query PRODUCTION_REPORTS_QUERY($period: ReportPeriod, $startDate: String, $endDate: String) {
    productionReports(period: $period, startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      period
      reports {
        num_of_uploads
        duration_of_uploads
        work_days_count
        first_attempt_validation_video_count
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`

export const PRODUCTION_REPORT_QUERY = gql`
  query PRODUCTION_REPORT_QUERY($period: ReportPeriod, $where: ProductionReportWhereInput) {
    productionReport(where: $where, period: $period) {
      id
      report_date
      durations {
        id
        uploaded_duration_goal
        uploaded_duration
        validated_duration
        project {
          id
          code_name
        }
        editor {
          id
          first_name
          last_name
          current
        }
      }
      project_reports {
        id
        shot_duration
        stock_duration
        project {
          id
          code_name
        }
      }
    }
    productionReportsCount(where: $where, period: $period)
  }
`

//LEAVES
export const LEAVES_QUERY = gql`
  query LEAVES_QUERY($where: LeaveWhereInput) {
    leaves(where: $where) {
      id
      date
      user {
        id
        first_name
        last_name
      }
    }
  }
`

export const SHORT_LESSON_QUESTIONS_QUERY = gql`
  query SHORT_LESSON_QUESTIONS_QUERY($where: LessonWhereInput, $orderItems: OrderType) {
    lessons(where: $where) {
      id
      lesson_questions {
        id
        question_video {
          id
          walter_media_url
          vimeo_thumbnail
        }
        lesson {
          id
          name
        }
        mc_question {
          id
          statement
          mc_items(order: $orderItems) {
            id
            statement
            correct
            position
            s3_image
            item_video {
              id
              vimeo_thumbnail
              walter_media_url
            }
          }
        }
        gapfill_question {
          id
          text
          gapfill_items {
            id
            position
            value
            element_id
          }
        }
        ranking_question {
          id
          statement
          ranking_items {
            id
            statement
            correct_position
            displayed_position
          }
        }
      }
    }
  }
`

//LESSON_QUESTIONS
export const LESSON_QUESTIONS_QUERY = gql`
  query LESSON_QUESTIONS_QUERY(
    $order: OrderType
    $where: LessonQuestionWhereInput
    $orderItems: OrderType
    $skip: Int
    $take: Int
  ) {
    lessonQuestions(order: $order, where: $where, skip: $skip, take: $take) {
      id
      introduction
      help_text
      correction_text
      correction_video {
        id
        lesson {
          id
          name
          course {
            id
            code_name
          }
        }
      }
      created_at
      updated_at
      s3_image
      skills {
        id
        name
      }
      mc_question {
        id
        statement
        mc_items(order: $orderItems) {
          id
          statement
          correct
          position
          item_video {
            id
            walter_media_url
            vimeo_thumbnail
          }
        }
      }
      gapfill_question {
        id
        text
        gapfill_items {
          id
          position
          value
          element_id
        }
      }
      ranking_question {
        id
        statement
        ranking_items {
          id
          statement
          correct_position
          displayed_position
        }
      }
      lesson {
        id
        name
        course {
          id
          code_name
        }
      }
    }
    lessonQuestionsCount
  }
`

export const SAME_LESSON_QUESTIONS_QUERY = gql`
  query SAME_LESSON_QUESTIONS_QUERY($where: LessonQuestionWhereInput, $orderItems: OrderType) {
    lessonQuestions(where: $where) {
      id
      introduction
      help_text
      created_at
      updated_at
      s3_image
      skills {
        id
        name
      }
      mc_question {
        id
        statement
        mc_items(order: $orderItems) {
          id
          statement
          correct
          position
          item_video {
            id
            walter_media_url
            vimeo_thumbnail
          }
        }
      }
      gapfill_question {
        id
        text
        gapfill_items {
          id
          position
          value
          element_id
        }
      }
      ranking_question {
        id
        statement
        ranking_items {
          id
          statement
          correct_position
          displayed_position
        }
      }
      lesson {
        id
        name
        course {
          id
          code_name
        }
      }
    }
  }
`

export const LESSON_QUESTIONS_SEARCH_QUERY = gql`
  query LESSON_QUESTIONS_SEARCH_QUERY(
    $searchQuery: String!
    $where: LessonQuestionWhereInput
    $orderItems: OrderType
    $order: OrderType
    $take: Int
  ) {
    lessonQuestionsSearch(searchQuery: $searchQuery, where: $where, order: $order, take: $take) {
      id
      introduction
      help_text
      correction_text
      correction_video {
        id
        lesson {
          id
          name
          course {
            id
            code_name
          }
        }
      }
      created_at
      updated_at
      s3_image
      skills {
        id
        name
      }
      mc_question {
        id
        statement
        mc_items(order: $orderItems) {
          id
          statement
          correct
          position
          item_video {
            id
            walter_media_url
            vimeo_thumbnail
          }
        }
      }
      gapfill_question {
        id
        text
        gapfill_items {
          id
          position
          value
          element_id
        }
      }
      ranking_question {
        id
        statement
        ranking_items {
          id
          statement
          correct_position
          displayed_position
        }
      }
      lesson {
        id
        name
        course {
          id
          code_name
        }
      }
    }
  }
`

//RESOURCES
export const RESOURCES_QUERY = gql`
  query RESOURCES_QUERY($where: ResourceWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    resources(where: $where, order: $order, take: $take, skip: $skip) {
      id
      name
      pinned
      s3_file
      comment
      created_at
      updated_at
      course {
        id
        code_name
      }
    }
    resourcesCount
  }
`

export const RESOURCES_SEARCH_QUERY = gql`
  query RESOURCES_SEARCH_QUERY($order: OrderType, $searchQuery: String, $take: Int) {
    resourcesSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      name
      s3_file
      comment
      created_at
      updated_at
      course {
        id
        code_name
      }
    }
  }
`

export const SAME_RESOURCES_QUERY = gql`
  query SAME_RESOURCES_QUERY($where: ResourceWhereInput, $order: OrderType, $skip: Int, $take: Int) {
    resources(where: $where, order: $order, take: $take, skip: $skip) {
      id
      name
      pinned
      s3_file
      comment
      created_at
      updated_at
      course {
        id
        code_name
        lessons {
          id
          name
        }
      }
    }
  }
`

export const DOWNLOAD_LESSON_HTML_EXCEL = gql`
  query DOWNLOAD_LESSON_HTML_EXCEL($where: CourseWhereInput) {
    downloadLessonHtmlExcel(where: $where)
  }
`

export const DOWNLOAD_COURSE_PLAN_EXCEL = gql`
  query DOWNLOAD_COURSE_PLAN_EXCEL($where: CourseWhereInput) {
    downloadCoursePlanExcel(where: $where)
  }
`

export const VIDEO_SET_NOTIFICATION_QUERY = gql`
  query VIDEO_SET_NOTIFICATION_QUERY($where: VideoSetWhereInput) {
    videoSet(where: $where) {
      id
      index
      project {
        id
        code_name
      }
    }
  }
`

//COURSE FEEDBACK
export const COURSE_FEEDBACK_QUERY = gql`
  query COURSE_FEEDBACK_QUERY($where: CourseWhereInput) {
    course(where: $where) {
      id
      name
      feedback {
        id
        teacher_rating
        difficulties
        cdp_acdp_rating
        teacher_recruiting_improvement
        setup_teacher_rating
        shooting_rating
        shooting_duration_rating
        pre_prod_improvement
        design
        editor
        project_manager
        project_manager_director_editor_rating
        post_production_improvement
        created_at
        updated_at
      }
    }
  }
`

//COURSE DEMO
export const COURSES_DEMO_QUERY = gql`
  query COURSES_DEMO_QUERY($where: CourseWhereInput) {
    courses(where: $where) {
      id
      code_name
      name
    }
  }
`

//PRODUCT THEMES
export const PRODUCT_THEMES_PAGE_QUERY = gql`
  query PRODUCT_THEMES_PAGE_QUERY($order: OrderType, $skip: Int, $take: Int) {
    productThemes(order: $order, skip: $skip, take: $take) {
      id
      code_name
      title
      description
      position
      selling_point
      catchphrase
      default_product {
        id
        code_name
      }
      products {
        id
        code_name
      }
      created_at
      updated_at
    }
    productThemesCount
  }
`

export const PRODUCT_THEMES_SEARCH_QUERY = gql`
  query PRODUCT_THEMES_SEARCH_QUERY($searchQuery: String, $order: OrderType, $take: Int) {
    productThemesSearch(searchQuery: $searchQuery, order: $order, take: $take) {
      id
      code_name
      title
      description
      position
      selling_point
      catchphrase
      default_product {
        id
        code_name
      }
      products {
        id
        code_name
      }
      created_at
      updated_at
    }
  }
`
export const PRODUCT_THEME_QUERY = gql`
  query PRODUCT_THEME_QUERY($where: ProductThemeWhereInput) {
    productTheme(where: $where) {
      id
      code_name
      title
      description
      position
      catchphrase
      selling_point
      default_product {
        id
        code_name
      }
      default_product {
        id
        code_name
      }
      products {
        id
        code_name
      }
      created_at
      updated_at
    }
  }
`

// THEMATIC QUERY
export const THEMATIC_CODE_NAME_QUERY = gql`
  query THEMATIC_CODE_NAME_QUERY($where: ThematicWhereInput) {
    newThematicCodeName(where: $where)
  }
`
