import { useState } from 'react'
import { DisciplineType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
import { Grid, Button, useTheme, TextField } from '@material-ui/core'
import { useAlert } from '../../hooks'
import { useMutation } from '@apollo/client'
import { UPDATE_DISCIPLINE_MUTATION } from '../../gql/mutations'
import { handleError } from '../../utils/handleError'
import { FormLayout } from '../../components/Forms'
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import NOIMG_card from '../../assets/NOIMG_card.jpg'
import NOIMG_logo from '../../assets/NOIMG_logo.svg'

type DisciplineVisualsFormPropsType = {
  discipline: DeepPartial<DisciplineType> | undefined
  handleClose: () => void
  refetch: () => Promise<any>
}

const DisciplineVisualsForm = ({ discipline, handleClose, refetch }: DisciplineVisualsFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [visuals, setVisuals] = useState({
    s3_card: {
      selectedFile: undefined,
      localImg: undefined,
      s3Url: discipline?.s3_card,
      defaultImg: NOIMG_card,
    },
    s3_banner: {
      selectedFile: undefined,
      localImg: undefined,
      s3Url: discipline?.s3_banner,
      defaultImg: NOIMG_banner,
    },
    s3_logo: {
      selectedFile: undefined,
      localImg: undefined,
      s3Url: discipline?.s3_logo,
      defaultImg: NOIMG_logo,
    },
  })
  const [color, setColor] = useState(discipline?.color)

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget
    console.log('id', id)
    const selectedFile = e?.target?.files?.[0]
    if (selectedFile) {
      setVisuals((prevState) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id as keyof typeof prevState],
            selectedFile: selectedFile,
            localImg: URL.createObjectURL(selectedFile),
          },
        }
      })
    } else {
      setVisuals((prevState) => {
        return {
          ...prevState,
          [id]: {
            ...prevState[id as keyof typeof prevState],
            selectedFile: undefined,
            localImg: undefined,
          },
        }
      })
    }
  }

  const [updateDisciplineMutation] = useMutation<{ updateDiscipline: DisciplineType }>(UPDATE_DISCIPLINE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Image uploadée` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (discipline) {
      await updateDisciplineMutation({
        variables: {
          input: {
            id: discipline.id,
            s3_card: visuals.s3_card.selectedFile,
            s3_banner: visuals.s3_banner.selectedFile,
            s3_logo: visuals.s3_logo.selectedFile,
            color: color,
          },
        },
      }).catch(console.error)
    }
  }

  return (
    <FormLayout type="update" title={'Mettre à jour les visuels'} onSubmit={handleSubmit} submitDisabled={false}>
      <Grid item container direction="column" justifyContent="space-around" alignItems="center" spacing={1}>
        {Object.keys(visuals).map((key) => {
          const vis = visuals[key as keyof typeof visuals]
          return (
            <Grid item key={key} style={{ marginTop: theme.spacing(2) }}>
              <img
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '200px',
                }}
                src={vis?.localImg ?? `${vis?.s3Url ?? ''}?${new Date().getTime()}`}
                loading="lazy"
                alt={`S3_picture - ${key}`}
                onError={({ currentTarget }) => {
                  currentTarget.src = vis.defaultImg
                }}
              />
              <Grid container justifyContent="center">
                <Button color="primary" variant="contained" component="label">
                  Selectionner
                  <input hidden accept=".jpg,.jpeg,.png,.svg" id={key} type="file" onChange={onSelectFile} />
                </Button>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid item container>
        <Grid item container>
          <Grid
            item
            style={{
              width: '100%',
              height: 250,
              backgroundColor: color,
              border: `1px solid ${theme.palette.text.disabled}`,
              borderRadius: 5,
            }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          label="Couleur (HEX)"
          name="color"
          value={color}
          onChange={(e) => {
            e.preventDefault()
            setColor(e.target.value)
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </FormLayout>
  )
}

export default DisciplineVisualsForm
