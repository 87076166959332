// REACT
import { useRef, useState } from 'react'
//MATERIAL UI
import { MdDragIndicator, MdExpandLess, MdExpandMore } from 'react-icons/md'
import { DisciplineType } from '../../../../lib/sharedTypes'
//MATERIAL UI
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core'
import ThematicCard from './ThematicCard'
//DND
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'
//TYPES
import { DeepPartial } from '../../utils/types'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
import NOIMG_banner from '../../assets/NOIMG_banner.jpg'
import { MdOutlineModeEdit } from 'react-icons/md'
import { CustomModal } from '../../components'
import DisciplineVisualsForm from './DisciplineVisualsForm'

type DisciplineCardPropsType = {
  discipline: DeepPartial<DisciplineType> | undefined
  index: number
  closed: boolean
  setOpenedCards: React.Dispatch<React.SetStateAction<(number | undefined | string)[]>>
  openedCards: (number | undefined | string)[]
  moveCard: (dragIndex: number, hoverIndex: number, item: DragItem) => void
  refetch: () => Promise<any>
}

type DragItem = {
  index: number
  id: string
  type: string
}

const DisciplineCard = ({
  discipline,
  index,
  closed,
  openedCards,
  setOpenedCards,
  moveCard,
  refetch,
}: DisciplineCardPropsType) => {
  const theme = useTheme()
  const [editDisciplineVisual, setEditDisciplineVisual] = useState(false)
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'DISCIPLINE_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'DISCIPLINE_CARD',
    item: { item: discipline, index: index, type: 'DISCIPLINE_CARD' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Grid
      item
      container
      direction="row"
      style={{
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
        opacity: isDragging ? 0 : 1,
      }}
      ref={ref}
      data-handler-id={handlerId}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        style={{
          width: '2%',
          flexGrow: 1,
          borderRadius: '10px 0px 0px 10px',
          cursor: 'grab',
          background: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
        }}
        id="12"
        ref={drag}
      >
        <Grid item>
          <MdDragIndicator size={22} />
        </Grid>
      </Grid>
      <Grid style={{ width: '98%', background: theme.palette.background.default, padding: theme.spacing(1) }}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item alignContent="center">
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {discipline?.name} - {discipline?.duration} heure(s) -{' '}
              <i style={{ fontWeight: 'normal', fontSize: '12px' }}>{discipline?.code_name ?? 'pas de nom de code'}</i>
              <IconButton
                style={{ padding: theme.spacing(1) }}
                onClick={(e) => {
                  e.stopPropagation()
                  setEditDisciplineVisual(true)
                }}
              >
                <MdOutlineModeEdit size="22" style={{ cursor: 'pointer' }} />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                if (closed) {
                  setOpenedCards((prevState) => {
                    return [...prevState, discipline?.id]
                  })
                } else {
                  setOpenedCards((prevState) => {
                    return prevState.filter((id) => id !== discipline?.id)
                  })
                }
              }}
            >
              {closed ? <MdExpandMore /> : <MdExpandLess />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction="row">
          {!closed && (
            <Grid xs={2}>
              <img
                alt="Card"
                style={{
                  width: '100%',
                  // height: '100%',
                  zIndex: 1,
                }}
                src={discipline?.s3_banner ?? NOIMG_banner}
              />
            </Grid>
          )}
          <Grid ref={animateRef} style={{ flex: 1 }}>
            {!closed &&
              discipline?.thematics?.map((thematic, themIndex) => {
                return (
                  <ThematicCard
                    key={thematic?.id}
                    thematic={thematic}
                    index={themIndex}
                    moveCard={moveCard}
                    setOpenedCards={setOpenedCards}
                    closed={!openedCards.includes(thematic?.global_id)}
                  />
                )
              })}
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        open={editDisciplineVisual}
        onClose={() => {
          setEditDisciplineVisual(false)
        }}
        animation="slide-up"
      >
        <DisciplineVisualsForm
          discipline={discipline}
          handleClose={() => {
            setEditDisciplineVisual(false)
          }}
          refetch={refetch}
        />
      </CustomModal>
    </Grid>
  )
}

export default DisciplineCard
