import { useState } from 'react'
// COMPONENTS
import {
  GeneralInformationsForm,
  SeoForm,
  FundingForm,
  ProductForm,
  OpportunityForm,
} from '../../components/Forms/catalog'
import { Loading, Error, CustomModal, TextEditor } from '../../components'
// MATERIAL UI
import { Grid, Button, Divider, Typography, makeStyles, useTheme, Paper, Chip } from '@material-ui/core'
// TYPE
import { CatalogStatusEnum, CatalogType } from '../../../../lib/sharedTypes'
// GQL
import { CATALOG_GENERAL_INFO_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CatalogOpportunity } from 'src/utils/types'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const GeneralInformations = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { catalog_id } = useParams()
  const [updateModal, setUpdateModal] = useState('')

  if (!catalog_id) {
    return <Error error={'Unknown catalog_id'} />
  }
  const {
    loading,
    error,
    data: { catalog } = {},
    refetch,
  } = useQuery<{
    catalog: CatalogType
  }>(CATALOG_GENERAL_INFO_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(catalog_id.toString()),
        },
      },
    },
  })

  if (loading || !catalog) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container direction="column">
        <Paper className={classes.paper}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Description</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('description')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <Grid item container>
                <Grid item sm={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Nom
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.name}</Typography>
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Catalogue parent
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {catalog?.parent?.name ? (
                        <Chip
                          size="small"
                          label={catalog?.parent?.name}
                          style={{
                            margin: theme.spacing(1),
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item sm={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Position parmis les catalogues
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.position}</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column" sm={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Affichage sur le showcase
                    </Typography>
                  </Grid>
                  <Grid item>
                    {catalog.status === CatalogStatusEnum.AVAILABLE ? (
                      <Chip
                        size="small"
                        label={'Affiché'}
                        style={{
                          margin: theme.spacing(1),
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.success.contrastText,
                        }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label={'Non affiché'}
                        style={{
                          margin: theme.spacing(1),
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.error.contrastText,
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Envoi vers Walter SF
                    </Typography>
                  </Grid>
                  <Grid item>
                    {catalog.send_to_walter_sf === true ? (
                      <Chip
                        size="small"
                        label={'Oui'}
                        style={{
                          margin: theme.spacing(1),
                          backgroundColor: theme.palette.success.main,
                          color: theme.palette.success.contrastText,
                        }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label={'Non'}
                        style={{
                          margin: theme.spacing(1),
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.error.contrastText,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Titre
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.title}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Breadcrumbs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.breadcrumb}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Contenu
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.content}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Titre liste des formations
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.courses_list_title}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Sous-titre liste des formations
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.courses_list_subtitle}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre avis clients
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{catalog?.reviews_subtitle}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  Titre formation en ligne
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{catalog?.online_course_title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  CTA catalogue de formation
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{catalog?.catalog_pdf_cta}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* OPPORTUNITY */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Opportunités</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('opportunity')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Titre
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.opportunity_title}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Description
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.opportunity_text}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                {catalog?.opportunity_list &&
                  (catalog?.opportunity_list as CatalogOpportunity[]).map((opportunity, index) => {
                    return (
                      <Grid key={index} item container direction="column" spacing={3}>
                        <Grid item container direction="row" spacing={3}>
                          <Grid item container direction="column" xs={5}>
                            <Grid item>
                              <Typography variant="body1" color="textSecondary" className={classes.title}>
                                Opportunité n°{index + 1}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">{opportunity.name}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container direction="column" xs={7}>
                            <Grid item>
                              <Typography variant="body1" color="textSecondary" className={classes.title}>
                                Lien
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">{opportunity.href}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Divider />
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* SEO */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">SEO</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('seo')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    URL
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.seo_url}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Canonical URL
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.canonical_url}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Titre
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.seo_title}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Description
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.seo_meta_description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* FUNDING */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Financement</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('funding')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Sous-titre
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.funding_subtitle}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Texte
                  </Typography>
                </Grid>
                <Grid item key={new Date(catalog.updated_at).getTime()}>
                  <TextEditor text={catalog.funding_text} readOnly={true} />
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Montant example
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.andpc_compensation_example_amount}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Montant example description
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.andpc_compensation_example_description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* PRODUCT */}
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Produits</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateModal('product')
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Titre
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{catalog?.course_top_title}</Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Top 1
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.product_top_1?.code_name}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Top 2
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.product_top_2?.code_name}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="body1" className={classes.title}>
                      Top 3
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{catalog?.product_top_3?.code_name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Produits
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    {catalog?.product_catalog_positions
                      .sort((a, b) => a.position - b.position)
                      .map((pcp) => {
                        return (
                          <Grid item key={pcp.id}>
                            <Chip size="small" label={pcp?.product?.code_name} color="primary" />
                          </Grid>
                        )
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <CustomModal
          open={updateModal === 'description'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <GeneralInformationsForm
            catalog={catalog}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'opportunity'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <OpportunityForm
            catalog={catalog}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'seo'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <SeoForm
            catalog={catalog}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'funding'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <FundingForm
            catalog={catalog}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
        <CustomModal
          open={updateModal === 'product'}
          onClose={() => {
            setUpdateModal('')
          }}
          animation="slide-up"
        >
          <ProductForm
            catalog={catalog}
            refetch={refetch}
            handleClose={() => {
              setUpdateModal('')
            }}
          />
        </CustomModal>
      </Grid>
    </DndProvider>
  )
}

export default GeneralInformations
