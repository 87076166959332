//REACT
import React from 'react'
//MATERIAL UI
import { SwipeableDrawer, List, ListItem, ListItemText, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
//TYPES
import type { RoleType } from '../../../lib/sharedTypes'
//REACT-ROUTER
import { useNavigate, useLocation } from 'react-router-dom'
//ICONS
import { BsCameraReels, BsCameraReelsFill } from 'react-icons/bs'
import {
  RiHome2Fill,
  RiHome2Line,
  RiFileEditLine,
  RiFileEditFill,
  RiShoppingCart2Fill,
  RiShoppingCart2Line,
  RiSettings5Fill,
  RiSettings5Line,
} from 'react-icons/ri'
import { PiStudentLight, PiStudentFill } from 'react-icons/pi'
import { HiDocumentReport, HiOutlineDocumentReport } from 'react-icons/hi'
import { MdLibraryBooks, MdOutlineLibraryBooks } from 'react-icons/md'
import { IoCalendarNumberOutline, IoCalendarNumberSharp, IoSchoolOutline } from 'react-icons/io5'
import { IoMdSchool } from 'react-icons/io'
//REDUX
import { useAppSelector } from '../hooks/reducerHooks'
//UTILS
import { isAuthorized } from '../utils/misc'
import { FaList, FaListAlt } from 'react-icons/fa'

type DrawerPropsType = {
  mdUp: boolean
  setDrawerOpen: (arg: boolean) => void
  drawerOpen: boolean
  drawerWidth: string
}

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      top: '64px',
    },
  },
  listItem: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    fontSize: '1em',
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function Drawer(props: DrawerPropsType) {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector((state) => state.user.user)
  const userRoles = user && user.roles ? user.roles.map((role: RoleType) => role.category as string) : []
  return (
    <SwipeableDrawer
      PaperProps={{ style: { width: props.drawerWidth } }}
      classes={{ paper: classes.drawerContainer }}
      variant={props.mdUp ? 'persistent' : 'temporary'}
      anchor="left"
      open={props.drawerOpen}
      onClose={() => {
        props.setDrawerOpen(false)
      }}
      onOpen={() => {
        props.setDrawerOpen(true)
      }}
    >
      <List style={{ paddingTop: 0 }}>
        <ListItem
          button
          component="a"
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/', '_blank')
            } else {
              navigate('/')
            }
          }}
          selected={location.pathname === '/'}
        >
          {location.pathname === '/' ? (
            <RiHome2Fill size="24px" className={classes.listItemIcon} />
          ) : (
            <RiHome2Line size="24px" className={classes.listItemIcon} />
          )}
          <ListItemText className={classes.listItemText}>Accueil</ListItemText>
        </ListItem>
        <ListItem
          button
          disabled={!isAuthorized(['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO', 'FINANCE'])}
          component="a"
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/projects', '_blank')
            } else {
              navigate('/projects')
            }
          }}
          selected={location.pathname.includes('/projects')}
        >
          {location.pathname.includes('/projects') ? (
            <BsCameraReelsFill size="24px" className={classes.listItemIcon} />
          ) : (
            <BsCameraReels size="24px" className={classes.listItemIcon} />
          )}
          <ListItemText className={classes.listItemText}>Projets</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          disabled={!isAuthorized(['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO'])}
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/formations', '_blank')
            } else {
              navigate('/formations')
            }
          }}
          selected={location.pathname.includes('/formations')}
        >
          {location.pathname.includes('/formations') ? (
            <RiFileEditFill size="24px" className={classes.listItemIcon} />
          ) : (
            <RiFileEditLine size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Formations</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          disabled={!isAuthorized(['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO'])}
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/products', '_blank')
            } else {
              navigate('/products')
            }
          }}
          selected={location.pathname.includes('/products')}
        >
          {location.pathname.includes('/products') ? (
            <RiShoppingCart2Fill size="24px" className={classes.listItemIcon} />
          ) : (
            <RiShoppingCart2Line size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Produits</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          disabled={!isAuthorized(['ADMIN', 'SALE'])}
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/themes', '_blank')
            } else {
              navigate('/themes')
            }
          }}
          selected={location.pathname.includes('/themes')}
        >
          {location.pathname.includes('/themes') ? (
            <FaListAlt size="24px" className={classes.listItemIcon} />
          ) : (
            <FaList size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Themes</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          disabled={!isAuthorized(['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SALE', 'SEO'])}
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/apprenticeships', '_blank')
            } else {
              navigate('/apprenticeships')
            }
          }}
          selected={location.pathname.includes('/apprenticeships')}
        >
          {location.pathname.includes('/apprenticeships') ? (
            <PiStudentFill size="24px" className={classes.listItemIcon} />
          ) : (
            <PiStudentLight size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Apprentissages</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO'])}
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/catalogues', '_blank')
            } else {
              navigate('/catalogues')
            }
          }}
          selected={location.pathname.includes('/catalogues')}
        >
          {location.pathname.includes('/catalogues') ? (
            <MdLibraryBooks size="24px" className={classes.listItemIcon} />
          ) : (
            <MdOutlineLibraryBooks size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Catalogues</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          className={classes.listItem}
          disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'PROJECT', 'SEO'])}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/teachers', '_blank')
            } else {
              navigate('/teachers')
            }
          }}
          selected={location.pathname.includes('/teachers')}
        >
          {location.pathname.includes('/teachers') ? (
            <IoMdSchool size="24px" className={classes.listItemIcon} />
          ) : (
            <IoSchoolOutline size="24px" className={classes.listItemIcon} />
          )}

          <ListItemText className={classes.listItemText}>Formateurs</ListItemText>
        </ListItem>

        <ListItem
          button
          component="a"
          disabled={
            !isAuthorized(['USER', 'ADMIN', 'PRODUCTION', 'PROJECT', 'DIRECTOR', 'EDITOR', 'SALE', 'SEO', 'DESIGN'])
          }
          className={classes.listItem}
          onClick={(e: React.MouseEvent) => {
            if (e.metaKey || e.ctrlKey) {
              window.open('/calendar', '_blank')
            } else {
              navigate('/calendar')
            }
          }}
          selected={location.pathname.includes('/calendar')}
        >
          {location.pathname.includes('/calendar') ? (
            <IoCalendarNumberSharp size="24px" className={classes.listItemIcon} />
          ) : (
            <IoCalendarNumberOutline size="24px" className={classes.listItemIcon} />
          )}
          <ListItemText className={classes.listItemText}>Calendrier</ListItemText>
        </ListItem>

        {userRoles && (isAuthorized(['ADMIN']) || user?.email === 'nina.le-tumelin@walter-learning.com') && (
          <>
            <Divider style={{ margin: theme.spacing(1) }} />
            <ListItem
              button
              component="a"
              className={classes.listItem}
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  window.open('/reports', '_blank')
                } else {
                  navigate('/reports')
                }
              }}
              selected={location.pathname.includes('/reports')}
            >
              {location.pathname.includes('/reports') ? (
                <HiDocumentReport size="26px" className={classes.listItemIcon} />
              ) : (
                <HiOutlineDocumentReport size="26px" className={classes.listItemIcon} />
              )}

              <ListItemText className={classes.listItemText}>Rapports</ListItemText>
            </ListItem>
          </>
        )}
        {userRoles && isAuthorized(['ADMIN']) && (
          <ListItem
            button
            component="a"
            className={classes.listItem}
            onClick={(e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                window.open('/settings', '_blank')
              } else {
                navigate('/settings')
              }
            }}
            selected={location.pathname.includes('/settings')}
          >
            {location.pathname.includes('/settings') ? (
              <RiSettings5Fill size="24px" className={classes.listItemIcon} />
            ) : (
              <RiSettings5Line size="24px" className={classes.listItemIcon} />
            )}

            <ListItemText className={classes.listItemText}>Paramètres</ListItemText>
          </ListItem>
        )}
      </List>
    </SwipeableDrawer>
  )
}
