/* eslint-disable */
import { useCallback, useRef, useState } from 'react'
// MATERIAL UI
import { Divider, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
//ICONS
import { MdOutlineAddCircleOutline, MdOutlineDelete } from 'react-icons/md'
// COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//AUTO ANIMATE
import { useAutoAnimate } from '@formkit/auto-animate/react'
//UTILS
import { ProductKeyInformations } from 'src/utils/types'
import { handleError } from '../../../utils/handleError'
//DND
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd'
import { Identifier } from 'dnd-core'
import { HTML5Backend } from 'react-dnd-html5-backend'

type KeyInformationsFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const KeyInformationsForm = ({ product, refetch, handleClose }: KeyInformationsFormPropsType) => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      key_informations: (stateProduct.key_informations as ProductKeyInformations[]).map((pki, i) => {
        return {
          ...pki,
          position: i,
        }
      }),
    }
    await updateProductMutation({ variables: { input: productInput } })
  }

  const swap = (arr: any[] | undefined, from: number, to: number) => {
    if (!arr) return
    if (!arr[to] || !arr[from]) return arr
    const temp = arr[from]
    arr[from] = arr[to]
    arr[to] = temp
    return arr
  }

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, item: any) => {
      switch (item.type) {
        case 'KEY_INFORMATION_CARD':
          const key_informations = swap(
            stateProduct.key_informations as ProductKeyInformations[],
            dragIndex,
            hoverIndex,
          )
          setStateProduct((prevState) => {
            return {
              ...prevState,
              key_informations: (key_informations as ProductKeyInformations[])?.map((pki, i) => {
                return {
                  ...pki,
                  position: i,
                }
              }),
            }
          })
          break
        default:
          break
      }
    },
    [stateProduct, setStateProduct],
  )
  console.log(JSON.stringify(stateProduct.key_informations), JSON.stringify(product.key_informations))

  return (
    <FormLayout
      type="update"
      title="Mettre à jour les informations clés de la formation"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct.key_informations) === JSON.stringify(product.key_informations)}
    >
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h6">Information clés</Typography>
        </Grid>
        <IconButton
          onClick={() => {
            const key_informations = (stateProduct.key_informations as ProductKeyInformations[]) ?? []
            key_informations.push({
              id: new Date().getTime(),
              title: '',
              description: '',
            } as ProductKeyInformations)

            setStateProduct((prevState) => {
              return {
                ...prevState,
                key_informations: key_informations,
              }
            })
          }}
        >
          <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Grid>
      <Grid item container direction="column" spacing={2} ref={animateRef}>
        <DndProvider backend={HTML5Backend}>
          {(stateProduct.key_informations as ProductKeyInformations[])?.map((product_key_information, index) => {
            return (
              <ProductKeyInformationCard
                product_key_information={product_key_information}
                index={index}
                setStateProduct={setStateProduct}
                stateProduct={stateProduct}
                moveCard={moveCard}
                key={product_key_information.id}
              />
            )
          })}
        </DndProvider>
      </Grid>
    </FormLayout>
  )
}

type DragItem = {
  index: number
  id: string
  type: string
}

const ProductKeyInformationCard = ({
  product_key_information,
  stateProduct,
  setStateProduct,
  index,
  moveCard,
}: {
  product_key_information: ProductKeyInformations
  stateProduct: ProductType
  setStateProduct: React.Dispatch<React.SetStateAction<ProductType>>
  index: number
  moveCard: (dragIndex: number, hoverIndex: number, item: any) => void
}) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'KEY_INFORMATION_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'KEY_INFORMATION_CARD',
    item: { item: product_key_information, index: product_key_information.id, type: 'KEY_INFORMATION_CARD' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Grid
      item
      container
      direction="column"
      style={{
        opacity: isDragging ? 0 : 1,
      }}
      data-handler-id={handlerId}
      ref={ref}
    >
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>{`Information #${index + 1}`}</Typography>
        </Grid>

        <Grid item>
          <IconButton
            color="primary"
            onClick={() => {
              setStateProduct((prevState) => {
                return {
                  ...prevState,
                  key_informations: (prevState.key_informations as ProductKeyInformations[]).filter((pki) => {
                    return pki.id !== product_key_information.id
                  }),
                }
              })
            }}
          >
            <MdOutlineDelete size={24} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: theme.spacing(2) }}>
        <Divider />
      </Grid>
      <Grid item container spacing={1} direction="row">
        <Grid item container direction="row" spacing={1}>
          <Grid item xs={6}>
            <TextField
              label={`Titre`}
              name="title"
              value={product_key_information.title}
              onChange={(e) => {
                e.preventDefault()

                const key_informations = (stateProduct.key_informations as ProductKeyInformations[]).map((info, i) => {
                  if (i === index) {
                    return {
                      ...info,
                      title: e.target.value,
                    }
                  } else {
                    return info
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    key_informations: key_informations,
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={`Valeur`}
              name="description"
              value={product_key_information.description}
              onChange={(e) => {
                e.preventDefault()

                const key_informations = (stateProduct.key_informations as ProductKeyInformations[]).map((info, i) => {
                  if (i === index) {
                    return {
                      ...info,
                      description: e.target.value,
                    }
                  } else {
                    return info
                  }
                })

                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    key_informations: key_informations,
                  }
                })
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default KeyInformationsForm
