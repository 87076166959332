//REACT
import { useEffect } from 'react'
//CSS
import './Program.css'
//MATERIAL UI
import { Grid, makeStyles, Typography, Divider } from '@material-ui/core'
//ASSETS
import WL_WHITE_LOGO from '../../assets/program/logo/wl-white.png'
import WL_BLACK_LOGO from '../../assets/program/logo/wl-black.png'
import WS_WHITE_LOGO from '../../assets/program/logo/ws-white.png'
import WS_BLACK_LOGO from '../../assets/program/logo/ws-black.png'
import DURATION from '../../assets/program/picto/duree-formation.png'
import EURO from '../../assets/program/picto/euro.png'
import SUPPORT from '../../assets/program/picto/items_support.png'
import DPC from '../../assets/program/picto/dpc.png'
import CHECK from '../../assets/program/picto/check.png'
import EXPERT from '../../assets/program/picto/items_experts.png'
import PLATFORM from '../../assets/program/picto/items_plateforme.png'
import HEART from '../../assets/program/picto/items_avecvous.png'
import CAMERA from '../../assets/program/picto/items_videos.png'
import PAPERCLIP from '../../assets/program/picto/items_docs.png'
import TEST from '../../assets/program/picto/items_tests.png'
import CUP from '../../assets/program/picto/items_progression.png'
import HELP from '../../assets/program/picto/items_aide.png'
// import FOOTER_WL from '../../assets/program/img/footer_wl.png'
// import FOOTER_WS from '../../assets/program/img/footer_ws.png'
import DPC_BG from '../../assets/program/img/dpc_bg.jpg'
import DPC_QR from '../../assets/program/picto/dpc_qr.png'
//REDUX
import { useAppDispatch } from '../../hooks/reducerHooks'
import { logInAction } from '../../store/reducers/authReducer'
//COMPONENTS
import { Loading, TextEditor } from '../../components'
//TYPES
import { ProductType } from '../../../../lib/sharedTypes'
//ROUTER
import { useParams } from 'react-router'
//GQL
import { PRODUCT_PROGRAM_QUERY } from '../../gql/queries'
import { useQuery } from '@apollo/client'
//UTILS
import { hexToRGBA, int2roman } from '../../utils/misc'

const useStyles = makeStyles(() => ({
  pdfPage: {
    background: 'white',
    pageBreakAfter: 'always',
  },
  content: {
    marginBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 75,
    paddingLeft: 75,
    height: '100%',
    color: 'black',
    background: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: 64,
  },
  productLogo: {
    height: 300,
    width: 300,
    objectFit: 'contain',
    position: 'absolute',
    bottom: '15%',
    right: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  catalogLogo: {
    width: 200,
    position: 'absolute',
    top: '5%',
    right: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  picto: {
    width: 40,
    height: 40,
    marginRight: 10,
  },
  paragraph: {
    marginBottom: 10,
    color: '#4d4d4d',
  },
  caption: {
    color: '#4d4d4d',
    fontStyle: 'italic',
    fontSize: '12px',
    marginTop: 0,
  },
}))

const Program = () => {
  const classes = useStyles()
  const { code_name } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (location.search) {
      const params = location.search.split('?')[1].split('&')
      const paramsObj = params.reduce((acc: Record<string, string>, el) => {
        acc[el.split('=')[0]] = el.split('=')[1]
        return acc
      }, {})
      if (Object.keys(paramsObj).includes('accessToken') && Object.keys(paramsObj).includes('refreshToken')) {
        const accessToken = params[0].replace('accessToken=', '').replace('Bearer%20', 'Bearer ')
        const refreshToken = params[1].replace('refreshToken=', '').replace('Bearer%20', 'Bearer ')
        dispatch(logInAction({ accessToken, refreshToken, isLoggedIn: true, connected: true }))
      }
    }
  }, [location])

  const { loading, data: { product } = {} } = useQuery<{ product: ProductType }>(PRODUCT_PROGRAM_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name?.toUpperCase(),
        },
      },
    },
  })

  if (loading || !product) {
    return <Loading />
  }

  const productIsSante = (product: ProductType) => {
    return product.product_catalog_positions.some(
      (pcp) => pcp.catalog.name === 'Santé' || pcp.catalog?.parent?.name === 'Santé',
    )
  }

  const { r, g, b } = hexToRGBA(product.color)

  return (
    <Grid item id="programPDF">
      {/* COVER PAGE */}
      <Grid item>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: `linear-gradient(to bottom, transparent, rgba(${r}, ${g}, ${b}, 1))`,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
        {product?.s3_card && (
          <svg id="example1" xmlns="http://www.w3.org/2000/svg" width="100vw" height="100vh">
            <image
              style={{
                width: '100%',
              }}
              xlinkHref={product?.s3_card
                ?.replace('walter-production-resources', 'walter-resources')
                ?.replace('card.jpeg', 'card_w1194_h1920.jpeg')}
            />
          </svg>
        )}
        {productIsSante(product) ? (
          <img className={classes.catalogLogo} src={WS_WHITE_LOGO} />
        ) : (
          <img className={classes.catalogLogo} src={WL_WHITE_LOGO} />
        )}
        <img className={classes.productLogo} src={product.s3_logo} />
        <Grid
          item
          className={classes.footer}
          style={{
            background: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <p
            style={{
              color: 'black',
              fontWeight: 'bold',
              margin: 0,
            }}
          >
            Retrouvez toutes nos formations professionnelles sur{' '}
            <a
              style={{ textDecoration: 'none' }}
              href={productIsSante(product) ? 'walter-sante.com' : 'walter-learning.com'}
              target="_blank"
            >
              {productIsSante(product) ? 'walter-sante.com' : 'walter-learning.com'}
            </a>
          </p>
        </Grid>
      </Grid>
      {/* PAGE 1 */}
      <Grid container direction="column" justifyContent="space-between" className={classes.pdfPage}>
        <Grid item className={classes.content}>
          <Grid item>
            <Typography variant="h2">Formation {product.title}</Typography>
            <Typography variant="h4">{product.subtitle}</Typography>
            <Typography variant="body1" className={classes.paragraph}>
              {product.preamble}
            </Typography>
          </Grid>
          <Grid container direction="column" style={{ marginTop: 20 }}>
            <Grid container direction="row" alignItems="center" style={{ marginBottom: 5 }}>
              <img className={classes.picto} src={DURATION} />
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {product.duration / 3600} heure{product.duration > 3600 && 's'} de formation
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid item>
                <img className={classes.picto} src={EURO} />
              </Grid>
              <Grid item container direction="column" justifyContent="center" style={{ marginBottom: 5 }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: 0 }}>
                  Principaux financements disponibles :{' '}
                  <span className={classes.paragraph} style={{ fontWeight: 'normal' }}>
                    {product.catalog_funding_organisms
                      .map((cfo) => cfo.funding_organism.name)
                      .filter((fo_code, index, array) => array.indexOf(fo_code) == index)
                      .join(', ')}
                  </span>
                </Typography>
                <Typography variant="body1" className={classes.caption}>
                  D’autres financements sont disponibles (Pôle Emploi, financement personnel, OPCO ou autre). Pour en
                  savoir plus, contactez un conseiller Walter Learning.
                </Typography>
              </Grid>
            </Grid>
            {product.action_number && (
              <Grid container direction="row" alignItems="center" wrap="nowrap" style={{ marginBottom: 5 }}>
                <img className={classes.picto} src={DPC} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Référence action DPC:{' '}
                  <span className={classes.paragraph} style={{ fontWeight: 'normal' }}>
                    {product.action_number}
                  </span>
                </Typography>
              </Grid>
            )}
            {product.epp_available && (
              <Grid container direction="row" alignItems="center" wrap="nowrap" style={{ marginBottom: 5 }}>
                <img className={classes.picto} src={SUPPORT} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Cette formation est un programme intégré, qui comporte une partie d’Evaluation des Pratiques
                  Professionnelles (méthode de l’audit clinique).
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid>
            <Typography variant="h4">{product.skills_title}</Typography>
            <TextEditor text={product.skills_introduction} readOnly={true} />
          </Grid>
        </Grid>
      </Grid>
      {/* PAGE 2 */}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.pdfPage}
        style={{ pageBreakAfter: product.certification ? 'unset' : 'always' }}
      >
        <Grid item className={classes.content}>
          <Grid>
            <Typography variant="h4">Vous serez capable de :</Typography>
            {product?.skills.map((skill) => {
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                  key={skill.id}
                  style={{ marginTop: 5 }}
                >
                  <img
                    style={{
                      height: 20,
                      marginRight: 5,
                    }}
                    src={CHECK}
                  />
                  <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 10 }}>
                    {skill.name}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid item>
            <Typography variant="h4">Prérequis</Typography>
            <Typography variant="body1" className={classes.paragraph} style={{ whiteSpace: 'pre-line' }}>
              {product.prerequisite}
            </Typography>
            <span className={classes.caption}>
              Si vous avez des questions sur l'accessibilité à la formation en cas de handicap, n'hésitez pas à nous
              contacter à l'adresse mail{' '}
              <a style={{ textDecoration: 'none' }} href={'mailto:help@walter-learning.com'}>
                help@walter-learning.com
              </a>
            </span>
          </Grid>
          <Grid item>
            <Typography variant="h4">À qui s'adresse cette formation?</Typography>
            <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0, whiteSpace: 'pre-line' }}>
              {product.public}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">Modalités d'évaluation</Typography>
            <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0, whiteSpace: 'pre-line' }}>
              - Test de positionnement <br /> - Questionnaires d'évaluation par chapitre
            </Typography>
          </Grid>
          {product.certification && (
            <Grid item>
              <Typography variant="h4">Certification</Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ margin: 0 }}>
                Nom de la certification: {product.certification.name}
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ margin: 0 }}>
                Organisme de certification: {product.certification.certifier.name.toUpperCase()}
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ margin: 0 }}>
                Code de la certification : {product.certification.code}
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ margin: 0 }}>
                Lien vers France compétences:{' '}
                <a style={{ textDecoration: 'none' }} href={product.certification.href}>
                  {product.certification.href}
                </a>
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ margin: 0, whiteSpace: 'pre-line' }}>
                Modalités de la certification:
                <br />
                <div dangerouslySetInnerHTML={{ __html: product.certification.text }} />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* PAGE 3 */}
      <Grid container direction="column" justifyContent="space-between" className={classes.pdfPage}>
        <Grid item className={classes.content}>
          <Grid item>
            <Typography variant="h4">{product.teachers.length > 1 ? 'Vos formateurs' : 'Votre formateur'}</Typography>
            {product.teachers.map((teacher) => {
              return (
                <Grid key={teacher.id}>
                  {teacher?.s3_picture && (
                    <img
                      style={{ marginBottom: 5, height: 270, width: 480 }}
                      src={teacher?.s3_picture
                        .replace('walter-production-resources', 'walter-resources')
                        .replace('portrait', 'portrait_w480_h270')}
                    />
                  )}
                  <Typography
                    variant="body1"
                    className={classes.paragraph}
                    style={{ fontWeight: 'bold', color: 'black' }}
                  >
                    {teacher.title} {teacher.first_name} {teacher.last_name}
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                    {teacher.introduction}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid item>
            <Typography variant="h4">Ce que vous allez apprendre</Typography>
            <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0 }}>
              {product.program_introduction}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* PAGE 4 */}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.pdfPage}
        style={{
          position: 'relative',
          display: 'flex',
          flexGrow: 1,
          // height: '100vh',
          width: '100%',
        }}
      >
        <Grid
          item
          className={classes.content}
          style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Typography variant="h2">Programme détaillé</Typography>
          {product.parts
            .sort((a, b) => a.position - b.position)
            .map((part) => {
              return (
                <Grid key={part.id}>
                  {product.is_apprenticeship ? (
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                      {part.name}
                    </Typography>
                  ) : (
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                      Partie {int2roman(part.position + 1)}. {part.name}
                    </Typography>
                  )}

                  {part.sub_parts
                    .sort((a, b) => a.position - b.position)
                    .map((sub_part) => {
                      return (
                        <Typography
                          variant="body1"
                          style={{ marginBottom: 5 }}
                          className={classes.paragraph}
                          key={sub_part.id}
                        >
                          {product.is_apprenticeship
                            ? sub_part.name
                            : `Chapitre ${String.fromCharCode(96 + sub_part.position + 1).toUpperCase()}. ${
                                sub_part.name
                              }`}
                        </Typography>
                      )
                    })}
                </Grid>
              )
            })}
          <Grid
            item
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 75, fontSize: 8 }}>
              Dernière mise à jour le{' '}
              {new Date(product.updated_at).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* PAGE 5 */}
      {product.action_number && (
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          style={{
            background: 'red',
            paddingRight: 75,
            paddingLeft: 75,
            width: '100vw',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            backgroundImage: `url(${DPC_BG})`,
          }}
        >
          <Grid item container justifyContent="center">
            <Typography variant="h4" style={{ marginBottom: 10, marginTop: 50 }}>
              Comment s’inscrire via mondpc.fr ?
            </Typography>
          </Grid>
          <Grid item>
            <Grid item style={{ marginBottom: 20 }}>
              <Typography variant="body1" style={{ marginBottom: 10, marginTop: 0, fontWeight: 'bold' }}>
                Étape 1
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0 }}>
                Connectez-vous sur :{' '}
                <a style={{ textDecoration: 'none' }} target="_blank" href="https://www.mondpc.fr/">
                  www.mondpc.fr
                </a>
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 20 }}>
              <Typography variant="body1" style={{ marginBottom: 10, marginTop: 0, fontWeight: 'bold' }}>
                Étape 2
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0 }}>
                Une fois connecté(e) allez dans « Recherche actions » dans le menu de gauche puis entrez la référence
                inscrite ci-dessous:
              </Typography>
              <Grid container direction="row" alignItems="center" wrap="nowrap">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    background: 'white',
                    flexShrink: 1,
                    border: '1px solid #E8E8E8',
                    borderRadius: 5,
                    flexWrap: 'nowrap',
                    paddingRight: 10,
                  }}
                >
                  <img className={classes.picto} src={DPC} />
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    <span className={classes.paragraph} style={{ fontWeight: 'normal' }}>
                      {product.action_number}
                    </span>
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item style={{ marginBottom: 20 }}>
              <Typography variant="body1" style={{ marginBottom: 10, marginTop: 0, fontWeight: 'bold' }}>
                Étape 3
              </Typography>
              <Typography variant="body1" className={classes.paragraph} style={{ marginTop: 0 }}>
                Sur la fiche de la formation, descendez en bas de la page et cliquez sur « S’inscrire » sur la session
                de votre choix puis cliquez sur « Valider » pour finaliser votre inscription.
              </Typography>
              <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 10 }}>
                <img src={DPC_QR} style={{ marginRight: 10, height: 60, width: 60 }} />
                <Grid container direction="column">
                  <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                    Retrouvez le tutoriel officiel de l’ANPC pour s’inscrire à une action DPC
                  </Typography>
                  <Typography variant="caption" style={{ color: '#4d4d4d' }}>
                    en regardant la vidéo sur Youtube{' '}
                    <a href="https://www.youtube.com/watch?v=R-xO5A1LbiA" target="_blank">
                      https://www.youtube.com/watch?v=R-xO5A1LbiA
                    </a>{' '}
                    ou en scannant le QR code
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ backgroundColor: '#EFF2F4', borderRadius: 5, padding: 15 }}
            >
              <Typography variant="caption" style={{ margin: 0, fontWeight: 'bold' }}>
                Vous ne parvenez pas à vous inscrire ?
              </Typography>
              <Typography variant="caption">
                Contactez l’un de nos conseillers pour vous aider dans vos démarches.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* PAGE 6 */}
      <Grid container direction="column" justifyContent="space-between" className={classes.pdfPage}>
        <Grid item className={classes.content}>
          <Grid style={{ display: 'table', width: '100%' }}>
            <Grid style={{ display: 'table-row' }}>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={EXPERT} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Experts reconnus
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Nos formateurs sont des spécialistes reconnus de leur discipline
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={PLATFORM} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Plateforme en ligne
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Un espace d’apprentissage en ligne simple d’utilisation et élégant
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={HEART} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Avec vous de A à Z
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Nos équipes vous accompagnent du financement à votre attestation
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: 'table-row' }}>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={CAMERA} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Vidéos
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Des vidéos courtes et ciblées pour faciliter la compréhension et la mémorisation
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={PAPERCLIP} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Documents annexes
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Des résumés, exercices et synthèses téléchargeables à la fin de chaque chapitre
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={TEST} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Tests
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Des tests pour vous aider à identifier vos axes de progression
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: 'table-row' }}>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={SUPPORT} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Où et quand vous voulez
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Accessible 24h/24 & 7j/7 sur ordinateur, tablette et smartphone.
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={CUP} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Accessible à vie
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Votre formation validée est accèssible à vie.
                </Typography>
              </Grid>
              <Grid style={{ display: 'table-cell', textAlign: 'center', padding: 20 }}>
                <img className={classes.picto} src={HELP} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Vous avez une question
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                  Notre équipe de support est là pour vous aider tout au long de votre parcours
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 50, marginBottom: 50 }} />
          <Grid>
            {productIsSante(product) ? (
              <Grid>
                <img style={{ height: 20, marginBottom: 30 }} src={WS_BLACK_LOGO} />
                <Typography variant="body1" className={classes.paragraph} style={{ marginBottom: 20 }}>
                  Walter Santé conçoit, produit et diffuse des formations en ligne à destination des professionnels de
                  santé.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Nous co-construisons des formations haut-de-gamme avec des spécialistes reconnus. Elles sont conçues
                  par nos ingénieurs pédagogiques, captées par nos réalisateurs, puis diffusées sur notre plateforme en
                  ligne. Nous les avons pensées pour que nos apprenants puissent les suivre dans toutes les situations.
                </Typography>
              </Grid>
            ) : (
              <Grid>
                <img style={{ height: 30, marginBottom: 20 }} src={WL_BLACK_LOGO} />
                <Typography variant="body1" className={classes.paragraph} style={{ marginBottom: 20 }}>
                  Walter Learning conçoit, produit et diffuse des formations en ligne à destination des professsionnels.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Nous co-construisons des formations haut-de-gamme avec des spécialistes reconnus. Elles sont conçues
                  par nos ingénieurs pédagogiques, captées par nos réalisateurs, puis diffusées sur notre plateforme en
                  ligne. Nous les avons pensées pour que nos apprenants puissent les suivre dans toutes les situations.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Program
