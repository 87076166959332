//REACT
import React, { useState } from 'react'
// MATERIAL UI
import { TextField, Grid } from '@material-ui/core'
//COMPONENTS
import { FormLayout } from './index'
import { MultiSelectChips, DurationInput } from '../index'
//TYPES
import { CatalogType, ProductType } from '../../../../lib/sharedTypes'
import { DeepPartial } from '../../utils/types'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { CATALOGS_QUERY, THEMATIC_CODE_NAME_QUERY } from '../../gql/queries'
import { CREATE_PRODUCT_MUTATION } from '../../gql/mutations'
//HOOKS
import { useAlert } from '../../hooks'
//UTILS
import { handleError } from '../../utils/handleError'
import { useNavigate } from 'react-router'

type ProductFormPropsType = {
  type?: 'apprenticeship' | 'thematic'
  thematic_id?: string
  product?: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ProductForm = ({ product, refetch, handleClose, type, thematic_id }: ProductFormPropsType) => {
  const [newProduct, setNewProduct] = useState<DeepPartial<ProductType> | undefined>(product)
  useQuery<{ newThematicCodeName: string }>(THEMATIC_CODE_NAME_QUERY, {
    variables: {
      where: {
        id: { eq: thematic_id ? parseInt(thematic_id) : undefined },
      },
    },
    onCompleted(data) {
      setNewProduct((prevState) => {
        return {
          ...prevState,
          code_name: data?.newThematicCodeName,
        }
      })
    },
  })

  const [setAlert] = useAlert()
  const { data: { catalogs: catalogs = [] } = {} } = useQuery<{ catalogs: CatalogType[] }>(CATALOGS_QUERY)
  const navigate = useNavigate()
  const [createProduct] = useMutation<{
    createProduct: ProductType
  }>(CREATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.createProduct.code_name} créé` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newProductInput = {
      ...newProduct,
      catalogs: newProduct?.catalogs?.map((catalog) => {
        return { id: catalog?.id }
      }),
      is_apprenticeship: type === 'apprenticeship',
      thematic: {
        id: thematic_id,
      },
    }
    if (newProduct?.id) {
      // await updateProduct({ variables: { input: newProductInput } })
    } else {
      const { data } = await createProduct({ variables: { input: newProductInput } })
      if (data && !type) {
        navigate(data.createProduct.code_name)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProduct((prevState) => {
      return {
        ...prevState,
        ...{ [event.target.name]: event.target.value },
      }
    })
  }

  return (
    <FormLayout
      type="create"
      title={
        type === 'apprenticeship'
          ? 'Créer un apprentissage'
          : type === 'thematic'
          ? 'Créer une formation'
          : 'Créer un produit'
      }
      onSubmit={handleSubmit}
      submitDisabled={newProduct === undefined}
    >
      <Grid item>
        <TextField
          fullWidth
          name="title"
          label="Titre"
          variant="outlined"
          value={newProduct?.title || ''}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          name="code_name"
          label="Nom de code"
          variant="outlined"
          value={newProduct?.code_name || ''}
          onChange={handleChange}
          required
        />
      </Grid>

      <Grid item>
        <DurationInput
          required
          format="hh"
          label={'Durée'}
          defaultValue={newProduct?.duration || 0}
          onChange={(duration) => {
            setNewProduct((prevState) => {
              return {
                ...prevState,
                duration: duration,
              }
            })
          }}
        />
      </Grid>
      {type === undefined && (
        <Grid item>
          <MultiSelectChips
            multiSelectLabel="Catalogue(s)"
            keyToUpdate="catalogs"
            labels={catalogs ? catalogs.map((catalog) => catalog.name) : []}
            selectedLabelsList={
              newProduct && newProduct.catalogs ? newProduct.catalogs.map((catalog) => catalog?.name) : []
            }
            handleChange={(_, values) => {
              const value = catalogs?.filter((catalog) => values.includes(catalog.name))
              setNewProduct({
                ...newProduct,
                catalogs: value,
              })
            }}
          />
        </Grid>
      )}
    </FormLayout>
  )
}

export default ProductForm
