//EXPORT typeORM entities types
export type {
  User as UserType,
  Course as CourseType,
  CoursePart as CoursePartType,
  Shooting as ShootingType,
  Job as JobType,
  Project as ProjectType,
  Lesson as LessonType,
  Role as RoleType,
  Notification as NotificationType,
  Product as ProductType,
  ProductSkill as ProductSkillType,
  FundingOrganism as FundingOrganismType,
  ProductPart as ProductPartType,
  Teacher as TeacherType,
  SaleProcess as SaleProcessType,
  Catalog as CatalogType,
  Resource as ResourceType,
  Suggestion as SuggestionType,
  Certifier as CertifierType,
  ProjectDocument as ProjectDocumentType,
  VideoSet as VideoSetType,
  LessonVideo as LessonVideoType,
  LessonQuestion as LessonQuestionType,
  LessonText as LessonTextType,
  LessonEpp as LessonEppType,
  LessonDocument as LessonDocumentType,
  Quiz as QuizType,
  QuizQuestion as QuizQuestionType,
  CourseSkill as CourseSkillType,
  VideoReview as VideoReviewType,
  VideoReviewComment as VideoReviewCommentType,
  LessonVideoDiagram as LessonVideoDiagramType,
  Requirement as RequirementType,
  MCItem as MCItemType,
  Episode as EpisodeType,
  Checkpoint as CheckpointType,
  Leave as LeaveType,
  Assignment as AssignmentType,
  Certification as CertificationType,
  CatalogFaq as CatalogFaqType,
  Media as MediaType,
  ProductReview as ProductReviewType,
  ProductFaq as ProductFaqType,
  ProductCatalogFo as ProductCatalogFoType,
  Writer as WriterType,
  WriterAssignment as WriterAssignmentType,
  TeachingMethod as TeachingMethodType,
  MCQuestion as MCQuestionType,
  ProductionReport as ProductionReportType,
  Duration as DurationType,
  ProjectReport as ProjectReportType,
  LessonHtml as LessonHtmlType,
  ProductCatalogPos as ProductCatalogPosType,
  CourseFeedback as CourseFeedbackType,
  GapfillItem as GapfillItemType,
  RankingQuestion as RankingQuestionType,
  RankingItem as RankingItemType,
  Discipline as DisciplineType,
  Thematic as ThematicType,
  LessonTask as LessonTaskType,
  LessonTaskItem as LessonTaskItemType,
  VirtualClassRoom as VirtualClassRoomType,
  ProductPostPublication as ProductPostPublicationType,
  LessonDactylo as LessonDactyloType,
  ProductTheme as ProductThemeType,
} from "../server/src/entities";

import {
  Job as JobType,
  Lesson as LessonType,
  VideoReviewComment as VideoReviewCommentType,
  Project as ProjectType,
  Role as RoleType,
  User as UserType,
  VideoSet as VideoSetType,
  VideoReview as VideoReviewType,
  Certification as CertificationType,
  LessonVideo,
} from "../server/src/entities";

type CreateLessonInput = {
  name: string;
  validated: boolean;
  summary: JSON;
  summary_required: boolean;
  summary_state: SummaryStateEnum;
  course: RelationInput;
};

//TYPES
export type Nullable<T> = T | null;

import { ReadStream } from "fs";

type Upload = {
  filename: string;
  mimetype: string;
  encoding: string;
  createReadStream: () => ReadStream;
};

export type OrderType = {
  sort: SortEnum;
  field: string;
};

export enum AssignmentEnum {
  EDITING = "EDITING",
  MARKETING = "MARKETING",
}

export enum ReportPeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export type DecodedToken = {
  email: string;
  iat: number;
  exp: number;
};

export type TokensType = {
  accessToken: string;
  refreshToken: string;
};

export type RabbitMQMessage = {
  category:
    | "PRODUCTION"
    | "REVIEW"
    | "INFO"
    | "COMMENT"
    | "CREATE_ASSIGNMENT"
    | "UPDATE_ASSIGNMENT"
    | "DELETE_ASSIGNMENT"
    | "CREATE_SHOOTING"
    | "UPDATE_SHOOTING"
    | "DELETE_SHOOTING"
    | "LESSON_VIDEO_REVIEW_STATE_UPDATE"
    | "UPDATE_MEDIAS"
    | "UPDATE_LEAVES"
    | "REQUEST_LESSON_VIDEO_DIAGRAM"
    | "SEED_DB"
    | "PRODUCT_TEASER_REVIEW_STATE_UPDATE"
    | "PUBLISH_PRODUCT"
    | "PUBLISH_COURSE"
    | "GENERATE_COURSE_SYNTHESIS"
    | "GENERATE_PDF_SYNTHESIS"
    | "UPDATE_PRODUCT_PROGRAM"
    | "SEED_PRODUCTION_REPORT"
    | "PRODUCTION_REPORT"
    | "VIDEO_SET"
    | "GENERATE_LESSON_SUMMARY"
    | "GENERATE_LESSON_TRANSCRIPT"
    | "UPDATE_THUMBNAILS"
    | "UPDATE_VIMEO_STATUS"
    | "UPLOAD_LESSON_VIDEO_TO_VIMEO"
    | "PROCESS_DELAYED_JOBS"
    | "CLEAR_VIMEO_VERSION_HISTORY"
    | "PRODUCT_TEASER_UPLOADER_UPDATE"
    | "IMPORT_COURSE_PARTS"
    | "PUBLISH_APPRENTICESHIP"
    | "GENERATE_PDF_QUIZ"
    | "DELETE_OLD_VIDEOS"
    | "PUBLISH_PRODUCT_THEME"
    | "PUBLISH_PRODUCT_SHOWCASE";
  data: Record<string, any>;
};

// INPUT
type BetweenNumberType = {
  from: number;
  to: number;
};

type BetweenDateType = {
  from: Date;
  to: Date;
};

export type BooleanOperatorInput = {
  eq?: boolean;
  ne?: boolean;
};

export type StringOperatorInput = {
  eq?: string;
  ne?: string;
  in?: string[];
  nin?: string[];
  startsWith?: string;
};

export type NumberOperatorInput = {
  eq?: number | null;
  ne?: number | null;
  in?: number[];
  nin?: number[];
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
  between?: BetweenNumberType;
};

export type DateOperatorInput = {
  eq?: Date;
  ne?: Date;
  in?: Date[];
  nin?: Date[];
  gt?: Date;
  gte?: Date;
  lt?: Date;
  lte?: Date;
  between?: BetweenDateType;
};

export type RelationInput = {
  id: number;
};

export type DeleteInputType = {
  id: number;
};

//ENUM
export enum TokenType {
  accessToken,
  refreshToken,
  confirmToken,
  forgotToken,
}

export enum DelayedJobStatus {
  REGISTERED = "REGISTERED",
  PROCESSING = "PROCESSING",
  EXECUTED = "EXECUTED",
}

export enum LessonTaskTypeEnum {
  OTHER = "OTHER",
  EPP = "EPP",
  ECF = "ECF",
  CERTIFICATION = "CERTIFICATION",
}

export enum LessonTaskGradingTypeEnum {
  GRADING_VALIDATION = "GRADING_VALIDATION",
  GRADING_PERCENTAGE = "GRADING_PERCENTAGE",
  GRADING_DPC = "GRADING_DPC",
}

export enum LessonDocumentTypeEnum {
  LESSON = "LESSON",
  SYNTHESIS = "SYNTHESIS",
}

export enum LessonHtmlTypeEnum {
  LESSONS_OVERVIEW = "LESSONS_OVERVIEW",
}

export enum ProductStatusEnum {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum ProductPostPublicationVersionsEnum {
  VERSION_2325_V3 = "VERSION_2325_V3",
}

export enum CatalogStatusEnum {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum VideoDiagramStateEnum {
  OK = "OK",
  NOK = "NOK",
  REQUESTED = "REQUESTED",
}

export enum SuggestionCategoryEnum {
  INTERNAL = "INTERNAL",
  WEBSITE = "WEBSITE",
  LMS = "LMS",
  OTHER = "OTHER",
}

export enum ProxyFileStatusEnum {
  CREATED = "CREATED",
  ONGOING = "ONGOING",
  MISSING = "MISSING",
}

export enum VideoReviewStateEnum {
  CREATED = "CREATED",
  CORRECTED = "CORRECTED",
  TO_BE_CORRECTED = "TO_BE_CORRECTED",
  VALIDATED = "VALIDATED",
}

export enum VideoStateEnum {
  WAITING = "WAITING",
  TO_DISCOVER = "TO_DISCOVER",
  TO_CORRECT = "TO_CORRECT",
  TO_CHECK = "TO_CHECK",
  VALIDATED = "VALIDATED",
  REQUESTED_REVIEW = "REQUESTED_REVIEW",
}

export enum VimeoStatusEnum {
  WAITING = "WAITING",
  UPLOADING = "UPLOADING",
  ERROR = "ERROR",
  UPLOADED = "UPLOADED",
}

export enum FolderLockingStateEnum {
  LOCKED = "LOCKED",
  UNLOCKED = "UNLOCKED",
  LOCKING = "LOCKING",
}

export enum SortEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SummaryStateEnum {
  WAITING = "WAITING",
  REVIEWING = "REVIEWING",
  EDITING = "EDITING",
  CORRECTED = "CORRECTED",
  VALIDATED = "VALIDATED",
  GENERATED = "GENERATED",
}

export enum JobCategoryEnum {
  DIRECTOR = "DIRECTOR",
  EDITOR = "EDITOR",
  PROJECT = "PROJECT",
  OTHER = "OTHER",
}

export enum RoleCategoryEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  PRODUCTION = "PRODUCTION",
  PROJECT = "PROJECT",
  DIRECTOR = "DIRECTOR",
  EDITOR = "EDITOR",
  SALE = "SALE",
  SEO = "SEO",
  DESIGN = "DESIGN",
  FINANCE = "FINANCE",
}

export enum NotificationCategoryEnum {
  PRODUCTION = "PRODUCTION",
  REVIEW = "REVIEW",
  ASSIGNMENT = "ASSIGNMENT",
  SHOOTING = "SHOOTING",
  INFO = "INFO",
  COMMENT = "COMMENT",
  LESSON_VIDEO = "LESSON_VIDEO",
  LESSON_VIDEO_DIAGRAM = "LESSON_VIDEO_DIAGRAM",
  PRODUCT = "PRODUCT",
  PUBLISHED_PRODUCT = "PUBLISHED_PRODUCT",
  PUBLISHED_COURSE = "PUBLISHED_COURSE",
  GENERATED_COURSE_SYNTHESIS = "GENERATED_COURSE_SYNTHESIS",
  PROGRAM_UPDATED = "PROGRAM_UPDATED",
  VIDEO_SET = "VIDEO_SET",
  LESSON_SUMMARY = "LESSON_SUMMARY",
  PRODUCT_TEASER = "PRODUCT_TEASER",
  COURSE_PARTS_IMPORT = "COURSE_PARTS_IMPORT",
  PRODUCT_THEME = "PRODUCT_THEME",
}

export enum QuizCategoryEnum {
  NO_IMPACT = "NO_IMPACT",
  ECF = "ECF",
  EXPRESSION = "EXPRESSION",
  POSITIONING = "POSITIONING",
  FINAL_EVALUATION = "FINAL_EVALUATION",
}

export enum QuizDisplayEnum {
  LIST = "LIST",
  CARD = "CARD",
}

// AUTH INPUTS
export type LoginInput = {
  email: string;
  password: string;
};

export type RefreshTokensInput = {
  refreshToken: string;
};

export type ForgotPasswordInput = {
  email: string;
};

export type RegisterInput = {
  email: string;
  // username: string;
  password: string;
  // ad_username: string;
  first_name: string;
  last_name: string;
  role: RoleType;
  job: JobType;
};

export type ChangePasswordInput = {
  forgotToken: string;
  password: string;
};

export type CreateVirtualClassRoomInputType = {
  id: number;
  name: string;
  description: string;
  duration: number;
  course: RelationInput;
};

export type UpdateVirtualClassRoomInputType = {
  id: number;
  name: string;
  description: string;
  duration: number;
};

//USER INPUT
export type UserWhereInputType = {
  id: NumberOperatorInput;
  email: StringOperatorInput;
  username: StringOperatorInput;
  ad_username: StringOperatorInput;
  first_name: StringOperatorInput;
  last_name: StringOperatorInput;
  slack_id: StringOperatorInput;
  job: JobWhereInputType;
  role: RoleWhereInputType;
  courses: CourseWhereInputType;
  projects: ProjectWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type UserQueryInputType = {
  where: UserWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type UserInputType = {
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  current: boolean;
  roles: [RoleCategoryEnum];
  job: JobCategoryEnum;
};

export type UsersSearchInputType = {
  searchQuery: string;
  take: number;
  order: OrderType;
};

//PROJECTS INPUT
export type ProjectWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  code_name: StringOperatorInput;
  course: CourseWhereInputType;
  storage_name: StringOperatorInput;
  duration_target: NumberOperatorInput;
  duration_production: NumberOperatorInput;
  vimeo_project_uri: StringOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProjectQueryInputType = {
  where: ProjectWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ProjectsSearchInputType = {
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type ProjectInputType = {
  id: number;
  code_name: string;
  name: string;
  members: RelationInput[];
  duration_production: number;
  duration_target: number;
  course: RelationInput;
};

//NOTIFICATION
export type NotificationWhereInputType = {
  id: NumberOperatorInput;
  category: NotificationCategoryEnum;
  content: StringOperatorInput;
  sent_at: DateOperatorInput;
  read_at: DateOperatorInput;
  checked_at: DateOperatorInput;
  sender: UserWhereInputType;
  target: UserWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type NotificationQueryInputType = {
  where: NotificationWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type NotificationInputType = {
  id: number;
  // category: NotificationCategoryEnum;
  content: string;
  sent_at: Date;
  read_at: Date;
  checked_at: Date;
  // sender: UserInputType;
  // target: UserInputType;
  created_at: Date;
  updated_at: Date;
};

export type NotificationsInputType = {
  ids: number[];
  // category: NotificationCategoryEnum;
  content: string;
  sent_at: Date;
  read_at: Date;
  checked_at: Date;
  // sender: UserInputType;
  // target: UserInputType;
  created_at: Date;
  updated_at: Date;
};

//PRODUCT
export type ProductWhereInputType = {
  id: NumberOperatorInput;
  code_name: StringOperatorInput;
  teaser_vimeo_id: StringOperatorInput;
  s3_banner: StringOperatorInput;
  s3_card: StringOperatorInput;
  color: StringOperatorInput;
  s3_pdf: StringOperatorInput;
  seo_url: StringOperatorInput;
  seo_title: StringOperatorInput;
  seo_meta_description: StringOperatorInput;
  breadcrumb_title: StringOperatorInput;
  title: StringOperatorInput;
  subtitle: StringOperatorInput;
  prerequisite: StringOperatorInput;
  preamble: StringOperatorInput;
  public: StringOperatorInput;
  program_introduction: StringOperatorInput;
  skills_title: StringOperatorInput;
  skills_introduction: StringOperatorInput;
  docusign_template_id: StringOperatorInput;
  lms_id: StringOperatorInput;
  lms_published_on: DateOperatorInput;
  waltersf_id: StringOperatorInput;
  waltersf_published_on: DateOperatorInput;
  is_apprenticeship: BooleanOperatorInput;
  courses: CourseWhereInputType;
  disciplines: DisciplineWhereInputType;
  product_theme: ProductWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProductQueryInputType = {
  where: ProductWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

type ProductProductSkillInput = {
  id: number;
  name: string;
  description: string;
  position: number;
};

type ProductQaInput = {
  id: number;
  question: string;
  position: number;
  answer: JSON;
};

type CatalogFundingOrganismInput = {
  code_name: string;
  duration: number;
  listing_price: number;
  minimum_selling_price: number;
  release_date: Date;
  is_alias: boolean;
  catalog: RelationInput;
  funding_organism: RelationInput;
  profession: RelationInput;
  sale_processes: [RelationInput];
};

type ThematicInput = {
  id: number;
  name: string;
  position: number;
  duration: number;
  courses: [RelationInput];
};

type ThematicWhereInput = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  duration: NumberOperatorInput;
  discipline: DisciplineWhereInputType;
  courses: CourseWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

type DisciplineInput = {
  id: number;
  name: string;
  code_name: string;
  position: number;
  duration: number;
  thematics: [ThematicInput];
};

export type DisciplineWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  duration: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProductInputType = {
  id: number;
  code_name: string;
  is_offerable: boolean;
  teaser_uploader: RelationInput;
  available_for_self_registration: boolean;
  teaser_vimeo_url: string;
  teaser_review_state: VideoStateEnum;
  s3_banner: Promise<Upload>;
  s3_card: Promise<Upload>;
  color: string;
  duration: number;
  duration_dpc: number;
  duration_fifpl: number;
  duration_fafpm: number;
  s3_pdf: Promise<Upload>;
  s3_logo: Promise<Upload>;
  seo_url: string;
  seo_title: string;
  seo_meta_description: string;
  breadcrumb_title: string;
  title: string;
  subtitle: string;
  prerequisite: string;
  preamble: string;
  public: string;
  program_introduction: string;
  skills_title: string;
  skills_introduction: JSON;
  certification: CertificationType;
  docusign_template_id: string;
  lms_id: string;
  lms_published_on: Date;
  waltersf_product_code: string;
  waltersf_id: string;
  waltersf_published_on: Date;
  listing_price: number;
  minimum_selling_price: number;
  dpc_compensation: number;
  selling_point: string;
  status: ProductStatusEnum;
  canonical_url: string;
  push_score: number;
  release_date: Date;
  cogs_estimated: number;
  cogs_theoretical: number;
  seo_faq: [ProductQaInput];
  courses: [RelationInput];
  catalogs: [RelationInput];
  certifier: RelationInput;
  catalog_funding_organisms: [CatalogFundingOrganismInput];
  funding_organisms: [RelationInput];
  recommendations: [RelationInput];
  sale_processes: [RelationInput];
  teaching_methods: [RelationInput];
  teachers: [RelationInput];
  skills: [ProductProductSkillInput];
  parts: [RelationInput];
  disciplines: [DisciplineInput];
  is_apprenticeship: boolean;
  thematic: RelationInput;
  created_at: Date;
  updated_at: Date;
  version: number;
};

export type ProductsSearchInputType = {
  searchQuery: string;
  where: ProductWhereInputType;
  take: number;
  order: OrderType;
};

//PRODUCT REVIEW
export type ProductReviewWhereInputType = {
  id: NumberOperatorInput;
  first_name: StringOperatorInput;
  last_name: StringOperatorInput;
  feedback: StringOperatorInput;
  rating: NumberOperatorInput;
  global_id: StringOperatorInput;
  written_at: DateOperatorInput;
  product: ProductWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProductReviewQueryInputType = {
  where: ProductWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ProductReviewInputType = {
  id: number;
  first_name: string;
  last_name: string;
  feedback: string;
  rating: number;
  written_at: Date;
  product?: RelationInput;
};

export type ProductReviewUpdateInputType = {
  id: number;
  first_name: string;
  last_name: string;
  feedback: string;
  rating: number;
  written_at: Date;
};

//CATALOG
export type CatalogWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  title: StringOperatorInput;
  seo_url: StringOperatorInput;
  seo_title: StringOperatorInput;
  seo_meta_description: StringOperatorInput;
  breadcrumb: StringOperatorInput;
  color: StringOperatorInput;
  position: NumberOperatorInput;
  parent: CatalogWhereInputType;
  children: CatalogWhereInputType;
  products: ProductWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CatalogQueryInputType = {
  where: CatalogWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type CatalogsSearchInputType = {
  searchQuery: string;
  take: number;
  order: OrderType;
};

type CatalogFaqInputType = {
  id: number;
  question: string;
  answer: JSON;
};

type CatalogProductPositionType = {
  id: number;
  position: number;
  product: RelationInput;
};

export type CatalogInputType = {
  id: number;
  name: string;
  status: CatalogStatusEnum;
  send_to_walter_sf: boolean;
  content: string;
  title: string;
  description: string;
  seo_url: string;
  seo_title: string;
  seo_meta_description: string;
  breadcrumb: string;
  color: string;
  position: number;
  funding_subtitle: string;
  funding_text: JSON;
  course_top_title: string;
  reviews_subtitle: string;
  courses_list_title: string;
  andpc_compensation_example_amount: number;
  andpc_compensation_example_description: string;
  online_course_title: string;
  qa_title: string;
  catalog_pdf_cta: string;
  catalog_pdf_s3link: Promise<Upload>;
  screenshots_s3link: string;
  opportunity_title: string;
  opportunity_text: string;
  opportunity_list: JSON;
  product_top_1: RelationInput | null;
  product_top_2: RelationInput | null;
  product_top_3: RelationInput | null;
  parent: RelationInput;
  products: [RelationInput];
  product_catalog_positions: [CatalogProductPositionType];
  faq: [CatalogFaqInputType];
};

//ProductPart
export type ProductPartWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  position: NumberOperatorInput;
  product: ProductWhereInputType;
  sub_parts: ProductPartWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProductPartQueryInputType = {
  where: ProductPartWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

type ProductPartInputType = {
  id: number;
  name: string;
  position: number;
  parent: RelationInput;
  sub_parts: ProductPartInputType[];
};

export type ProductPartsInputType = {
  parts: ProductPartInputType[];
  id: number;
  program_introduction: string;
};

//COURSE INPUT
type RequirementInput = {
  id: number;
  requires: RelationInput;
  completion_rate: number;
};

export type CourseInputType = {
  id: number;
  code_name: string;
  name: string;
  duration: number;
  premiere_session: Date;
  s3_logo: Promise<Upload>;
  s3_banner: Promise<Upload>;
  s3_card: Promise<Upload>;
  color: string;
  position: number;
  introduction: RelationInput;
  evaluation: RelationInput;
  lessons: [RelationInput];
  sub_courses: [RelationInput];
  requirements: [RequirementInput];
  parent: RelationInput;
  parts: [RelationInput];
  product: RelationInput;
  managers: [RelationInput];
  project: RelationInput;
  resources: [RelationInput];
  skills: [RelationInput];
};

export type CourseWhereInputType = {
  id: NumberOperatorInput;
  code_name: StringOperatorInput;
  name: StringOperatorInput;
  duration: NumberOperatorInput;
  premiere_session: DateOperatorInput;
  s3_logo: StringOperatorInput;
  s3_banner: StringOperatorInput;
  s3_card: StringOperatorInput;
  color: StringOperatorInput;
  position: NumberOperatorInput;
  lessons: LessonWhereInputType;
  lessons_search: String;
  lessons_count: NumberOperatorInput;
  lessons_questions: LessonQuestionWhereInputType;
  lessons_questions_search: String;
  lessons_questions_count: NumberOperatorInput;
  video_lessons: LessonVideoWhereInputType;
  video_lessons_count: NumberOperatorInput;
  epp_lessons: LessonEppWhereInputType;
  epp_lessons_count: NumberOperatorInput;
  document_lessons: LessonDocumentWhereInputType;
  document_lessons_count: NumberOperatorInput;
  text_lessons: LessonTextWhereInputType;
  text_lessons_count: NumberOperatorInput;
  quiz_lessons: QuizWhereInputType;
  quiz_lessons_count: NumberOperatorInput;
  sub_courses: CourseWhereInputType;
  sub_courses_count: NumberOperatorInput;
  summaries_count: NumberOperatorInput;
  parts_count: NumberOperatorInput;
  managers_count: NumberOperatorInput;
  // requirements: RequirementWhereInputType;
  requirements_count: NumberOperatorInput;
  parent: Partial<CourseWhereInputType>;
  parts: CoursePartWhereInputType;
  product: ProductWhereInputType;
  managers: Partial<UserWhereInputType>;
  project: ProjectWhereInputType;
  resources: ResourceWhereInputType;
  resources_count: NumberOperatorInput;
  resources_search: StringOperatorInput;
  skills: CourseSkillWhereInputType;
  skills_search: String;
  skills_count: NumberOperatorInput;
  thematic: Partial<ThematicWhereInput>;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CourseQueryInputType = {
  searchQuery?: string;
  where: CourseWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type CoursesSearchInputType = {
  searchQuery: string;
  take: number;
  skip: number;
  order: OrderType;
};

//COURSESKILL
export type CourseSkillWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  course: Partial<CourseWhereInputType>;
  lesson_questions: LessonQuestionQueryInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CourseSkillQueryInputType = {
  where: CourseSkillWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type CourseSkillSearchInputType = {
  where: CourseSkillWhereInputType;
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type CourseSkillInputType = {
  id: number;
  name: string;
  course: RelationInput;
};

// Shooting
export type ShootingWhereInputType = {
  id: NumberOperatorInput;
  starts_at: DateOperatorInput;
  ends_at: DateOperatorInput;
  address: StringOperatorInput;
  // teachers: UserWhereInputType;
  crew: UserWhereInputType;
  project: Partial<ProjectWhereInputType>;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ShootingQueryInputType = {
  where: ShootingWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ShootingInputType = {
  id: number;
  starts_at: Date;
  ends_at: Date;
  address: string;
  duration_target: number;
  teachers: RelationInput[];
  crew: RelationInput[];
  project: RelationInput;
};

//ProjectDocument
export type ProjectDocumentWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  s3_file: StringOperatorInput;
  project: Partial<ProjectWhereInputType>;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ProjectDocumentQueryInputType = {
  where: ProjectDocumentWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ProjectDocumentInputType = {
  id: number;
  name: string;
  s3_file: string;
  project: RelationInput;
};

//LESSON
export type LessonWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  note: StringOperatorInput;
  validated: BooleanOperatorInput;
  summary: StringOperatorInput;
  summary_required: StringOperatorInput;
  summary_state: SummaryStateEnum;
  published_on: StringOperatorInput;
  course: Partial<CourseWhereInputType>;
  // questions: [LessonQuestion]
  // video: LessonVideo
  // document: Document
  // epp: LessonEpp
  // quiz: Quiz
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonInputType = {
  id: number;
  name: string;
  note: string;
  validated: boolean;
  summary: JSON;
  summary_review: string;
  summary_required: boolean;
  summary_state: SummaryStateEnum;
  published_on: string;
  course: RelationInput;
  lesson_questions: [RelationInput];
  resources: [RelationInput];
  video: RelationInput;
  document: RelationInput;
  epp: RelationInput;
  quiz: RelationInput;
  text: RelationInput;
  parts: [RelationInput];
};

export type LessonQueryInputType = {
  where: LessonWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonSearchInputType = {
  where: LessonWhereInputType;
  searchQuery: string;
  take: number;
  skip: number;
  order: OrderType;
};

export type LessonAdvancedSearchInputType = {
  where: LessonWhereInputType;
  searchQuery: string;
  searchObject: LessonWhereInputAdvanced;
  take: number;
  order: OrderType;
};

export type LessonWhereInputAdvanced = {
  or: [
    {
      field: string;
      operator: string;
      value: string[];
    }[]
  ];
};

//LessonVideo
export type LessonVideoInputType = {
  id: number;
  lesson: LessonType;
  // vimeo_thumbnail: Promise<Upload>;
  thumbnail_timecode: number;
  review_state: VideoStateEnum;
  vimeo_url: string;
  video_set: VideoSetType;
  project: ProjectType;
};

export type LessonVideoUploadedInputType = {
  id: number;
  user: RelationInput;
};

export type LessonVideoWhereInputType = {
  id: NumberOperatorInput;
  number: NumberOperatorInput;
  review_state: StringOperatorInput;
  file_path: StringOperatorInput;
  file_size: NumberOperatorInput;
  file_duration: NumberOperatorInput;
  thumbnail_timecode: NumberOperatorInput;
  vimeo_id: StringOperatorInput;
  vimeo_status: StringOperatorInput;
  vimeo_iframe: StringOperatorInput;
  vimeo_thumbnail: StringOperatorInput;
  vimeo_lastsync: DateOperatorInput;
  // video_set: VideoSet
  // video_diagrams: [LessonVideoDiagram]
  // lesson: Lesson
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonVideoQueryInputType = {
  where: LessonVideoWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//EPP
export type LessonEppWhereInputType = {
  id: NumberOperatorInput;
  epp_video_iframe: StringOperatorInput;
  grid_video_iframe: StringOperatorInput;
  lesson: LessonWhereInputType;
  // episodes: [Episode]
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonEppQueryInputType = {
  where: LessonEppWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

type CheckpointInput = {
  id: number;
  statement: string;
  advice: string;
  position: number;
  episode: RelationInput;
};

type EpisodeInput = {
  id: number;
  title: string;
  position: number;
  epp: RelationInput;
  checkpoints: [CheckpointInput];
};

export type LessonEppInputType = {
  id: number;
  epp_video_iframe: string;
  grid_video_iframe: string;
  s3_grid_pdf: Promise<Upload>;
  episodes: [EpisodeInput];
  inclusion_criteria: string;
  exclusion_criteria: string;
  lesson: CreateLessonInput;
};

//LESSON DOCUMENT
export type LessonDocumentWhereInputType = {
  id: NumberOperatorInput;
  s3_file: StringOperatorInput;
  lesson: LessonWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonDocumentQueryInputType = {
  where: LessonDocumentWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonDocumentInputType = {
  id: number;
  s3_file: Promise<Upload>;
  lesson: CreateLessonInput;
  type: LessonDocumentTypeEnum;
  downloadable: boolean;
};

// LESSON DACTYLO
export type LessonDactyloWhereInputType = {
  id: NumberOperatorInput;
  transcript: StringOperatorInput;
  lesson: LessonWhereInputType;
  lesson_video: LessonVideoWhereInputType;
  pausable: BooleanOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonDactyloQueryInputType = {
  where: LessonDactyloWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonDactyloInputType = {
  id: number;
  transcript: JSON;
  lesson: CreateLessonInput;
  lesson_video: RelationInput;
  pausable: boolean;
};

//LESSON TEXT
export type LessonTextWhereInputType = {
  id: NumberOperatorInput;
  title: StringOperatorInput;
  content: StringOperatorInput;
  lesson: LessonWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonTextQueryInputType = {
  where: LessonTextWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonTextInputType = {
  id: number;
  content: JSON;
  s3_file: Promise<Upload>;
  type: LessonDocumentTypeEnum;
  lesson: CreateLessonInput;
  downloadable: boolean;
};

//LESSON TASK
export type LessonTaskQueryInputType = {
  where: LessonTaskWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonTaskWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  description: StringOperatorInput;
  required: BooleanOperatorInput;
  grading_type: StringOperatorInput;
  type: StringOperatorInput;
  lesson: LessonWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonTaskItemInputType = {
  id: number;
  name: string;
  position: number;
  required: boolean;
  max_size: number;
  require_walter_validation: boolean;
  is_corrector_item: boolean;
  is_available_after_correction: boolean;
  lesson_task: RelationInput;
};

export type CreateLessonTaskInputType = {
  id: number;
  text: JSON;
  correction_s3_file: Promise<Upload>;
  name: string;
  description: string;
  validation_threshold: number;
  required: boolean;
  grading_type: LessonTaskGradingTypeEnum;
  type: LessonTaskTypeEnum;
  available_after_session_end: boolean;
  need_correction_file: boolean;
  task_items: [LessonTaskItemInputType];
  lesson: CreateLessonInput;
};

export type UpdateLessonTaskInputType = {
  id: number;
  text: JSON;
  correction_s3_file: Promise<Upload>;
  name: string;
  description: string;
  validation_threshold: number;
  required: boolean;
  grading_type: LessonTaskGradingTypeEnum;
  type: LessonTaskTypeEnum;
  available_after_session_end: boolean;
  need_correction_file: boolean;
  task_items: [LessonTaskItemInputType];
};

//LESSON HTML
export type LessonHtmlWhereInputType = {
  id: NumberOperatorInput;
  content: StringOperatorInput;
  lesson: LessonWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonHtmlQueryInputType = {
  where: LessonHtmlWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type LessonHtmlInputType = {
  id: number;
  content: Promise<Upload>;
  type: LessonHtmlTypeEnum;
  lesson: CreateLessonInput;
};

//LESSON QUIZ
export type QuizWhereInputType = {
  id: NumberOperatorInput;
  // category: StringOperatorInput;
  name: StringOperatorInput;
  // questions: [QuizQuestion]
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type QuizQueryInputType = {
  where: QuizWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

type QuizQuizQuestionsInput = {
  id: number;
  points: number;
  position: number;
  lesson_question: RelationInput;
};

export type QuizInputType = {
  id: number;
  category: QuizCategoryEnum;
  display: QuizDisplayEnum;
  allow_retry: boolean;
  quiz_questions: [QuizQuizQuestionsInput];
  lesson: CreateLessonInput;
};

//QuizQuestion
export type QuizQuestionWhereInputType = {
  id: NumberOperatorInput;
  position: NumberOperatorInput;
  points: NumberOperatorInput;
  quiz: QuizWhereInputType;
  // lesson_question: LessonQuestion
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type QuizQuestionInputType = {
  where: QuizWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//Suggestion
export type SuggestionWhereInputType = {
  id: NumberOperatorInput;
  content: StringOperatorInput;
  category: StringOperatorInput;
  user: UserWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type SuggestionQueryInputType = {
  where: SuggestionWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type SuggestionInputType = {
  id: number;
  content: string;
  category: SuggestionCategoryEnum;
};

//SaleProcess SaleProcessInputType
export type SaleProcessWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  description: StringOperatorInput;
  key_salesforce: StringOperatorInput;
  products: ProductWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type SaleProcessQueryInputType = {
  where: SaleProcessWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type SaleProcessInputType = {
  id: number;
  name: string;
  description: string;
  key_salesforce: string;
  products: RelationInput;
  created_at: Date;
  updated_at: Date;
  version: number;
};

// FundingOrganism
export type FundingOrganismWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  full_name: StringOperatorInput;
  description: StringOperatorInput;
  website: StringOperatorInput;
  key_lms: StringOperatorInput;
  key_salesforce: StringOperatorInput;
  ordering_key: StringOperatorInput;
  // products: [Product]
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type FundingOrganismInputType = {
  where: FundingOrganismWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

// Teacher
export type TeacherWhereInputType = {
  id: NumberOperatorInput;
  title: StringOperatorInput;
  email: StringOperatorInput;
  first_name: StringOperatorInput;
  last_name: StringOperatorInput;
  phone_number: StringOperatorInput;
  mobile_number: StringOperatorInput;
  s3_picture: StringOperatorInput;
  introduction: StringOperatorInput;
  push_score: NumberOperatorInput;
  published_on: DateOperatorInput;
  key_lms: StringOperatorInput;
  // products: [Product]
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type TeacherQueryInputType = {
  where: TeacherWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type TeacherInputType = {
  id: number;
  email: string;
  title: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  mobile_number: string;
  s3_picture: Promise<Upload>;
  introduction: string;
  resume_highlights: string[];
  push_score: number;
};

export type TeachersSearchInputType = {
  searchQuery: string;
  take: number;
  order: OrderType;
};

// Certifier
export type CertifierWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  full_name: StringOperatorInput;
  description: StringOperatorInput;
  website: StringOperatorInput;
  key_lms: StringOperatorInput;
  ordering_key: StringOperatorInput;
  // products: [Product]
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CertifierInputType = {
  where: CertifierWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//CERTIFICATION
export type CertificationWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  code: StringOperatorInput;
  href: StringOperatorInput;
  text: StringOperatorInput;
  message: StringOperatorInput;
  // certifier: CertifierWhereInput
  // products: ProductWhereInput
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CertificationQueryInputType = {
  where: CertificationWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//JOB
export type JobWhereInputType = {
  id: NumberOperatorInput;
  category: StringOperatorInput;
  day_target: NumberOperatorInput;
  planning_position: NumberOperatorInput;
  users: UserWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type JobInputType = {
  where: JobWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//ROLE
export type RoleWhereInputType = {
  id: NumberOperatorInput;
  category: StringOperatorInput;
  users: UserWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type RoleInputType = {
  where: RoleWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//LESSON_QUESTION
export type LessonQuestionSearchInputType = {
  where: LessonQuestionWhereInputType;
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type LessonQuestionWhereInputType = {
  id: NumberOperatorInput;
  introduction: StringOperatorInput;
  help_text: StringOperatorInput;
  correction_text: StringOperatorInput;
  correction_video: StringOperatorInput;
  published_on: DateOperatorInput;
  lesson: LessonInputType;
  mc_question: Partial<McQuestionWhereInputType>;
  // gapfill_question: GapfillQuestion
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LessonQuestionQueryInputType = {
  where: LessonQuestionWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//VIDEOSET
export type VideoSetWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  index: NumberOperatorInput;
  duration: NumberOperatorInput;
  folder_locking_state: StringOperatorInput;
  project: ProjectQueryInputType;
  editor: UserQueryInputType;
  lesson_videos: LessonVideoQueryInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type VideoSetQueryInputType = {
  where: VideoSetWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type VideoSetInputType = {
  id: number;
  // name: string;
  editor: UserType;
  project: ProjectType;
};

//COURSE PART
export type CoursePartWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  position: NumberOperatorInput;
  course: CourseWhereInputType;
  lesson: LessonWhereInputType;
  parent: CoursePartWhereInputType;
  sub_parts: CoursePartWhereInputType;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type CoursePartQueryInputType = {
  where: CoursePartWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type CoursePartSearchInputType = {
  where: CoursePartWhereInputType;
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type CoursePartInputType = {
  id: number;
  position: number;
  duration: number;
  name: string;
  lesson: RelationInput;
  sub_parts: [CoursePartInputType];
};

export type CoursePartsInputType = {
  course: RelationInput;
  parts: [CoursePartInputType];
};

// RESOURCES
export type ResourceInputType = {
  id: number;
  name: string;
  comment: string;
  pinned: boolean;
  s3_file: Promise<Upload>;
  lessons: [RelationInput];
  course: RelationInput;
};

export type LessonResourceSearchInputType = {
  where: ResourceWhereInputType;
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type DeleteResourceInputType = {
  id: number;
  lessons: [RelationInput];
};

export type ResourceWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  comment: StringOperatorInput;
  s3_file: StringOperatorInput;
  course: Partial<CourseWhereInputType>;
  lessons: Partial<LessonWhereInputType>;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type ResourceQueryInputType = {
  where: ResourceWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ResourceSearchInputType = {
  where: ResourceWhereInputType;
  searchQuery: string;
  take: number;
  order: OrderType;
};
//VIDEO REVIEW
export type VideoReviewWhereInputType = {
  id: NumberOperatorInput;
  state: StringOperatorInput;
  time_code: NumberOperatorInput;
  pos_x: NumberOperatorInput;
  pos_y: NumberOperatorInput;
  content: StringOperatorInput;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type VideoReviewQueryInputType = {
  where: VideoReviewWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type VideoReviewInputType = {
  id: number;
  time_code: number;
  state: VideoReviewStateEnum;
  pos_x: number;
  pos_y: number;
  content: string;
  reviewer: UserType;
  product: RelationInput;
  lesson_video: RelationInput;
  comments: VideoReviewCommentType;
};

//VIDEO REVIEW COMMENT
export type VideoReviewCommentInputType = {
  id: number;
  content: string;
  author: UserType;
  video_review: VideoReviewType;
};

export type VideoReviewCommentWhereInputType = {
  id: NumberOperatorInput;
  content: StringOperatorInput;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type VideoReviewCommentQueryInputType = {
  where: VideoReviewCommentWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//LESSON VIDEO DIAGRAM
export type LessonVideoDiagramInputType = {
  id: number;
  name: string;
  time_code: number;
  comment: string;
  state: VideoDiagramStateEnum;
  s3_file?: Promise<Upload>;
  lesson_video: RelationInput;
};

export type LessonVideoDiagramWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  time_code: NumberOperatorInput;
  comment: StringOperatorInput;
  state: VideoDiagramStateEnum;
  s3_file: StringOperatorInput;
  lesson_video: LessonVideoWhereInputType;
  created_by: UserQueryInputType;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type LessonVideoDiagramQueryInputType = {
  where: LessonVideoDiagramWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type RequestLessonVideoDiagramInputType = {
  lesson: RelationInput;
  time_code: number;
};

//LESSON QUESTION
type RankingLessonQuestionInput = {
  id: number;
  statement: string;
  ranking_items: [RankingItemLessonQuestionInput];
};

type RankingItemLessonQuestionInput = {
  id: number;
  statement: string;
  correct_position: number;
  displayed_position: number;
};

type McItemsLessonQuestionInput = {
  id: number;
  statement: string;
  item_video: RelationInput;
  correct: boolean;
  s3_image: Promise<Upload>;
};

type McQuestionLessonQuestionInput = {
  id: number;
  statement: string;
  mc_items: [McItemsLessonQuestionInput];
};

type GapfillItemsLessonQuestionInput = {
  id: number;
  position: number;
  value: string;
  element_id: string;
  is_correct: boolean;
  strict: boolean;
};

type GapfillLessonQuestionInput = {
  id: number;
  text: string;
  gapfill_items: [GapfillItemsLessonQuestionInput];
};

export type LessonQuestionInputType = {
  id: number;
  introduction: string;
  help_text: string;
  correction_text: string | null;
  correction_video: LessonVideo | null;
  question_video: RelationInput;
  lesson: RelationInput;
  s3_image: Promise<Upload>;
  skills: [RelationInput];
  mc_question: McQuestionLessonQuestionInput;
  gapfill_question: GapfillLessonQuestionInput;
  ranking_question: RankingLessonQuestionInput;
};

//LEAVE
export type LeaveInputType = {
  user: RelationInput;
  startDate: Date;
  endDate: Date;
};

export type DeleteLeaveInputType = {
  user: RelationInput;
  startDate: Date;
  endDate: Date;
};

export type LeaveWhereInputType = {
  id: NumberOperatorInput;
  date: DateOperatorInput;
  user: Partial<UserWhereInputType>;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type LeaveQueryInputType = {
  where: LeaveWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//ASSIGNMENT
export type AssignmentInputType = {
  id: number;
  type: AssignmentEnum;
  user: RelationInput;
  project: RelationInput;
  date: Date;
};

export type CreateOrUpdateAssignmentInputType = {
  user: RelationInput;
  type: AssignmentEnum;
  project: RelationInput;
  startDate: Date;
  endDate: Date;
};

export type DeleteAssignmentInputType = {
  user: RelationInput;
  startDate: Date;
  endDate: Date;
};

export type AssignmentWhereInputType = {
  id: NumberOperatorInput;
  type: StringOperatorInput;
  user: Partial<UserWhereInputType>;
  project: Partial<ProjectWhereInputType>;
  date: DateOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type AssignmentQueryInputType = {
  where: AssignmentWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//WRITER
export type WriterInputType = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

//WRITERASSIGNEMENT
export type WriterAssignmentInputType = {
  id: number;
  writer: RelationInput;
  course: RelationInput;
  start: Date;
  end: Date;
  active: boolean;
};

//MC QUESTION
export type McQuestionWhereInputType = {
  id: NumberOperatorInput;
  statement: StringOperatorInput;
  // lesson_question: LessonQuestion;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

// TEACHING METHOD
export type TeachingMethodWhereInputType = {
  id: NumberOperatorInput;
  name: StringOperatorInput;
  position: NumberOperatorInput;
  is_default: BooleanOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
  version: NumberOperatorInput;
};

export type TeachingMethodQueryInputType = {
  where: TeachingMethodWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type TeachingMethodInputType = {
  id: number;
  name: string;
  position: number;
  is_default: boolean;
  created_at: Date;
  updated_at: Date;
  version: number;
};

//MC ITEM
export type McItemWhereInputType = {
  id: NumberOperatorInput;
  statement: StringOperatorInput;
  correct: BooleanOperatorInput;
  position: NumberOperatorInput;
  mc_question: Partial<McQuestionWhereInputType>;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type McItemQueryInputType = {
  where: McItemWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

//PRODUCTION REPORT
export type ProductionReportWhereInputType = {
  id: NumberOperatorInput;
  report_date: DateOperatorInput;
  version: NumberOperatorInput;
  created_at: DateOperatorInput;
  updated_at: DateOperatorInput;
};

export type ProductionReportQueryInputType = {
  where: ProductionReportWhereInputType;
  period: ReportPeriod;
  startDate: string;
  endDate: ReportPeriod;
  skip: number;
  take: number;
  order: OrderType;
};

//COURSE FEEDBACK
export type CourseFeedbackWhereInputType = {
  id: NumberOperatorInput;
  teacher_rating: NumberOperatorInput;
  difficulties: StringOperatorInput;
  cdp_acdp_rating: NumberOperatorInput;
  teacher_recruiting_improvement: StringOperatorInput;
  setup_teacher_rating: NumberOperatorInput;
  shooting_rating: NumberOperatorInput;
  shooting_duration_rating: NumberOperatorInput;
  pre_prod_improvement: StringOperatorInput;
  design: NumberOperatorInput;
  editor: NumberOperatorInput;
  project_manager: NumberOperatorInput;
  project_manager_director_editor_rating: NumberOperatorInput;
  post_production_improvement: StringOperatorInput;
  course: CourseWhereInputType;
};

export type CourseFeedbackQueryInputType = {
  where: CourseFeedbackWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type CourseFeedbackInputType = {
  teacher_rating: number;
  difficulties: string[];
  cdp_acdp_rating: number;
  teacher_recruiting_improvement: string;
  setup_teacher_rating: number;
  shooting_rating: number;
  shooting_duration_rating: number;
  pre_prod_improvement: string;
  design: boolean;
  editor: boolean;
  project_manager: boolean;
  project_manager_director_editor_rating: number;
  post_production_improvement: string;
  course: RelationInput;
};

// DELAYED_JOB
export type DelayedJobType = {
  id: number;
  category: string;
  data: JSON;
  user: UserType;
  status: DelayedJobStatus;
  execution_datetime: Date;
  created_at: Date;
  updated_at: Date;
  version: number;
};

export type CreateDelayedJobInputType = {
  category: string;
  data: JSON;
  execution_datetime: Date;
};

//POST PUBLICATION
export type CreatePostPublicationInput = {
  product: RelationInput;
  type: string;
  new_product_code: string;
  fc_duration: number;
  has_cv: boolean;
  forum_slug: string;
  publication_version: ProductPostPublicationVersionsEnum;
};

export type UpdatePostPublicationInput = {
  id: number;
  type: string;
  new_product_code: string;
  fc_duration: number;
  has_cv: boolean;
  forum_slug: string;
  publication_version: ProductPostPublicationVersionsEnum;
};

type DeltaOperation = {
  insert: string;
  attributes: Record<string, any>;
};

export type DeltaType = {
  ops: DeltaOperation[];
};

//PRODUCTHEME
export type ProductThemeWhereInputType = {
  id: StringOperatorInput;
  code_name: StringOperatorInput;
  position: NumberOperatorInput;
  title: StringOperatorInput;
  description: StringOperatorInput;
  products: ProductQueryInputType;
};

export type ProductThemeQueryInputType = {
  where: ProductThemeWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ProductThemesSearchInputType = {
  searchQuery: string;
  take: number;
  order: OrderType;
};

export type ProductThemeInputType = {
  code_name: string;
  title: string;
  description: string;
  selling_point: string;
  default_product: RelationInput;
  products: RelationInput[];
};

export type ProductThemeUpdateInputType = {
  id: number;
  code_name: string;
  position: number;
  title: string;
  selling_point: string;
  catchphrase: string;
  description: string;
  default_product: RelationInput;
  products: RelationInput[];
};

export type DisciplineInputType = {
  id: number;
  s3_banner: Promise<Upload>;
  s3_card: Promise<Upload>;
  s3_logo: Promise<Upload>;
  color: string;
};

//THEMATIC
export type ThematicWhereInputType = {
  id: NumberOperatorInput;
};

export type ThematicQueryInputType = {
  where: ThematicWhereInputType;
  skip: number;
  take: number;
  order: OrderType;
};

export type ReportType = {
  num_of_uploads: number;
  duration_of_uploads: number;
  work_days_count: number;
  first_attempt_validation_video_count: number;
  user: UserType;
};

export type Report = {
  startDate: String;
  endDate: String;
  period: String;
  reports: [ReportType];
};
