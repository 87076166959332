import { useState } from 'react'
// MATERIAL UI
import { Grid, TextField, Typography, IconButton, useTheme, Button } from '@material-ui/core'
// COMPONENTS
import { FormLayout } from '../index'
//ICON
import { MdAddCircleOutline } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
// TYPE
import { ProductPartType, ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_PARTS_MUTATION } from '../../../gql/mutations'
//UTILS
import { useAlert } from '../../../hooks'
import { handleError } from '../../../utils/handleError'
import { int2roman } from '../../../utils/misc'

type PlanFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const PlanForm = ({ product, refetch, handleClose }: PlanFormPropsType) => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProductParts] = useMutation<{ updateProductParts: boolean }>(UPDATE_PRODUCT_PARTS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Plan mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updateProductsPartsInput = stateProduct.parts.map((part) => {
      return {
        ...part,
        id: typeof part.id === 'number' ? -1 : part.id,
        sub_parts: part.sub_parts.map((sp) => {
          return {
            ...sp,
            id: typeof sp.id === 'number' ? -1 : sp.id,
          }
        }),
      }
    })

    await updateProductParts({
      variables: {
        input: {
          id: stateProduct.id,
          program_introduction: stateProduct.program_introduction,
          parts: updateProductsPartsInput,
        },
      },
    })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour le plan"
      onSubmit={handleSubmit}
      submitDisabled={
        stateProduct.parts === product.parts && stateProduct.program_introduction === product.program_introduction
      }
    >
      <Grid item>
        <TextField
          multiline
          minRows={5}
          maxRows={5}
          required
          label="Introduction du programme"
          name="program_introduction"
          value={stateProduct.program_introduction}
          onChange={(e) => {
            setStateProduct((prevState) => {
              return {
                ...prevState,
                program_introduction: e.target.value,
              }
            })
          }}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item container direction="row" spacing={2} style={{ maxWidth: '100vw' }}>
        {stateProduct.parts
          .sort((a, b) => a.position - b.position)
          .map((part, i) => {
            return (
              <Grid item xs={12} key={part.id} container direction="column">
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    {!stateProduct.is_apprenticeship && (
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Partie {int2roman(i + 1)}.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      value={part.name}
                      variant="outlined"
                      onChange={(e) => {
                        const newParts = stateProduct.parts.map((p) => {
                          if (p.id === part.id) {
                            p.name = e.target.value
                          }
                          return p
                        })
                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            parts: newParts,
                          }
                        })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setStateProduct((prevState) => {
                          return {
                            ...prevState,
                            parts: prevState.parts.filter((p) => part.id !== p.id),
                          }
                        })
                      }}
                    >
                      <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                    </IconButton>
                  </Grid>
                </Grid>

                {part.sub_parts
                  .sort((a, b) => a.position - b.position)
                  .map((sub_part, index) => {
                    return (
                      <Grid key={sub_part.id} container direction="row" alignItems="center" spacing={1}>
                        <Grid item style={{ marginLeft: stateProduct.is_apprenticeship ? '20px' : undefined }}>
                          {!stateProduct.is_apprenticeship && (
                            <Typography variant="body1">
                              Chapitre {String.fromCharCode(96 + index + 1).toUpperCase()}.
                            </Typography>
                          )}
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            value={sub_part.name}
                            variant="outlined"
                            onChange={(e) => {
                              const newParts = stateProduct.parts.map((p) => {
                                if (p.id === part.id) {
                                  p.sub_parts.map((sp) => {
                                    if (sp.id === sub_part.id) {
                                      sp.name = e.target.value
                                    }
                                    return sp
                                  })
                                }
                                return p
                              })
                              setStateProduct((prevState) => {
                                return {
                                  ...prevState,
                                  parts: newParts,
                                }
                              })
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              setStateProduct((prevState) => {
                                return {
                                  ...prevState,
                                  parts: prevState.parts.map((p) => {
                                    return {
                                      ...p,
                                      sub_parts: p.sub_parts.filter((sp) => sp.id !== sub_part.id),
                                    }
                                  }),
                                }
                              })
                            }}
                          >
                            <HiOutlineTrash size="22" style={{ color: theme.palette.error.main, cursor: 'pointer' }} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              console.log('sub_part', sub_part)
                              const new_sub_part = {
                                id: new Date().getTime(),
                                name: '',
                                position: sub_part.position + 1,
                              } as ProductPartType
                              const _part = stateProduct.parts.find((p) => p.id === part.id)
                              const sub_parts = _part?.sub_parts

                              ;(sub_parts ?? []).splice(sub_part.position, 0, new_sub_part).map((sp, index) => {
                                return {
                                  ...sp,
                                  position: index,
                                }
                              })

                              setStateProduct((prevState) => {
                                return {
                                  ...prevState,
                                  parts: prevState.parts.map((p) => {
                                    if (p.id === part.id) {
                                      console.log('p.id === part.id', p.id)
                                      return {
                                        ...p,
                                        sub_parts: sub_parts ?? [],
                                      }
                                    } else {
                                      return p
                                    }
                                  }),
                                }
                              })
                            }}
                          >
                            <MdAddCircleOutline
                              size="22"
                              style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                <Button
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '15px',
                  }}
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation()

                    const new_part = {
                      id: new Date().getTime(),
                      name: '',
                      position: part.position + 1,
                      sub_parts: [
                        {
                          id: new Date().getTime(),
                          name: '',
                          position: 0,
                        },
                      ] as ProductPartType[],
                    } as ProductPartType
                    const _parts = stateProduct.parts

                    ;(_parts ?? []).splice(part.position, 0, new_part).map((part, index) => {
                      return {
                        ...part,
                        position: index,
                      }
                    })

                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        parts: _parts,
                      }
                    })
                  }}
                >
                  Ajouter une {stateProduct.is_apprenticeship ? 'matière' : 'partie'}{' '}
                  <MdAddCircleOutline
                    size="22"
                    style={{ color: theme.palette.primary.main, cursor: 'pointer', marginLeft: theme.spacing(1) }}
                  />
                </Button>
              </Grid>
            )
          })}
      </Grid>
    </FormLayout>
  )
}

export default PlanForm
