/* eslint-disable */
// REACT
import { useState, useCallback } from 'react'
// COMPONENTS
import { Layout, Error, Loading, Title, CustomModal } from '../components'
import { DisciplineCard } from '../components/Apprenticeship'
import ApprenticeshipForm from '../components/Forms/apprenticeship/ApprenticeshipForm'
//MATERIAL UI
import { Grid, Typography, Divider, useTheme, Button, makeStyles, alpha, IconButton } from '@material-ui/core'
//ICONS
import { MdOutlineAddCircleOutline } from 'react-icons/md'
//TYPES
import { ProductType, ThematicType, DisciplineType } from '../../../lib/sharedTypes'
//REACT-ROUTER
import { useNavigate, useParams } from 'react-router-dom'
import { PRODUCT_APPRENTICESHIP_QUERY } from '../gql/queries'
// GQL
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION, PUBLISH_APPRENTICESHIP_MUTATION } from '../gql/mutations'
//HOOKS
import { useAlert } from '../hooks'
//UTILS
import { handleError } from '../utils/handleError'
import { isAuthorized } from '../utils/misc'
import { DeepPartial } from '../utils/types'
//DND
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const useStyles = makeStyles((theme) => ({
  deleteBTN: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
  publishBTN: {
    color: 'white',
    width: 100,
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
}))

const Apprenticeship = () => {
  const navigate = useNavigate()
  const [publishModal, setPublishModal] = useState<boolean>(false)
  const [editingPosition, setEditingPosition] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const classes = useStyles()
  const theme = useTheme()
  const [setAlert] = useAlert()
  const { code_name } = useParams()
  const [stateProduct, setStateProduct] = useState<DeepPartial<ProductType>>()
  const [openedCards, setOpenedCards] = useState<(number | string | undefined)[]>([])
  const [openAll, setOpenAll] = useState(false)

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{ product: ProductType }>(PRODUCT_APPRENTICESHIP_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    onCompleted(data) {
      setStateProduct({
        ...data.product,
        disciplines: data.product.disciplines
          .map((d) => {
            return {
              ...d,
              thematics: d.thematics.sort((a, b) => a.position - b.position),
            }
          })
          .sort((a, b) => a.position - b.position),
      })
    },
  })

  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
    },
  })

  const swap = (arr: any[] | undefined, from: number, to: number) => {
    if (!arr) return
    if (!arr[to] || !arr[from]) return arr
    const temp = arr[from]
    arr[from] = arr[to]
    arr[to] = temp
    return arr
  }

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, item: any) => {
      setEditingPosition(true)
      switch (item.type) {
        case 'DISCIPLINE_CARD':
          setStateProduct((prevState) => {
            return {
              ...prevState,
              disciplines: swap(prevState?.disciplines, hoverIndex, dragIndex),
            }
          })
          break
        case 'THEMATIC_CARD':
          const discipline = stateProduct?.disciplines?.find((disc) =>
            disc?.thematics?.map((t) => t?.id && t.id.toString()).includes(item?.item?.id.toString()),
          )
          if (discipline) {
            const disciplines = stateProduct?.disciplines?.map((d) => {
              if (d?.id === discipline?.id) {
                return {
                  ...discipline,
                  thematics: swap(discipline?.thematics, hoverIndex, dragIndex),
                }
              }
              return d
            })
            setStateProduct((prevState) => {
              return {
                ...prevState,
                disciplines: disciplines,
              }
            })
          }
          break
        default:
          break
      }
    },
    [stateProduct, setStateProduct],
  )

  const handleSubmit = async () => {
    const productInput = {
      id: stateProduct?.id,
      disciplines: stateProduct?.disciplines?.map((discipline, index) => {
        return {
          ...discipline,
          id: typeof discipline?.id === 'number' ? undefined : discipline?.id,
          position: index,
          thematics: discipline?.thematics?.map((thematic, themIndex) => {
            return {
              id: typeof thematic?.id === 'number' ? undefined : thematic?.id,
              name: thematic?.name,
              duration: thematic?.duration,
              position: themIndex,
              courses: thematic?.courses?.map((course) => {
                return { id: course?.id }
              }),
            }
          }),
        }
      }),
    }

    await updateProductMutation({ variables: { input: productInput } })
  }

  const [publishApprenticeship] = useMutation<{ publishApprenticeship: ProductType }>(PUBLISH_APPRENTICESHIP_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.publishApprenticeship.code_name} en cours de publication` })
    },
  })

  if (!code_name) {
    return (
      <Layout title="Apprentissage">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Apprentissage">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading || !product) {
    return (
      <Layout title="Apprentissage">
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid item style={{ width: '100%' }}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Title title={code_name} />
          </Grid>
          <Grid item>
            <Button
              component="a"
              variant="outlined"
              color="primary"
              onClick={(e: React.MouseEvent) => {
                console.log('code_name', code_name)
                if (e.metaKey || e.ctrlKey) {
                  window.open(`/formations/${code_name}`, '_blank')
                } else {
                  navigate(`/formations/${code_name}`)
                }
              }}
              style={{ marginRight: theme.spacing(1) }}
            >
              Formation
            </Button>
            <Button
              component="a"
              variant="outlined"
              color="primary"
              onClick={(e: React.MouseEvent) => {
                console.log('code_name', code_name)
                if (e.metaKey || e.ctrlKey) {
                  window.open(`/products/${code_name}`, '_blank')
                } else {
                  navigate(`/products/${code_name}`)
                }
              }}
              style={{ marginRight: theme.spacing(1) }}
            >
              Produit
            </Button>
            <Button
              variant="contained"
              className={classes.publishBTN}
              disabled={editing || editingPosition || !isAuthorized(['ADMIN', 'PRODUCTION'])}
              onClick={() => {
                setPublishModal(true)
              }}
            >
              Publier
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Typography variant="h5">Matières</Typography>
              </Grid>
              <Grid item>
                {editing ? (
                  <IconButton
                    style={{ padding: 0, marginLeft: theme.spacing(2) }}
                    onClick={() => {
                      setStateProduct((prevState) => {
                        return {
                          ...prevState,
                          disciplines: prevState?.disciplines
                            ? [
                                ...prevState.disciplines,
                                {
                                  id: new Date().getTime(),
                                  name: '',
                                  duration: 0,
                                  thematics: [] as ThematicType[],
                                } as DisciplineType,
                              ]
                            : [
                                {
                                  id: new Date().getTime(),
                                  name: '',
                                  duration: 0,
                                  thematics: [] as ThematicType[],
                                } as DisciplineType,
                              ],
                        }
                      })
                    }}
                  >
                    <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
                  </IconButton>
                ) : (
                  <Typography
                    variant="caption"
                    color="primary"
                    style={{ cursor: 'pointer', marginLeft: theme.spacing(1) }}
                    onClick={() => {
                      if (openAll) {
                        setOpenedCards([])
                        setOpenAll(false)
                      } else {
                        const ids = product.disciplines.map((d) => d.id)
                        const gids = product.disciplines.reduce((acc: string[], el) => {
                          el.thematics.map((thematic) => {
                            acc.push(thematic.global_id)
                          })
                          return acc
                        }, [])
                        setOpenedCards([...ids, ...gids])
                        setOpenAll(true)
                      }
                    }}
                  >
                    {!openAll ? 'Développer tout' : 'Réduire tout'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {!editing && !editingPosition ? (
              <Button
                color="primary"
                disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
                variant="outlined"
                onClick={() => {
                  setEditing(true)
                }}
              >
                Modifier
              </Button>
            ) : (
              <Grid>
                <Button
                  style={{ marginRight: theme.spacing(1) }}
                  className={classes.deleteBTN}
                  disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
                  variant="outlined"
                  onClick={() => {
                    setEditing(false)
                    setEditingPosition(false)
                    setStateProduct({
                      ...product,
                      disciplines: product.disciplines
                        .map((d) => {
                          return {
                            ...d,
                            thematics: d.thematics.sort((a, b) => a.position - b.position),
                          }
                        })
                        .sort((a, b) => a.position - b.position),
                    })
                  }}
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  disabled={
                    !isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT']) ||
                    JSON.stringify(stateProduct) === JSON.stringify(product) ||
                    !stateProduct?.disciplines?.every((disc) => {
                      return (
                        disc?.name &&
                        disc?.duration !== 0 &&
                        disc?.thematics?.every((thematic) => {
                          return thematic?.name && thematic?.duration !== 0
                        })
                      )
                    })
                  }
                  variant="outlined"
                  onClick={async () => {
                    await handleSubmit()
                    await refetch()
                    setEditingPosition(false)
                    setEditing(false)
                  }}
                >
                  Enregistrer
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
          <Divider />
        </Grid>
        {editing ? (
          <ApprenticeshipForm stateProduct={stateProduct} setStateProduct={setStateProduct} />
        ) : (
          <DndProvider backend={HTML5Backend}>
            {stateProduct?.disciplines?.map((discipline, index) => {
              return (
                <DisciplineCard
                  setOpenedCards={setOpenedCards}
                  openedCards={openedCards}
                  closed={!openedCards.includes(discipline?.id)}
                  discipline={discipline}
                  index={index}
                  key={discipline?.id}
                  moveCard={moveCard}
                  refetch={refetch}
                />
              )
            })}
          </DndProvider>
        )}
      </Grid>
      <CustomModal
        size="xs"
        title="Publier l'apprentissage"
        open={publishModal}
        onClose={() => {
          setPublishModal(false)
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              className={classes.deleteBTN}
              onClick={async () => {
                setPublishModal(false)
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.publishBTN}
              onClick={async () => {
                await publishApprenticeship({ variables: { input: { id: product?.id } } })
                setPublishModal(false)
              }}
            >
              Publier
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Layout>
  )
}

export default Apprenticeship
