//MATERIAL UI
import { Grid, Typography, useTheme, IconButton, TextField } from '@material-ui/core'
//ICONS
import { MdOutlineDelete, MdOutlineAddCircleOutline } from 'react-icons/md'
//TYPES
import { ProductType, ThematicType, CourseType } from '../../../../../lib/sharedTypes'
import { DeepPartial } from '../../../utils/types'

type ApprenticeshipFormPropsType = {
  stateProduct: DeepPartial<ProductType> | undefined
  setStateProduct: React.Dispatch<React.SetStateAction<DeepPartial<DeepPartial<ProductType>> | undefined>>
}

const ApprenticeshipForm = ({ stateProduct, setStateProduct }: ApprenticeshipFormPropsType) => {
  const theme = useTheme()

  return (
    <Grid item xs={12} container>
      {stateProduct?.disciplines?.map((discipline, index) => {
        return (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            key={discipline?.id}
            alignItems="center"
            justifyContent="space-between"
            style={{
              padding: theme.spacing(1),
              background: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius,
              marginTop: theme.spacing(1),
            }}
          >
            <Grid item xs={4}>
              <TextField
                label={`Nom de la matière`}
                name="name"
                value={discipline?.name}
                onChange={(e) => {
                  e.preventDefault()
                  const disciplines = stateProduct?.disciplines?.map((disc, i) => {
                    if (i === index) {
                      return {
                        ...disc,
                        name: e.target.value,
                      }
                    } else {
                      return disc
                    }
                  })
                  setStateProduct((prevState) => {
                    return {
                      ...prevState,
                      disciplines: disciplines,
                    }
                  })
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={`Nom de code`}
                name="code_name"
                value={discipline?.code_name}
                onChange={(e) => {
                  e.preventDefault()
                  const disciplines = stateProduct?.disciplines?.map((disc, i) => {
                    if (i === index) {
                      return {
                        ...disc,
                        code_name: e.target.value,
                      }
                    } else {
                      return disc
                    }
                  })
                  setStateProduct((prevState) => {
                    return {
                      ...prevState,
                      disciplines: disciplines,
                    }
                  })
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                type="number"
                label={`Durée`}
                name="duration"
                InputProps={{ inputProps: { min: 0 } }}
                value={discipline?.duration}
                onChange={(e) => {
                  e.preventDefault()
                  const disciplines = stateProduct?.disciplines?.map((disc, i) => {
                    if (i === index) {
                      return {
                        ...disc,
                        duration: parseFloat(e.target.value),
                      }
                    } else {
                      return disc
                    }
                  })
                  setStateProduct((prevState) => {
                    return {
                      ...prevState,
                      disciplines: disciplines,
                    }
                  })
                }}
                fullWidth
              />
            </Grid>

            <Grid container item xs={1} justifyContent="center" alignItems="center">
              <IconButton
                color="primary"
                onClick={() => {
                  setStateProduct((prevState) => {
                    return {
                      ...prevState,
                      disciplines: prevState?.disciplines?.filter((disc) => {
                        return disc?.id !== discipline?.id
                      }),
                    }
                  })
                }}
              >
                <MdOutlineDelete size={24} />
              </IconButton>
            </Grid>

            <Grid item container alignItems="center" direction="row" xs={12} style={{ paddingLeft: theme.spacing(4) }}>
              <Grid item style={{ paddingLeft: theme.spacing(1) }}>
                <Typography variant="body1">Thématiques</Typography>
              </Grid>
              <IconButton
                onClick={() => {
                  setStateProduct((prevState) => {
                    return {
                      ...prevState,
                      disciplines: prevState?.disciplines?.map((d) => {
                        if (d?.id === discipline?.id) {
                          return {
                            ...d,
                            thematics: discipline?.thematics
                              ? [
                                  ...discipline.thematics,
                                  {
                                    id: new Date().getTime(),
                                    name: '',
                                    duration: 0,
                                    courses: [] as CourseType[],
                                  } as ThematicType,
                                ]
                              : [
                                  {
                                    id: new Date().getTime(),
                                    name: '',
                                    duration: 0,
                                    courses: [] as CourseType[],
                                  } as ThematicType,
                                ],
                          }
                        }
                        return d
                      }),
                    }
                  })
                }}
              >
                <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              direction="row"
              xs={12}
              style={{
                paddingLeft: theme.spacing(4),
              }}
            >
              {discipline?.thematics?.map((thematic, themIndex) => {
                return (
                  <Grid
                    key={thematic?.id}
                    spacing={2}
                    container
                    direction="row"
                    alignItems="center"
                    style={{
                      margin: theme.spacing(1),
                      background: theme.palette.background.paper,
                      borderRadius: theme.shape.borderRadius,
                    }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        label={`Nom de la thématique`}
                        name="name"
                        value={thematic?.name}
                        onChange={(e) => {
                          e.preventDefault()
                          const thematics = discipline?.thematics?.map((thematic, i) => {
                            if (i === themIndex) {
                              return {
                                ...thematic,
                                name: e.target.value,
                              }
                            } else {
                              return thematic
                            }
                          })
                          setStateProduct((prevState) => {
                            return {
                              ...prevState,
                              disciplines: prevState?.disciplines?.map((disc) => {
                                if (disc?.id === discipline.id) {
                                  return {
                                    ...disc,
                                    thematics: thematics,
                                  }
                                }
                                return disc
                              }),
                            }
                          })
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        label={`Durée`}
                        name="duration"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={thematic?.duration || 0}
                        onChange={(e) => {
                          e.preventDefault()
                          const thematics = discipline?.thematics?.map((thematic, i) => {
                            if (i === themIndex) {
                              return {
                                ...thematic,
                                duration: parseFloat(e.target.value),
                              }
                            } else {
                              return thematic
                            }
                          })
                          setStateProduct((prevState) => {
                            return {
                              ...prevState,
                              disciplines: prevState?.disciplines?.map((disc) => {
                                if (disc?.id === discipline.id) {
                                  return {
                                    ...disc,
                                    thematics: thematics,
                                  }
                                }
                                return disc
                              }),
                            }
                          })
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={1} container item justifyContent="center" alignItems="center">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          const filteredThematics = discipline?.thematics?.filter((the) => {
                            return the?.id !== thematic?.id
                          })
                          setStateProduct((prevState) => {
                            return {
                              ...prevState,
                              disciplines: prevState?.disciplines?.map((disc) => {
                                if (disc?.id === discipline.id) {
                                  return {
                                    ...disc,
                                    thematics: filteredThematics,
                                  }
                                }
                                return disc
                              }),
                            }
                          })
                        }}
                      >
                        <MdOutlineDelete size={24} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ApprenticeshipForm
