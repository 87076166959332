import { useEffect, useState } from 'react'
// MATERIAL UI
import { Grid, TextField } from '@material-ui/core'
// COMPONENTS
import { Error } from '../../index'
import { FormLayout } from '../index'
// TYPE
import { ProductType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
import { useAlert } from '../../../hooks'
//UTILS
import { useNavigate } from 'react-router-dom'
import { handleError } from '../../../utils/handleError'

type ApprenticeshipGeneralInformationsFormPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const ApprenticeshipGeneralInformationsForm = ({
  product,
  refetch,
  handleClose,
}: ApprenticeshipGeneralInformationsFormPropsType) => {
  const [setAlert] = useAlert()
  const navigate = useNavigate()

  const [stateProduct, setStateProduct] = useState<ProductType>(product)

  useEffect(() => {
    setStateProduct(product)
  }, [product])

  const [updateProductMutation] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      if (data.updateProduct.code_name !== product.code_name) {
        navigate(`/products/${data.updateProduct.code_name}`)
      }
      await refetch()
      handleClose()
    },
  })

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput: Record<string, any> = {
      ...stateProduct,
      certification: stateProduct?.certification && {
        ...stateProduct.certification,
        certifier: { id: stateProduct?.certification?.certifier?.id },
      },
      courses: stateProduct?.courses?.map((course) => {
        return { id: course.id }
      }),
      funding_organisms: stateProduct?.funding_organisms?.map((fo) => {
        return { id: fo.id }
      }),
      recommendations: stateProduct?.recommendations?.map((reco) => {
        return { id: reco.id }
      }),
      sale_processes: stateProduct?.sale_processes?.map((sp) => {
        return { id: sp.id }
      }),
      teachers: stateProduct?.teachers?.map((teacher) => {
        return { id: teacher.id }
      }),
      skills: stateProduct?.skills?.map((skill) => {
        return { id: skill.id }
      }),
      parts: stateProduct?.parts?.map((part) => {
        return { id: part.id }
      }),
      teaching_methods: stateProduct?.teaching_methods?.map((sp) => {
        return { id: sp.id }
      }),
    }
    if (productInput.catalogs) {
      delete productInput.catalogs
    }
    if (productInput.catalog_funding_organisms) {
      delete productInput.catalog_funding_organisms
    }

    if (productInput.product_catalog_positions) {
      delete productInput.product_catalog_positions
    }

    await updateProductMutation({ variables: { input: productInput } })
  }

  return (
    <FormLayout
      type="update"
      title="Mettre à jour la description"
      onSubmit={handleSubmit}
      submitDisabled={JSON.stringify(stateProduct) === JSON.stringify(product)}
    >
      <Grid item container direction="row">
        <Grid item xs={12}>
          <Grid item>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              label="Salaire de départ"
              value={stateProduct.salary_start || 0}
              onChange={(e) => {
                setStateProduct({
                  ...stateProduct,
                  salary_start: parseFloat(e.target.value),
                })
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormLayout>
  )
}

export default ApprenticeshipGeneralInformationsForm
