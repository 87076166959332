import { gql } from '@apollo/client'

//USER
export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION($input: CreateUserInput) {
    createUser(input: $input) {
      id
      email
      username
      first_name
      last_name
      roles {
        id
        category
      }
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      email
      username
      first_name
      last_name
      roles {
        id
        category
      }
    }
  }
`

//VIDEOSET
export const CREATE_VIDEOSET_MUTATION = gql`
  mutation CREATE_VIDEOSET_MUTATION($input: CreateVideoSetInput) {
    createVideoSet(input: $input) {
      id
      index
    }
  }
`

export const UPDATE_VIDEOSET_MUTATION = gql`
  mutation UPDATE_VIDEOSET_MUTATION($input: UpdateVideoSetInput) {
    updateVideoSet(input: $input) {
      id
      index
    }
  }
`

export const DELETE_VIDEOSET_MUTATION = gql`
  mutation DELETE_VIDEOSET_MUTATION($input: DeleteInput) {
    deleteVideoSet(input: $input)
  }
`

//LESSON_VIDEO
export const CREATE_LESSON_VIDEO_MUTATION = gql`
  mutation CREATE_LESSON_VIDEO_MUTATION($input: CreateLessonVideoInput) {
    createLessonVideo(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

export const UPDATE_LESSON_VIDEO_MUTATION = gql`
  mutation UPDATE_LESSON_VIDEO_MUTATION($input: UpdateLessonVideoInput) {
    updateLessonVideo(input: $input) {
      id
      number
      lesson {
        id
        name
      }
    }
  }
`
export const LESSON_VIDEO_UPLOADED_MUTATION = gql`
  mutation LESSON_VIDEO_UPLOADED_MUTATION($input: LessonVideoUploadedInput) {
    lessonVideoUploaded(input: $input)
  }
`

//PRODUCT
export const CREATE_PRODUCT_MUTATION = gql`
  mutation CREATE_PRODUCT_MUTATION($input: CreateProductInput) {
    createProduct(input: $input) {
      id
      code_name
      title
      duration
    }
  }
`

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UPDATE_PRODUCT_MUTATION($input: UpdateProductInput) {
    updateProduct(input: $input) {
      id
      code_name
    }
  }
`

export const UPDATE_PROGRAM = gql`
  mutation UPDATE_PROGRAM($input: RelationInput) {
    updateProgram(input: $input) {
      id
      code_name
    }
  }
`

export const UPLOAD_PRODUCT_TEASER_TO_VIMEO = gql`
  mutation UPLOAD_PRODUCT_TEASER_TO_VIMEO($input: RelationInput) {
    uploadProductTeaserToVimeo(input: $input) {
      id
      code_name
    }
  }
`

export const PUBLISH_PRODUCT_MUTATION = gql`
  mutation PUBLISH_PRODUCT_MUTATION($input: RelationInput) {
    publishProduct(input: $input) {
      id
      code_name
    }
  }
`

export const IMPORT_PARTS_PRODUCT = gql`
  mutation IMPORT_PARTS_PRODUCT($input: RelationInput) {
    importParts(input: $input) {
      id
      code_name
    }
  }
`

export const UPDATE_PRODUCT_PARTS_MUTATION = gql`
  mutation UPDATE_PRODUCT_PARTS_MUTATION($input: UpdateProductPartsInput) {
    updateProductParts(input: $input)
  }
`

export const PRODUCT_TEASER_UPLOADED_MUTATION = gql`
  mutation PRODUCT_TEASER_UPLOADED_MUTATION($input: ProductTeaserUploadedInput) {
    productTeaserUploaded(input: $input)
  }
`

//VIDEO REVIEW
export const CREATE_VIDEO_REVIEW_MUTATION = gql`
  mutation CREATE_VIDEO_REVIEW_MUTATION($input: CreateVideoReviewInput) {
    createVideoReview(input: $input) {
      id
      content
    }
  }
`

export const UPDATE_VIDEO_REVIEW_MUTATION = gql`
  mutation UPDATE_VIDEO_REVIEW_MUTATION($input: UpdateVideoReviewInput) {
    updateVideoReview(input: $input) {
      id
      content
    }
  }
`

export const DELETE_VIDEO_REVIEW_MUTATION = gql`
  mutation DELETE_VIDEO_REVIEW_MUTATION($input: DeleteInput) {
    deleteVideoReview(input: $input)
  }
`

//VIDEO REVIEW COMMENT
export const CREATE_VIDEO_REVIEW_COMMENT_MUTATION = gql`
  mutation CREATE_VIDEO_REVIEW_COMMENT_MUTATION($input: CreateVideoReviewCommentInput) {
    createVideoReviewComment(input: $input) {
      id
      content
    }
  }
`

export const DELETE_VIDEO_REVIEW_COMMENT_MUTATION = gql`
  mutation DELETE_VIDEO_REVIEW_COMMENT_MUTATION($input: DeleteInput) {
    deleteVideoReviewComment(input: $input)
  }
`

//NOTIFICATION
export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation UPDATE_NOTIFICATION_MUTATION($input: UpdateNotificationInput) {
    updateNotification(input: $input) {
      id
      content
      read_at
    }
  }
`

export const UPDATE_NOTIFICATIONS_MUTATION = gql`
  mutation UPDATE_NOTIFICATIONS_MUTATION($input: UpdateNotificationsInput) {
    updateNotifications(input: $input) {
      id
      content
      read_at
    }
  }
`

//COURSE
export const DELETE_COURSE_MUTATION = gql`
  mutation DELETE_COURSE_MUTATION($input: DeleteInput) {
    deleteCourse(input: $input)
  }
`

export const VALIDATE_COURSE_LESSONS_MUTATIONS = gql`
  mutation VALIDATE_COURSE_LESSONS_MUTATIONS($input: RelationInput) {
    validateCourseLessons(input: $input) {
      id
      code_name
    }
  }
`

export const VALIDATE_COURSE_LESSONS_SUMMARIES_MUTATIONS = gql`
  mutation VALIDATE_COURSE_LESSONS_SUMMARIES_MUTATIONS($input: RelationInput) {
    validateCourseLessonsSummaries(input: $input) {
      id
      code_name
    }
  }
`

export const GENERATE_COURSE_SYNTHESIS_MUTATIONS = gql`
  mutation GENERATE_COURSE_SYNTHESIS_MUTATIONS($input: RelationInput) {
    generateCourseSynthesis(input: $input) {
      id
      code_name
    }
  }
`

export const CREATE_COURSE_MUTATION = gql`
  mutation CREATE_COURSE_MUTATION($input: CreateCourseInput) {
    createCourse(input: $input) {
      id
      code_name
    }
  }
`

export const UPDATE_COURSE_MUTATION = gql`
  mutation UPDATE_COURSE_MUTATION($input: UpdateCourseInput) {
    updateCourse(input: $input) {
      id
      code_name
    }
  }
`

export const PUBLISH_COURSE_MUTATION = gql`
  mutation PUBLISH_COURSE_MUTATION($input: RelationInput) {
    publishCourse(input: $input) {
      id
      code_name
    }
  }
`

//LESSON
export const UPDATE_LESSON_MUTATION = gql`
  mutation UPDATE_LESSON_MUTATION($input: UpdateLessonInput) {
    updateLesson(input: $input) {
      id
      name
    }
  }
`
export const DELETE_LESSON_MUTATION = gql`
  mutation DELETE_LESSON_MUTATION($input: DeleteInput) {
    deleteLesson(input: $input)
  }
`

export const DELETE_LESSONS_MUTATION = gql`
  mutation DELETE_LESSONS_MUTATION($input: [DeleteInput]) {
    deleteLessons(input: $input)
  }
`

export const GENERATE_LESSON_SUMMARY_MUTATION = gql`
  mutation GENERATE_LESSON_SUMMARY_MUTATION($input: RelationInput) {
    generateLessonSummary(input: $input)
  }
`

//RESOURCE
export const IMPORT_RESOURCES_MUTATION = gql`
  mutation IMPORT_RESOURCES_MUTATION($input: ImportResourcesInput) {
    importResources(input: $input)
  }
`

export const CREATE_RESOURCE_MUTATION = gql`
  mutation CREATE_RESOURCE_MUTATION($input: CreateResourceInput) {
    createResource(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_RESOURCE_MUTATION = gql`
  mutation UPDATE_RESOURCE_MUTATION($input: UpdateResourceInput) {
    updateResource(input: $input) {
      id
      name
    }
  }
`

export const DELETE_RESOURCE_MUTATION = gql`
  mutation DELETE_RESOURCE_MUTATION($input: DeleteResourceInput) {
    deleteResource(input: $input)
  }
`

export const DELETE_RESOURCES_MUTATION = gql`
  mutation DELETE_RESOURCES_MUTATION($input: [DeleteResourceInput]) {
    deleteResources(input: $input)
  }
`

//DIAGRAM
export const CREATE_LESSON_VIDEO_DIAGRAM_MUTATION = gql`
  mutation CREATE_LESSON_VIDEO_DIAGRAM_MUTATION($input: CreateLessonVideoDiagramInput) {
    createLessonVideoDiagram(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_LESSON_VIDEO_DIAGRAM_MUTATION = gql`
  mutation UPDATE_LESSON_VIDEO_DIAGRAM_MUTATION($input: UpdateLessonVideoDiagramInput) {
    updateLessonVideoDiagram(input: $input) {
      id
      name
      state
      s3_file
      comment
    }
  }
`

export const DELETE_LESSON_VIDEO_DIAGRAM_MUTATION = gql`
  mutation DELETE_LESSON_VIDEO_DIAGRAM_MUTATION($input: DeleteInput) {
    deleteLessonVideoDiagram(input: $input)
  }
`

export const UPLOAD_LESSON_VIDEO_TO_VIMEO = gql`
  mutation UPLOAD_LESSON_VIDEO_TO_VIMEO($input: RelationInput) {
    uploadLessonVideoToVimeo(input: $input) {
      id
      number
    }
  }
`

export const UPLOAD_ALL_LESSON_VIDEO_TO_VIMEO = gql`
  mutation UPLOAD_ALL_LESSON_VIDEO_TO_VIMEO($input: RelationInput) {
    uploadAllLessonVideoToVimeo(input: $input)
  }
`

//LESSON HTML
export const CREATE_LESSON_HTML_MUTATION = gql`
  mutation CREATE_LESSON_HTML_MUTATION($input: CreateLessonHtmlInput) {
    createLessonHtml(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`
export const UPDATE_LESSON_HTML_MUTATION = gql`
  mutation UPDATE_LESSON_HTML_MUTATION($input: UpdateLessonHtmlInput) {
    updateLessonHtml(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

//LESSON DOCUMENT
export const CREATE_LESSON_DOCUMENT_MUTATION = gql`
  mutation CREATE_LESSON_DOCUMENT_MUTATION($input: CreateLessonDocumentInput) {
    createLessonDocument(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

export const UPDATE_LESSON_DOCUMENT_MUTATION = gql`
  mutation UPDATE_LESSON_DOCUMENT_MUTATION($input: UpdateLessonDocumentInput) {
    updateLessonDocument(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

// LESSON DACTYLO
export const CREATE_LESSON_DACTYLO_MUTATION = gql`
  mutation CREATE_LESSON_DACTYLO_MUTATION($input: CreateLessonDactyloInput) {
    createLessonDactylo(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

export const UPDATE_LESSON_DACTYLO_MUTATION = gql`
  mutation UPDATE_LESSON_DACTYLO_MUTATION($input: UpdateLessonDactyloInput) {
    updateLessonDactylo(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

//LESSON TASK
export const CREATE_LESSON_TASK_MUTATION = gql`
  mutation CREATE_LESSON_TASK_MUTATION($input: CreateLessonTaskInput) {
    createLessonTask(input: $input) {
      id
    }
  }
`

export const UPDATE_LESSON_TASK_MUTATION = gql`
  mutation UPDATE_LESSON_TASK_MUTATION($input: UpdateLessonTaskInput) {
    updateLessonTask(input: $input) {
      id
    }
  }
`

//LESSON TEXT
export const CREATE_LESSON_TEXT_MUTATION = gql`
  mutation CREATE_LESSON_TEXT_MUTATION($input: CreateLessonTextInput) {
    createLessonText(input: $input) {
      id
    }
  }
`

export const UPDATE_LESSON_TEXT_MUTATION = gql`
  mutation UPDATE_LESSON_TEXT_MUTATION($input: UpdateLessonTextInput) {
    updateLessonText(input: $input) {
      id
    }
  }
`

export const PREVIEW_LESSON_TEXT_MUTATION = gql`
  mutation PREVIEW_LESSON_TEXT_MUTATION($input: PreviewLessonTextInput) {
    previewLessonText(input: $input)
  }
`

export const REGENERATE_PDF_LESSON_TEXT_MUTATION = gql`
  mutation REGENERATE_PDF_LESSON_TEXT_MUTATION($input: RelationInput) {
    regeneratePdfLessonText(input: $input) {
      id
    }
  }
`

//LESSON QUIZ
export const REGENERATE_PDF_LESSON_QUIZ_MUTATION = gql`
  mutation REGENERATE_PDF_LESSON_QUIZ_MUTATION($input: RelationInput) {
    regeneratePdfLessonQuiz(input: $input) {
      id
    }
  }
`

export const CREATE_QUIZ_MUTATION = gql`
  mutation CREATE_QUIZ_MUTATION($input: CreateQuizInput) {
    createQuiz(input: $input) {
      id
    }
  }
`

export const UPDATE_QUIZ_MUTATION = gql`
  mutation UPDATE_QUIZ_MUTATION($input: UpdateQuizInput) {
    updateQuiz(input: $input) {
      id
    }
  }
`

//COURSE PART
export const IMPORT_COURSE_PARTS_MUTATION = gql`
  mutation IMPORT_COURSE_PARTS_MUTATION($input: ImportCoursePartsInput) {
    importCourseParts(input: $input)
  }
`

export const UPDATE_COURSE_PARTS_MUTATION = gql`
  mutation UPDATE_COURSE_PARTS_MUTATION($input: UpdateCoursePartsInput) {
    updateCourseParts(input: $input)
  }
`

export const UPDATE_COURSE_PART_MUTATION = gql`
  mutation UPDATE_COURSE_PART_MUTATION($input: UpdateCoursePartInput) {
    updateCoursePart(input: $input) {
      id
    }
  }
`

//LESSON QUESTION
export const IMPORT_LESSON_QUESTIONS_MUTATION = gql`
  mutation IMPORT_LESSON_QUESTIONS_MUTATION($input: ImportLessonQuestionsInput) {
    importLessonQuestions(input: $input)
  }
`

export const UPDATE_LESSON_QUESTION_MUTATION = gql`
  mutation UPDATE_LESSON_QUESTION_MUTATION($input: UpdateLessonQuestionInput) {
    updateLessonQuestion(input: $input) {
      id
    }
  }
`

export const CREATE_LESSON_QUESTION_MUTATION = gql`
  mutation CREATE_LESSON_QUESTION_MUTATION($input: CreateLessonQuestionInput) {
    createLessonQuestion(input: $input) {
      id
    }
  }
`

export const DELETE_LESSON_QUESTION_MUTATION = gql`
  mutation DELETE_LESSON_QUESTION_MUTATION($input: DeleteInput) {
    deleteLessonQuestion(input: $input)
  }
`

export const DELETE_LESSON_QUESTIONS_MUTATION = gql`
  mutation DELETE_LESSON_QUESTIONS_MUTATION($input: [DeleteInput]) {
    deleteLessonQuestions(input: $input)
  }
`

//LESSON EPP
export const CREATE_LESSON_EPP_MUTATION = gql`
  mutation CREATE_LESSON_EPP_MUTATION($input: CreateLessonEppInput) {
    createLessonEpp(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

export const UPDATE_LESSON_EPP_MUTATION = gql`
  mutation UPDATE_LESSON_EPP_MUTATION($input: UpdateLessonEppInput) {
    updateLessonEpp(input: $input) {
      id
      lesson {
        id
        name
      }
    }
  }
`

//COURSE SKILL
export const IMPORT_COURSE_SKILLS_MUTATION = gql`
  mutation IMPORT_COURSE_SKILLS_MUTATION($input: ImportCourseSkillsInput) {
    importCourseSkills(input: $input)
  }
`

export const UPDATE_COURSE_SKILL_MUTATION = gql`
  mutation UPDATE_COURSE_SKILL_MUTATION($input: UpdateCourseSkillInput) {
    updateCourseSkill(input: $input) {
      id
    }
  }
`

export const CREATE_COURSE_SKILL_MUTATION = gql`
  mutation CREATE_COURSE_SKILL_MUTATION($input: CreateCourseSkillInput) {
    createCourseSkill(input: $input) {
      id
    }
  }
`

export const DELETE_COURSE_SKILL_MUTATION = gql`
  mutation DELETE_COURSE_SKILL_MUTATION($input: DeleteInput) {
    deleteCourseSkill(input: $input)
  }
`

export const DELETE_COURSE_SKILLS_MUTATION = gql`
  mutation DELETE_COURSE_SKILLS_MUTATION($input: [DeleteInput]) {
    deleteCourseSkills(input: $input)
  }
`

//LEAVE
export const CREATE_LEAVE_MUTATION = gql`
  mutation CREATE_LEAVE_MUTATION($input: CreateLeaveInput) {
    createLeave(input: $input) {
      id
    }
  }
`

export const DELETE_LEAVE_MUTATION = gql`
  mutation DELETE_LEAVE_MUTATION($input: DeleteLeaveInputType) {
    deleteLeave(input: $input)
  }
`

//ASSIGNMENT
export const CREATE_ASSIGNMENT_MUTATION = gql`
  mutation CREATE_ASSIGNMENT_MUTATION($input: CreateOrUpdateAssignmentInput) {
    createAssignment(input: $input) {
      id
    }
  }
`

export const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation UPDATE_ASSIGNMENT_MUTATION($input: UpdateAssignmentInput) {
    updateAssignment(input: $input) {
      id
    }
  }
`

export const DELETE_ASSIGNMENT_MUTATION = gql`
  mutation DELETE_ASSIGNMENT_MUTATION($input: DeleteAssignmentInputType) {
    deleteAssignment(input: $input)
  }
`

//PROJECT
export const CREATE_MEDIA_PROXIES_MUTATION = gql`
  mutation CREATE_MEDIA_PROXIES_MUTATION($input: RelationInput) {
    createMediaProxies(input: $input) {
      id
      code_name
    }
  }
`

export const CREATE_PROJECT_MUTATION = gql`
  mutation CREATE_PROJECT_MUTATION($input: CreateProjectInput) {
    createProject(input: $input) {
      id
      code_name
    }
  }
`
export const UPDATE_PROJECT_MUTATION = gql`
  mutation UPDATE_PROJECT_MUTATION($input: UpdateProjectInput) {
    updateProject(input: $input) {
      id
      code_name
    }
  }
`

//PROJECT DOCUMENT
export const CREATE_PROJECT_DOCUMENT_MUTATION = gql`
  mutation CREATE_PROJECT_DOCUMENT_MUTATION($input: CreateProjectDocumentInput) {
    createProjectDocument(input: $input) {
      id
      name
    }
  }
`
export const UPDATE_PROJECT_DOCUMENT_MUTATION = gql`
  mutation UPDATE_PROJECT_DOCUMENT_MUTATION($input: UpdateProjectDocumentInput) {
    updateProjectDocument(input: $input) {
      id
      name
    }
  }
`

export const DELETE_PROJECT_DOCUMENT_MUTATION = gql`
  mutation DELETE_PROJECT_DOCUMENT_MUTATION($input: DeleteInput) {
    deleteProjectDocument(input: $input)
  }
`

//SHOOTING
export const CREATE_SHOOTING_MUTATION = gql`
  mutation CREATE_SHOOTING_MUTATION($input: CreateShootingInput) {
    createShooting(input: $input) {
      id
    }
  }
`
export const UPDATE_SHOOTING_MUTATION = gql`
  mutation UPDATE_SHOOTING_MUTATION($input: UpdateShootingInput) {
    updateShooting(input: $input) {
      id
    }
  }
`

export const DELETE_SHOOTING_MUTATION = gql`
  mutation DELETE_SHOOTING_MUTATION($input: DeleteInput) {
    deleteShooting(input: $input)
  }
`

//TEACHER
export const CREATE_TEACHER_MUTATION = gql`
  mutation CREATE_TEACHER_MUTATION($input: CreateTeacherInput) {
    createTeacher(input: $input) {
      id
      first_name
    }
  }
`
export const UPDATE_TEACHER_MUTATION = gql`
  mutation UPDATE_TEACHER_MUTATION($input: UpdateTeacherInput) {
    updateTeacher(input: $input) {
      id
      first_name
    }
  }
`

export const DELETE_TEACHER_MUTATION = gql`
  mutation DELETE_TEACHER_MUTATION($input: DeleteInput) {
    deleteTeacher(input: $input)
  }
`

//CATALOG
export const CREATE_CATALOG_MUTATION = gql`
  mutation CREATE_CATALOG_MUTATION($input: CreateCatalogInput) {
    createCatalog(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_CATALOG_MUTATION = gql`
  mutation UPDATE_CATALOG_MUTATION($input: UpdateCatalogInput) {
    updateCatalog(input: $input) {
      id
      name
    }
  }
`

//PRODUCT REVIEW
export const CREATE_PRODUCT_REVIEW_MUTATION = gql`
  mutation CREATE_PRODUCT_REVIEW_MUTATION($input: CreateProductReviewInput) {
    createProductReview(input: $input) {
      id
    }
  }
`

export const UPDATE_PRODUCT_REVIEW_MUTATION = gql`
  mutation UPDATE_PRODUCT_REVIEW_MUTATION($input: UpdateProductReviewInput) {
    updateProductReview(input: $input) {
      id
    }
  }
`

export const DELETE_PRODUCT_REVIEW_MUTATION = gql`
  mutation DELETE_PRODUCT_REVIEW_MUTATION($input: DeleteInput) {
    deleteProductReview(input: $input)
  }
`

//SUGGESTION
export const CREATE_SUGGESTION_MUTATION = gql`
  mutation CREATE_SUGGESTION_MUTATION($input: CreateSuggestionInput) {
    createSuggestion(input: $input) {
      id
    }
  }
`

export const UPDATE_SUGGESTION_MUTATION = gql`
  mutation UPDATE_SUGGESTION_MUTATION($input: UpdateSuggestionInput) {
    updateSuggestion(input: $input) {
      id
    }
  }
`

export const DELETE_SUGGESTION_MUTATION = gql`
  mutation DELETE_SUGGESTION_MUTATION($input: DeleteInput) {
    deleteSuggestion(input: $input)
  }
`

//SALEPROCESS
export const CREATE_SALE_PROCESS_MUTATION = gql`
  mutation CREATE_SALE_PROCESS_MUTATION($input: CreateSaleProcessInput) {
    createSaleProcess(input: $input) {
      id
      name
    }
  }
`
//VIRTUAL CLASSROOMS
export const CREATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION = gql`
  mutation CREATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION($input: CreateVirtualClassRoomInput) {
    createVirtualClassroom(input: $input) {
      id
    }
  }
`
export const UPDATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION = gql`
  mutation UPDATE_VIRTUAL_CLASSROOM_ASSIGNMENT_MUTATION($input: UpdateVirtualClassRoomInput) {
    updateVirtualClassroom(input: $input) {
      id
    }
  }
`

export const DELETE_VIRTUAL_CLASSROOM_MUTATION = gql`
  mutation DELETE_VIRTUAL_CLASSROOM_MUTATION($input: DeleteInput) {
    deleteVirtualClassroom(input: $input)
  }
`

//WRITER
export const CREATE_WRITER_MUTATION = gql`
  mutation CREATE_WRITER_MUTATION($input: CreateWriterInput) {
    createWriter(input: $input) {
      id
      first_name
    }
  }
`

//WRITER_ASSIGNMENT
export const CREATE_WRITER_ASSIGNMENT_MUTATION = gql`
  mutation CREATE_WRITER_ASSIGNMENT_MUTATION($input: CreateWriterAssignmentInput) {
    createWriterAssignment(input: $input) {
      id
    }
  }
`
export const UPDATE_WRITER_ASSIGNMENT_MUTATION = gql`
  mutation UPDATE_WRITER_ASSIGNMENT_MUTATION($input: UpdateWriterAssignmentInput) {
    updateWriterAssignment(input: $input) {
      id
    }
  }
`

export const DELETE_WRITER_ASSIGNMENT_MUTATION = gql`
  mutation DELETE_WRITER_ASSIGNMENT_MUTATION($input: DeleteInput) {
    deleteWriterAssignment(input: $input)
  }
`
// TEACHING METHOD
export const CREATE_TEACHING_METHOD_MUTATION = gql`
  mutation CREATE_TEACHING_METHOD_MUTATION($input: CreateTeachingMethodInput) {
    createTeachingMethod(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_TEACHING_METHOD_MUTATION = gql`
  mutation UPDATE_TEACHING_METHOD_MUTATION($input: UpdateTeachingMethodInput) {
    updateTeachingMethod(input: $input) {
      id
      name
    }
  }
`

//FEEDBACK
export const CREATE_COURSE_FEEDBACK = gql`
  mutation CREATE_COURSE_FEEDBACK($input: CreateCourseFeedbackInput) {
    createCourseFeedback(input: $input) {
      id
    }
  }
`

// DELAYED_JOB
export const REGISTER_DELAYED_JOB = gql`
  mutation REGISTER_DELAYED_JOB($input: CreateDelayedJobInput) {
    createDelayedJob(input: $input)
  }
`

// APPRENTICESHIP
export const PUBLISH_APPRENTICESHIP_MUTATION = gql`
  mutation PUBLISH_APPRENTICESHIP_MUTATION($input: RelationInput) {
    publishApprenticeship(input: $input) {
      id
      code_name
    }
  }
`

export const UPDATE_DISCIPLINE_MUTATION = gql`
  mutation UPDATE_DISCIPLINE_MUTATION($input: UpdateDisciplineInput) {
    updateDiscipline(input: $input) {
      id
      name
    }
  }
`

// PRODUCT POST PUBLICATION
export const CREATE_PRODUCT_POST_PUBLICATION_MUTATION = gql`
  mutation CREATE_PRODUCT_POST_PUBLICATION_MUTATION($input: CreateProductPostPublicationInput) {
    createProductPostpublication(input: $input) {
      id
    }
  }
`

export const UPDATE_PRODUCT_POST_PUBLICATION_MUTATION = gql`
  mutation UPDATE_PRODUCT_POST_PUBLICATION_MUTATION($input: UpdateProductPostPublicationInput) {
    updateProductPostpublication(input: $input) {
      id
    }
  }
`

export const DELETE_PRODUCT_POST_PUBLICATION = gql`
  mutation DELETE_PRODUCT_POST_PUBLICATION($input: DeleteInput) {
    deleteProductPostpublication(input: $input)
  }
`

//PRODUCT THEME
export const CREATE_PRODUCT_THEME_MUTATION = gql`
  mutation CREATE_PRODUCT_THEME_MUTATION($input: CreateProductThemeInput) {
    createProductTheme(input: $input) {
      id
      code_name
    }
  }
`
export const UPDATE_PRODUCT_THEME_MUTATION = gql`
  mutation UPDATE_PRODUCT_THEME_MUTATION($input: UpdateProductThemeInput) {
    updateProductTheme(input: $input) {
      id
      code_name
    }
  }
`

export const PUBLISH_PRODUCT_THEME_MUTATION = gql`
  mutation PUBLISH_PRODUCT_THEME_MUTATION($input: RelationInput) {
    publishProductTheme(input: $input) {
      id
      code_name
    }
  }
`
