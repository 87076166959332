// REACT
import { useEffect, useState } from 'react'
// MATERIAL UI
import { Button, Divider, Grid, makeStyles, Menu, MenuItem, Paper, Typography, useTheme } from '@material-ui/core'

// COMPONENTS
import { CustomModal, Error, Loading } from '../../components'
import { DeleteForm } from '../../components/Forms'
import {
  JobInformationForm,
  KeyInformationsForm,
  ApprenticeshipGeneralInformationsForm,
} from '../../components/Forms/product'
// GQL
import { useMutation, useQuery } from '@apollo/client'
import { DELETE_PRODUCT_POST_PUBLICATION } from '../../gql/mutations'
import { PRODUCT_INFO_QUERY } from '../../gql/queries'
// TYPE
import { ProductPostPublicationType, ProductType } from '../../../../lib/sharedTypes'
// UTILS
import { handleError } from '../../utils/handleError'
import { isAuthorized } from '../../utils/misc'
import { ProductJobInformations, ProductKeyInformations } from '../../utils/types'
// REACT ROUTER
import { useLocation, useOutletContext, useParams } from 'react-router'
//HOOKS
import { useAlert } from '../../hooks'

type LocationState = {
  state: {
    openModal: string
  }
}

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const Apprenticeship = () => {
  const { refetchProduct } = useOutletContext<{ refetchProduct: () => Promise<any> }>()
  const { code_name } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const [updateModal, setUpdateModal] = useState('')
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const [selectedRow, setSelectedRow] = useState<ProductPostPublicationType | undefined>(undefined)
  const [deleteModal, setDeleteModal] = useState(false)
  const [setAlert] = useAlert()

  const [deleteProductPostPublication] = useMutation<{ deleteVirtualClassroom: boolean }>(
    DELETE_PRODUCT_POST_PUBLICATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted() {
        setAlert({ severity: 'success', content: `Publication supprimée` })
        await refetch()
      },
    },
  )

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{ product: ProductType }>(PRODUCT_INFO_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
    async onCompleted() {
      await refetchProduct()
    },
  })

  useEffect(() => {
    const { state } = location as LocationState
    if (state && state.openModal) {
      setUpdateModal(state.openModal)
    }
  }, [location])

  if (loading || !product) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      {/* DESCRIPTION */}
      <Paper className={classes.paper} style={{ width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Description</Typography>
            <Button
              color="primary"
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              variant="outlined"
              onClick={() => {
                setUpdateModal('description')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>

        <Grid item container direction="row">
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Salaire de départ
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{product.salary_start}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* KEY INFORMATIONS */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Les informations clés</Typography>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('key_informations')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {(product.key_informations as ProductKeyInformations[])?.map((opportunity, index) => {
            return (
              <Grid key={index} item container direction="column" spacing={3}>
                <Grid item container direction="row" spacing={3}>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Information n°{index + 1}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.title}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{opportunity.description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Divider />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
      {/* JOB INFORMATION */}
      <Paper className={classes.paper} style={{ marginTop: theme.spacing(2), width: '100%' }}>
        <Grid item>
          <Grid item container justifyContent="space-between">
            <Typography variant="h5">Les informations sur l'emploi</Typography>
            <Button
              disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'SEO', 'PROJECT'])}
              color="primary"
              variant="outlined"
              onClick={() => {
                setUpdateModal('job_informations')
              }}
            >
              Modifier
            </Button>
          </Grid>
          <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h6">Titre</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{(product.job_informations as ProductJobInformations)?.title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">Description</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{(product.job_informations as ProductJobInformations).description}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">Titre clé</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{(product.job_informations as ProductJobInformations).key_title}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6">Informations clés</Typography>
          </Grid>
          {(product.job_informations as ProductJobInformations).keys?.map((key, index) => {
            return (
              <Grid key={index} item container direction="column" spacing={3}>
                <Grid item container direction="row" spacing={3}>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Information n°{index + 1}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{key.title}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" className={classes.title}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{key.description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Divider />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Paper>

      <CustomModal
        open={updateModal === 'description'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <ApprenticeshipGeneralInformationsForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>

      <CustomModal
        open={updateModal === 'key_informations'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <KeyInformationsForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <CustomModal
        open={updateModal === 'job_informations'}
        onClose={() => {
          setUpdateModal('')
        }}
        animation="slide-up"
      >
        <JobInformationForm
          product={product}
          refetch={refetch}
          handleClose={() => {
            setUpdateModal('')
          }}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            setUpdateModal('post_publication')
          }}
        >
          Modifier la publication
        </MenuItem>
        <MenuItem
          disabled={!isAuthorized(['ADMIN', 'PRODUCTION'])}
          onClick={() => {
            setDeleteModal(true)
            setAnchorEl(null)
          }}
        >
          Supprimer la publication
        </MenuItem>
      </Menu>
      <CustomModal
        size="xs"
        title={`Supprimer la publication`}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(true)
          setAnchorEl(null)
          setSelectedRow(undefined)
        }}
      >
        <DeleteForm
          onDelete={async () => {
            await deleteProductPostPublication({
              variables: { input: { id: selectedRow?.id } },
            })
            setDeleteModal(false)
            setAnchorEl(null)
            await refetch()
          }}
          onCancel={() => {
            setDeleteModal(false)
            setAnchorEl(null)
          }}
        />
      </CustomModal>
    </Grid>
  )
}

export default Apprenticeship
