import { useState } from 'react'
// MATERIAL UI
import { Grid, IconButton, TextField, Typography, useTheme, FormControlLabel, Switch } from '@material-ui/core'
//ICONS
import { MdOutlineAddCircleOutline } from 'react-icons/md'
// COMPONENTS
import { SaleProcessForm } from './index'
import { FormLayout } from '../index'
import { Error, MultiSelectChips, CustomModal } from '../../index'
// TYPE
import { ProductType, SaleProcessType } from '../../../../../lib/sharedTypes'
//GQL
import { useMutation, useQuery } from '@apollo/client'
import { SALE_PROCESSES_QUERY } from '../../../gql/queries'
import { UPDATE_PRODUCT_MUTATION } from '../../../gql/mutations'
//HOOKS
import { useAlert } from '../../../hooks'
//UTILS
import { handleError } from '../../../utils/handleError'

type CommercialPropsType = {
  product: ProductType
  refetch: () => Promise<any>
  handleClose: () => void
}

const Commercial = ({ product, refetch, handleClose }: CommercialPropsType) => {
  const [setAlert] = useAlert()
  const theme = useTheme()
  const [saleProcess, setSaleProcessModal] = useState(false)
  const [stateProduct, setStateProduct] = useState<ProductType>(product)
  const [updateProduct] = useMutation<{ updateProduct: ProductType }>(UPDATE_PRODUCT_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted(data) {
      setAlert({ severity: 'success', content: `${data.updateProduct.code_name} mis-à-jour` })
      await refetch()
      handleClose()
    },
  })

  const {
    error: errorsaleProcesses,
    data: { saleProcesses } = {},
    refetch: refetchSP,
  } = useQuery<{ saleProcesses: SaleProcessType[] }>(SALE_PROCESSES_QUERY)

  const handleChange = (key: string, value: string | number) => {
    setStateProduct((prevState) => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  const handleSelectChange = (key: string, values: string[]) => {
    const value = saleProcesses?.filter((sp: SaleProcessType) => values.includes(sp.name))
    setStateProduct((prevState) => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...{ [key]: value },
      }
    })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const productInput = {
      id: stateProduct.id,
      listing_price: stateProduct.listing_price,
      is_active: stateProduct.is_active,
      is_offerable: stateProduct.is_offerable,
      send_to_walter_sf: stateProduct.send_to_walter_sf,
      minimum_selling_price: stateProduct.minimum_selling_price,
      cogs_estimated: stateProduct.cogs_estimated,
      cogs_theoretical: stateProduct.cogs_theoretical,
      selling_point: stateProduct.selling_point,
      dpc_compensation: stateProduct.dpc_compensation,
      catchphrase: stateProduct.catchphrase,
      docusign_template_id: stateProduct.docusign_template_id
        ? stateProduct.docusign_template_id.toString()
        : stateProduct.docusign_template_id,
      sale_processes: stateProduct.sale_processes.map((sp) => {
        return { id: sp.id }
      }),
      waltersf_product_code: stateProduct.waltersf_product_code
        ? stateProduct.waltersf_product_code.toLowerCase()
        : null,
      is_crm_displayed: stateProduct.is_crm_displayed,
    }
    await updateProduct({ variables: { input: productInput } })
  }

  if (!stateProduct) {
    return <Error error={'Produit inconnu'} />
  }

  if (errorsaleProcesses) {
    return <Error error={errorsaleProcesses} />
  }

  return (
    <>
      <FormLayout
        type="update"
        title="Mettre à jour les aspects commerciaux"
        onSubmit={handleSubmit}
        submitDisabled={stateProduct === product}
      >
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.is_active === true}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        is_active: event.target.checked ? true : false,
                      }
                    })
                  }}
                />
              }
              label={
                stateProduct.is_active === true
                  ? 'Le produit est disponible à la vente sur Salesforce'
                  : "Le produit n'est pas disponible à la vente sur Salesforce"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.is_offerable === true}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        is_offerable: event.target.checked ? true : false,
                      }
                    })
                  }}
                />
              }
              label={
                stateProduct.is_offerable === true
                  ? 'Le produit peut être offert'
                  : 'Le produit ne peut pas être offert'
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={stateProduct.is_crm_displayed === true}
                  onChange={(event) => {
                    setStateProduct((prevState) => {
                      return {
                        ...prevState,
                        is_crm_displayed: event.target.checked ? true : false,
                      }
                    })
                  }}
                />
              }
              label={
                stateProduct.is_crm_displayed === true
                  ? 'Le produit est affiché sur Walter-CRM'
                  : "Le produit n'est pas affiché sur Walter-CRM"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="selling_point"
              label="Arguments de vente"
              multiline
              minRows={5}
              maxRows={5}
              variant="outlined"
              defaultValue={stateProduct?.selling_point}
              onChange={(event) => {
                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    [event.target.id]: event.target.value,
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="catchphrase"
              label="Phrase d'accroche"
              multiline
              minRows={2}
              maxRows={2}
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              defaultValue={stateProduct?.catchphrase}
              onChange={(event) => {
                setStateProduct((prevState) => {
                  return {
                    ...prevState,
                    [event.target.id]: event.target.value,
                  }
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Prix de vente</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Prix affiché"
              type="number"
              name="listing_price"
              defaultValue={stateProduct.listing_price}
              onChange={(e) => {
                e.preventDefault()
                const key = e.target.name
                const value = e.target.value
                handleChange(key, parseFloat(value))
              }}
              inputProps={{
                step: 0.01,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Grid item>
              <TextField
                label="Prix minimum"
                type="number"
                name="minimum_selling_price"
                defaultValue={stateProduct.minimum_selling_price}
                inputProps={{
                  step: 0.01,
                }}
                onChange={(e) => {
                  e.preventDefault()
                  const key = e.target.name
                  const value = e.target.value
                  handleChange(key, parseFloat(value))
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item>
              <TextField
                label="Compensation DPC"
                type="number"
                name="dpc_compensation"
                defaultValue={stateProduct.dpc_compensation}
                inputProps={{
                  step: 0.01,
                }}
                onChange={(e) => {
                  e.preventDefault()
                  const key = e.target.name
                  const value = e.target.value
                  handleChange(key, parseFloat(value))
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Gestion des coûts</Typography>
            <Typography variant="body2">Intégration des coûts tel que l'inscription à la certification</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Coût de la certification"
              type="number"
              name="cogs_theoretical"
              defaultValue={stateProduct.cogs_theoretical}
              onChange={(e) => {
                e.preventDefault()
                const key = e.target.name
                const value = e.target.value
                handleChange(key, parseFloat(value))
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item>
              <TextField
                label="Coût estimé de la formation"
                type="number"
                name="cogs_estimated"
                defaultValue={stateProduct.cogs_estimated}
                onChange={(e) => {
                  e.preventDefault()
                  const key = e.target.name
                  const value = e.target.value
                  handleChange(key, parseFloat(value))
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Grid>
              <Typography variant="h6">Processus de vente</Typography>
              <Typography variant="body2">Sélection des processus à suivre lors de la vente sur Salesforce</Typography>
            </Grid>
            <Grid>
              <IconButton
                onClick={() => {
                  setSaleProcessModal(true)
                }}
              >
                <MdOutlineAddCircleOutline style={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MultiSelectChips
              multiSelectLabel="Processus de vente"
              keyToUpdate="sale_processes"
              labels={saleProcesses?.map((sp) => sp.name) || []}
              selectedLabelsList={stateProduct.sale_processes ? stateProduct.sale_processes.map((sp) => sp.name) : []}
              handleChange={handleSelectChange}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Template DocuSign</Typography>
            <Typography variant="body2">Id du template DocuSign à utiliser lors de la vente</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Docusign"
              name="docusign_template_id"
              defaultValue={stateProduct.docusign_template_id}
              onChange={(e) => {
                e.preventDefault()
                const key = e.target.name
                const value = e.target.value
                handleChange(key, value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormLayout>
      <CustomModal
        size="xs"
        title={'Ajouter un processus de vente'}
        open={saleProcess}
        onClose={() => {
          setSaleProcessModal(false)
        }}
      >
        <SaleProcessForm
          handleClose={() => {
            setSaleProcessModal(false)
          }}
          refetch={refetchSP}
        />
      </CustomModal>
    </>
  )
}

export default Commercial
