import { useState, useEffect } from 'react'
// COMPONENTS
import { Layout, Title, TabsMenu, Error, Loading } from '../components'
//TYPES
import { ProductType } from '../../../lib/sharedTypes'
// MATERIAL UI
import { Grid, Button, makeStyles, Typography, CircularProgress, useTheme, Tooltip } from '@material-ui/core'
//REACT-ROUTER
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom'
//GQL
import { PUBLISH_PRODUCT_MUTATION } from '../gql/mutations'
import { PRODUCT_QUERY } from '../gql/queries'
import { useQuery, useMutation } from '@apollo/client'
//HOOKS
import { useAlert } from '../hooks'
//UTILS
import { isAuthorized } from '../utils/misc'
import { handleError } from '../utils/handleError'

const tabs = [
  'informations générales',
  'gestion des visuels',
  'aspects commerciaux',
  'avis clients',
  'foire aux questions',
]

const useStyles = makeStyles((theme) => ({
  publishBTN: {
    width: 100,
    color: 'white',
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.light,
    },
  },
}))

const Product = () => {
  const theme = useTheme()
  const [setAlert] = useAlert()
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()
  const { code_name } = useParams()
  const [activeTab, setActiveTab] = useState<string>(tabs[0])
  const [publishProduct, { loading: publishLoading }] = useMutation<{ publishProduct: ProductType }>(
    PUBLISH_PRODUCT_MUTATION,
    {
      onError(error) {
        const msg = handleError(error)
        setAlert({ severity: 'error', content: msg })
      },
      async onCompleted(data) {
        setAlert({ severity: 'success', content: `${data.publishProduct.code_name} en cours de publication` })
        await refetch()
      },
    },
  )

  const {
    loading,
    error,
    data: { product } = {},
    refetch,
  } = useQuery<{ product: ProductType }>(PRODUCT_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
    },
  })

  useEffect(() => {
    const tab = location.pathname.split('/').at(-1)
    switch (tab) {
      case 'general_informations':
        setActiveTab('informations générales')
        break
      case 'visual_management':
        setActiveTab('gestion des visuels')
        break
      case 'commercial_aspects':
        setActiveTab('aspects commerciaux')
        break
      case 'reviews':
        setActiveTab('avis clients')
        break
      case 'faq':
        setActiveTab('foire aux questions')
        break
      case 'apprenticeships':
        setActiveTab('alternance')
        break
      default:
        setActiveTab('informations générales')
        break
    }
  }, [location])

  const handleTabChange = (tab: string) => {
    switch (tab) {
      case 'informations générales':
        navigate('general_informations')
        break
      case 'gestion des visuels':
        navigate('visual_management')
        break
      case 'aspects commerciaux':
        navigate('commercial_aspects')
        break
      case 'avis clients':
        navigate('reviews')
        break
      case 'foire aux questions':
        navigate('faq')
        break
      case 'alternance':
        navigate('apprenticeships')
        break
      default:
        console.error(`Unknown tab ${activeTab}`)
        break
    }
    setActiveTab(tab)
  }

  if (!code_name) {
    return (
      <Layout title="Produit">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Produit">
        <Error error={error} />
      </Layout>
    )
  }

  if (loading || !product) {
    return (
      <Layout title="Produit">
        <Loading />
      </Layout>
    )
  }

  const sortedPublications = product.publications.sort((a, b) => {
    return new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
  })

  console.log(product)

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }} wrap="nowrap">
        <Grid item style={{ width: '100%' }}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title title={code_name} />
            </Grid>
            <Grid item style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Grid container>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    marginRight: theme.spacing(1),
                  }}
                >
                  <Button
                    component="a"
                    variant="outlined"
                    color="primary"
                    disabled={
                      !isAuthorized(['ADMIN', 'DESIGN', 'PRODUCTION', 'PROJECT', 'SEO']) ||
                      product?.courses?.length === 0
                    }
                    onClick={(e: React.MouseEvent) => {
                      if (e.metaKey || e.ctrlKey) {
                        window.open(`/formations/${product.courses[0].code_name}`, '_blank')
                      } else {
                        navigate(`/formations/${product.courses[0].code_name}`)
                      }
                    }}
                    style={{ marginRight: theme.spacing(1) }}
                  >
                    Formation
                  </Button>
                  <Button
                    component="a"
                    variant="outlined"
                    color="primary"
                    disabled={
                      !isAuthorized(['ADMIN', 'DESIGN', 'DIRECTOR', 'EDITOR', 'PRODUCTION', 'PROJECT', 'SEO']) ||
                      product?.courses?.length === 0 ||
                      product?.courses[0]?.projects?.length === 0
                    }
                    onClick={(e: React.MouseEvent) => {
                      if (e.metaKey || e.ctrlKey) {
                        window.open(`/projects/${product.courses[0].projects[0].code_name}`, '_blank')
                      } else {
                        navigate(`/projects/${product.courses[0].projects[0].code_name}`)
                      }
                    }}
                  >
                    Projet
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.publishBTN}
                    disabled={!isAuthorized(['ADMIN', 'PRODUCTION', 'DESIGN', 'SEO', 'SALE'])}
                    onClick={async () => {
                      await publishProduct({ variables: { input: { id: product?.id } } })
                    }}
                  >
                    {publishLoading ? (
                      <CircularProgress
                        variant="indeterminate"
                        size="1.5rem"
                        style={{ color: 'white' }}
                        id="upload_progress"
                      />
                    ) : product?.lms_published_on ? (
                      'Republier'
                    ) : (
                      'Publier'
                    )}
                  </Button>
                </Grid>
                <Grid item container direction="column" alignItems="flex-end">
                  {sortedPublications?.length !== 0 ? (
                    <Tooltip
                      title={`Publié par ${sortedPublications[0]?.user.first_name} ${sortedPublications[0]?.user?.last_name}`}
                    >
                      <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                        Dernière publication:{' '}
                        {sortedPublications?.length !== 0
                          ? new Date(sortedPublications[0].published_at).toLocaleString()
                          : 'Non publié'}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                      Dernière publication: Non publié
                    </Typography>
                  )}
                  <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                    Dernière modification: {new Date(product.updated_at).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <TabsMenu
            allowedTabs={product.is_apprenticeship ? [...tabs, 'alternance'] : tabs}
            tabs={product.is_apprenticeship ? [...tabs, 'alternance'] : tabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          />
        </Grid>
        <Grid container wrap="nowrap" style={{ width: '100%', height: '100%' }}>
          <Outlet context={{ refetchProduct: refetch }} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Product
