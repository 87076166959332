//REACT
import { useState } from 'react'
//COMPONENTS
import { LessonTaskForm } from '../../../components/Forms/lesson'
import { LessonInfo, LessonRessources } from '../../../components/Lesson'
import { Layout, Title, Error, Loading, CustomModal, TextEditor } from '../../../components'
// MATERIAL UI
import { Grid, Typography, Paper, useTheme, makeStyles, Button, Divider } from '@material-ui/core'
// REACT-ROUTER
import { useParams } from 'react-router-dom'
// TYPES
import { LessonType } from '../../../../../lib/sharedTypes'
// GRAPHQL
import { useQuery } from '@apollo/client'
import { LESSON_TASK_QUERY } from '../../../gql/queries'
//UTILS
import { isAuthorized, isCourseManager } from '../../../utils/misc'
import { lessonTaskTypes, lessonTaskGradingTypes } from '../../../utils/constants'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
}))

const LessonTask = () => {
  const { code_name, lesson_id } = useParams()
  const theme = useTheme()
  const [updateTaskModal, setUpdateTaskModal] = useState(false)
  const classes = useStyles()
  if (!lesson_id) {
    return (
      <Layout title="Leçon tâche">
        <Error error="lesson_id inconnu" />
      </Layout>
    )
  }

  if (!code_name) {
    return (
      <Layout title="Leçon tâche">
        <Error error="code_name inconnu" />
      </Layout>
    )
  }

  const {
    loading,
    error,
    data: { lesson } = {},
    refetch,
  } = useQuery<{
    lesson: LessonType
  }>(LESSON_TASK_QUERY, {
    variables: {
      where: {
        id: {
          eq: parseInt(lesson_id?.toString()),
        },
      },
    },
  })

  if (loading || !lesson) {
    return (
      <Layout title="Leçon tâche">
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout title="Leçon tâche">
        <Error error={error} />
      </Layout>
    )
  }

  return (
    <Layout title={code_name}>
      <Grid container direction="column" spacing={1} style={{ width: '100%' }}>
        <Grid item>
          <Title title={code_name} />
        </Grid>
        {/* LESSON */}
        <LessonInfo
          lesson={lesson}
          refetch={refetch}
          hideSummary={true}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />
        <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
          <Grid item>
            <Grid item container justifyContent="space-between">
              <Typography variant="h5">Tâche</Typography>
              <Button
                disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setUpdateTaskModal(true)
                }}
              >
                Modifier
              </Button>
            </Grid>
            <Grid item style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
              <Divider />
            </Grid>
            <Grid item container direction="column" style={{ marginBottom: theme.spacing(1) }}>
              <Grid item container>
                <Grid xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Texte
                  </Typography>
                  <TextEditor text={lesson?.task?.text} readOnly={true} />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Fichier de correction
                  </Typography>
                  {lesson.task.correction_s3_file ? (
                    <object
                      key={lesson.task.correction_s3_file}
                      data={`${lesson.task.correction_s3_file}#view=FitV`}
                      width="100%"
                      height="250px"
                    >
                      <p>{lesson.task.correction_s3_file}</p>
                    </object>
                  ) : (
                    '-'
                  )}
                </Grid>
              </Grid>

              <Grid item container direction="row">
                <Grid xs={3}>
                  <Typography variant="body1" className={classes.title}>
                    Nom
                  </Typography>
                  <Typography variant="body2">{lesson.task.name || '-'}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography variant="body1" className={classes.title}>
                    Requis
                  </Typography>
                  <Typography variant="body2">{lesson.task.required ? 'Oui' : 'Non'}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography variant="body1" className={classes.title}>
                    Type
                  </Typography>
                  <Typography variant="body2">{lessonTaskTypes[lesson.task.type].label || '-'}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography variant="body1" className={classes.title}>
                    Type de notation
                  </Typography>
                  <Typography variant="body2">
                    {lessonTaskGradingTypes[lesson.task.grading_type].label || '-'}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography variant="body1" className={classes.title}>
                    Seuil de validation
                  </Typography>
                  <Typography variant="body2">{lesson.task.validation_threshold || '-'}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Description
                </Typography>
                <Typography variant="body2">{lesson.task.description || '-'}</Typography>
              </Grid>
              <Typography variant="body1" className={classes.title}>
                Fichier(s) à déposer:
              </Typography>
              <Grid container item xs={12} style={{ marginLeft: theme.spacing(2) }}>
                {lesson.task.task_items
                  .sort((a, b) => a.id - b.id)
                  .filter((item) => !item.is_corrector_item)
                  .map((item) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        key={item.id}
                        justifyContent="flex-start"
                        spacing={1}
                      >
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Nom
                          </Typography>
                          <Typography variant="body2">{item.name || '-'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Requis
                          </Typography>
                          <Typography variant="body2">{item.required ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Taille maximum
                          </Typography>
                          <Typography variant="body2">{`${item.max_size}mb` || '-'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Besoin d'une validation
                          </Typography>
                          <Typography variant="body2">{item.require_walter_validation ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
              <Typography variant="body1" className={classes.title}>
                Fichier(s) de correction:
              </Typography>
              <Grid container item xs={12} style={{ marginLeft: theme.spacing(2) }}>
                {lesson.task.task_items
                  .sort((a, b) => a.id - b.id)
                  .filter((item) => item.is_corrector_item)
                  .map((item) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        key={item.id}
                        justifyContent="flex-start"
                        spacing={1}
                      >
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Nom
                          </Typography>
                          <Typography variant="body2">{item.name || '-'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" className={classes.title}>
                            Requis
                          </Typography>
                          <Typography variant="body2">{item.required ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" className={classes.title}>
                            Taille maximum
                          </Typography>
                          <Typography variant="body2">{`${item.max_size}mb` || '-'}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" className={classes.title}>
                            Besoin d'une validation
                          </Typography>
                          <Typography variant="body2">{item.require_walter_validation ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2" className={classes.title}>
                            Disponible après correction
                          </Typography>
                          <Typography variant="body2">{item.is_available_after_correction ? 'Oui' : 'Non'}</Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {/* RESSOURCES */}
        <LessonRessources
          lesson={lesson}
          refetch={refetch}
          disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(lesson.course))}
        />

        <CustomModal
          open={updateTaskModal}
          onClose={() => {
            setUpdateTaskModal(false)
          }}
          animation="slide-up"
        >
          <LessonTaskForm
            lesson={lesson}
            refetch={refetch}
            handleClose={() => {
              setUpdateTaskModal(false)
            }}
          />
        </CustomModal>
      </Grid>
    </Layout>
  )
}

export default LessonTask
